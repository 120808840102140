<template>
    <div ref="accountItem" :class="accountItemClass" @click.stop="onAccountClick">
        <span v-if="isCollaborativePortfolio" class='material-symbols-outlined collaborative-account-icon'>request_quote</span>
        <i v-else class="pi pi-user account-item-icon"></i>
        
        <span class="account-item-title">{{ accountNameLabel }}</span>

        <Button v-if="(isGroupOwner || accountOwner) && account && !portfolioLocked" icon='pi pi-trash'
            class="action-button p-button-text" @click.stop='deleteAccount' v-tooltip.bottom="'Delete'" />
        <i v-if="isMobile()" :class="(this.thisSelected ? '' : 'hidden ') + 'pi menu-active pi-check'" />

    </div>
</template>

<script>


import BrowserUtils from '../../utilities/BrowserUtils';
import UserUtils from '../../utilities/UserUtils';
import EventBus from '../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'GroupPortfolioListSubItem',
    props: ['account', 'sideMenuWidth', "isGroupOwner", "portfolioLocked", "isCollaborativePortfolio"],
    emits: ['account-deleted'],

    data() {
        return {

            thisSelected: false,
            accountOwner: false,
        };
    },


    mounted() {
        // console.log("mounted AccountListItem", this.selectedGroupConduit);
        this.thisSelected = this.isItemSelected(this.selectedGroupConduit);
        this.accountOwner = UserUtils.isUser(this.account.userId);
        this.accountOwner = UserUtils.isUser(this.account.userId);
    },

    computed: {
        ...mapState(['selectedGroupConduit']),

        accountItemClass() {
            let baseClass = (this.isGroupOwner ? 'account-item-owner ' : 'account-item ');
            if (this.thisSelected) {
                return baseClass + ' selected';
            }
            else {
                return baseClass;
            }
        },

        accountNameLabel() {
            if(this.isCollaborativePortfolio){
                return this.account.accountName;
            } else {
                return '@'+this.account.userName + '(' + (this.account.accountName ? this.account.accountName : (this.account.currencyId ==1 ? 'CAD' : 'USD')) + ')';
            }
        }
    },

    watch: {
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            // console.log("account list item detected group conduit changed ", newConduit);
            this.thisSelected = this.isItemSelected(newConduit);
            // console.log("selectedGroupConduit1", this.account, this.thisSelected);
        },
    },

    methods: {

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item) {
            // console.log("selectedGroupConduit", this.account
            //     , item.type == "portfolio"
            //     , item.obj.investmentAccountId == this.account.investmentAccountId
            //     , item.obj.groupPortfolioId == this.account.groupPortfolioId);
            return this.account
                && item.type == "portfolio"
                && item.obj.investmentAccountId == this.account.investmentAccountId
                && item.obj.groupPortfolioId == this.account.groupPortfolioId;
        },

        // channel data that has property 'state' will come from your own channels that you belong to, meaning that you're already a member
        onAccountClick() {
            if (this.$store.state.chatMode == 'groups') {
                EventBus.emit("select-group-portfolio", {portfolio: this.account});
            }
        },

        deleteAccount() {
            EventBus.emit('open-delete-confirm-modal', { account: this.account, callback: ()=>this.$emit('account-deleted') } );
        },

    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text {
    background: white !important;
    color: #32364e !important;
}

.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.hidden {
    visibility: hidden;
}

.account-item {
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px 0px 16px;
    border-radius: 8px;
}

.account-item-owner {
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px 0px 16px;
    border-radius: 8px;
}

.account-item:hover,
.account-item-owner:hover {
    background: #f2f4fa;
    cursor: pointer;
}

.account-item.selected,
.account-item-owner.selected {
    background: #f2f4fa;
    cursor: unset;
}

/* .account-item:hover .account-title {
    color: #FFFFFF;
} */

.account-item-icon {
    margin-right: 4px;
    font-size: 14px;
}

.collaborative-account-icon {
    margin-right: 2px;
    font-size: 18px !important;
}

.p-avatar {
    min-width: 48px;
}



.account-item .account-title {
    /* font-weight: bold; */
    color: #32364e;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.account-item-title {
    margin-right: auto;
}

.account-item .account-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.account-item .time-posted {
    white-space: nowrap;
    font-size: 14px;
}

.account-item .more-options {
    margin-left: auto;
}

.account-item .account-item-name-container {
    display: flex;
    flex-direction: column;
}

.account-item-dm .account-item-name-container {
    max-width: 70%;
}

.account-item-dm-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.account-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.account-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover {
    border-color: #FFFFFF !important;
}

.block-btn {
    /* margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px; */
    border-radius: 16px;
    font-size: 0.8rem;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
}

.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;

}
</style>