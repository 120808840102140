<template>
    <div ref="portfolioItem"  @click.stop="onPortfolioClick()">

        <div :class="portfolioItemClass">
            <div class="expend-icon-container" @click.stop="onPortfolioExpendClick()">
                <i :class='"pi portfolio-item-icon " + (showAccounts ? "pi-angle-down" : "pi-angle-right")'></i>
                <span v-if="isCollaborativePortfolio" class="material-symbols-outlined">handshake</span>
                <i v-else class="portfolio-item-icon pi pi-briefcase"></i>
                <i v-if="portfolio?.collaborationSettings?.suspendTradingFloor" v-tooltip.bottom="'Suspended Trading Floor'" 
                    class="pi pi-exclamation-triangle portfolio-item-icon"></i>
            </div>

            <span class="portfolio-item-title">{{ displayPortfolioTitle() }}</span>
            <template v-if="portfolio && portfolio.groupPortfolioId > 0">
                <Button class="action-button p-button-text" style="margin-left:auto;" icon="pi pi-ellipsis-v" @click="togglePortfolioMenu($event)" aria-haspopup="true" aria-controls="overlay_menu" />
                <Menu ref="portfolioMenu" id="overlay_menu" :model="portfolioMenuOptions" :popup="true" />
            </template>
           


            <!-- <i v-if="isMobile()" :class="(thisSelected && !isSubItemSelected() ? '' : 'hidden ') + 'pi menu-active pi-check'" /> -->
        </div>

        <GroupPortfolioListSubItem v-show="showAccounts" v-for='account in accounts' :key='account.groupPortfolioId' :isCollaborativePortfolio="isCollaborativePortfolio"
            :sideMenuWidth="sideMenuWidth" :account="account" :isGroupOwner="isGroupOwner" @account-deleted="loadPortfolioAccounts" :portfolioLocked="portfolio.locked" />

    </div>
</template>

<script>
import GroupPortfolioListSubItem from './GroupPortfolioListSubItem.vue';

import GroupService from '../../service/GroupService';
import BrowserUtils from '../../utilities/BrowserUtils';

import EventBus from '../../event-bus';
import { mapState } from 'vuex';
import { COLLABORATIVE_PORTFOLIO_TYPE } from '../../common/constants';
import Menu from 'primevue/menu';

export default {
    name: 'GroupPortfolioListItem',
    props: ['portfolio', 'sideMenuWidth', "isGroupOwner", "isGroupAdmin"],
    emits: ['portfolio-clicked'],
    components: {
        GroupPortfolioListSubItem,
        Menu,
    },

    data() {
        return {
            COLLABORATIVE_PORTFOLIO_TYPE,

            thisSelected: false,
            showAccounts: false,
            accounts: [],
        };
    },

    mounted() {
        // console.log("mounted PortfolioListItem", this.selectedGroupConduit);
        this.thisSelected = this.isItemSelected(this.selectedGroupConduit);
        this.showAccounts = this.isSubItemSelected();
        if(this.showAccounts){
            this.loadPortfolioAccounts();
        }
    },

    computed: {
        ...mapState(['selectedGroup']),
        ...mapState(['selectedGroupConduit']),

        portfolioItemClass() {
            let baseClass = (this.isGroupOwner ? 'portfolio-item-owner ' : 'portfolio-item ');
            if (this.thisSelected && !this.isSubItemSelected()) {
                return baseClass + ' selected';
            }
            else {
                return baseClass;
            }
        },

        isCollaborativePortfolio(){
            return this.portfolio?.groupPortfolioTypeId === COLLABORATIVE_PORTFOLIO_TYPE.ID;
        },

        portfolioMenuOptions() {
            let menuOptions = [];

            if( this.isGroupOwner || this.isGroupAdmin) {
                if( this.isCollaborativePortfolio ){
                    menuOptions.push( 
                        {
                            label: 'Add Account',
                            icon: 'pi pi-plus',
                            command: ()=> {
                                this.addAccount();
                            },
                            disabled: this.portfolio?.locked
                        },
                    );
                }
                else {
                     menuOptions.push( 
                        {
                            label: 'Add Your Account',
                            icon: 'pi pi-plus',
                            command: ()=> {
                                this.addAccount();
                            },
                            disabled: this.portfolio?.locked
                        },
                    );
                }

                menuOptions.push(
            
                    {
                        label: 'Edit',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.editPortfolio();
                        }
                    }
                );
               menuOptions.push(
                    {
                        label: 'Delete',
                        icon: 'pi pi-trash',
                        command: ()=> {
                            this.deletePortfolio();
                        }
                    },
               );

            }
            else if(!this.isCollaborativePortfolio) {
                 menuOptions.push( 
                    {
                        label: 'Add Your Account',
                        icon: 'pi pi-plus',
                        command: ()=> {
                            this.addAccount();
                        },
                        disabled: this.portfolio?.locked
                    },
                );
            }
            return menuOptions;

        }
    },

    watch: {
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            // console.log("portfolio list item detected group conduit changed ", newConduit);
            this.thisSelected = this.isItemSelected(newConduit);
        },
    },

    methods: {

        togglePortfolioMenu(event) {
            event.stopPropagation();
            this.$refs.portfolioMenu.toggle(event);
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item) {
            return item && this.portfolio
                && item.groupId == this.portfolio.groupId
                && item.type == "portfolio" 
                && item.obj.groupPortfolioId == this.portfolio.groupPortfolioId;
        },

        isSubItemSelected() {
            return this.thisSelected && this.selectedGroupConduit.obj.investmentAccountId != undefined;
        },

        onPortfolioClick() {
            this.showAccounts = !this.showAccounts;
            this.loadPortfolioAccounts();
            if (this.$store.state.chatMode == 'groups') {
                this.$emit('portfolio-clicked', this.portfolio);
            }
        },

        onPortfolioExpendClick() {
            this.showAccounts = !this.showAccounts;
            this.loadPortfolioAccounts();
        },

        deletePortfolio() {
            EventBus.emit('open-delete-confirm-modal', { 'portfolio' : this.portfolio } );
        },

        addAccount() {
            
            EventBus.emit("open-add-group-portfolio-account-modal", { portfolio : this.portfolio, callback: () => this.loadPortfolioAccounts() });
        },

        editPortfolio() {
            
            EventBus.emit("open-create-edit-group-portfolio-modal", { portfolio: this.portfolio });
        },

        displayPortfolioTitle() {
            // console.log("displayPortfolioTitle", this.portfolio.name);
            return this.portfolio.name;
        },

        loadPortfolioAccounts() {
            // if(this.accounts && this.accounts.length > 0){
            //     EventBus.emit('initialize-portfolio');
            //     return;
            // }
            // console.log("loadPortfolioAccounts", this.accounts && this.accounts.length > 0, this.accounts);
            const groupId = this.selectedGroup.groupId;
            GroupService.getGroupPortfolioAccounts(groupId, this.portfolio.groupPortfolioId).then(resp => {
                if (resp.data.status == 'success') {
                    if(resp?.data?.portfolioAccounts?.length > 0 && this.portfolio.groupPortfolioId == resp.data.portfolioAccounts[0].groupPortfolioId){
                        resp.data.portfolioAccounts.map( account => {
                            account["groupId"] = groupId;
                        });
                        this.accounts = resp.data.portfolioAccounts;
                    }

                    EventBus.emit('initialize-portfolio');
                }
            });
        },

    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text {
    background: white !important;
    color: #32364e !important;
}

.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.hidden {
    visibility: hidden;
}

.portfolio-item {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}

.portfolio-item-owner {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}

.portfolio-item:hover,
.portfolio-item-owner:hover {
    background: #f2f4fa;
    cursor: pointer;
}

.portfolio-item.selected,
.portfolio-item-owner.selected {
    background: #f2f4fa;
    cursor: unset;
}

/* .portfolio-item:hover .portfolio-title {
    color: #FFFFFF;
} */

.portfolio-item-icon {
    margin-right: 2px;
    font-size: 14px;
}

.material-symbols-outlined {
    font-size: 16px;
}

.p-avatar {
    min-width: 48px;
}



.portfolio-item-title {
    color: #32364e;
    font-size: 14px;
    margin-right: auto !important;
}

.portfolio-item .portfolio-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.portfolio-item .time-posted {
    white-space: nowrap;
    font-size: 14px;
}

.portfolio-item .more-options {
    margin-left: auto;
}

.portfolio-item .portfolio-item-name-container {
    display: flex;
    flex-direction: column;
}

.portfolio-item-dm .portfolio-item-name-container {
    max-width: 70%;
}

.portfolio-item-dm-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.portfolio-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.portfolio-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover {
    border-color: #FFFFFF !important;
}

.block-btn {
    /* margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px; */
    border-radius: 16px;
    font-size: 0.8rem;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
    flex-shrink: 0 !important;
}

.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;

}

.expend-icon-container {
    display:flex;
    align-items: center;
    height: 25px;
    cursor: pointer;
}
</style>