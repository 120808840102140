import APIService from '@/common/APIService';

class PaymentServiceImpl {
  createCheckoutSession(subscriptionId) {
    return APIService.post('/payments/create-checkout-session', {
      subscriptionId: subscriptionId,
    });
  }

  createPortalSession() {
    return APIService.post('/payments/create-portal-session', {});
  }

  createPlayDoughCheckoutSession(wadSelection) {
    return APIService.post('/payments/create-playdough-checkout-session', wadSelection);
  }

  createGroupPlayDoughCheckoutSession(groupId, wadSelection) {
    wadSelection['groupId'] = groupId;
    return APIService.post('/payments/create-group-playdough-checkout-session', wadSelection);
  }

  createCompetitionResetCheckoutSession(req) {
    return APIService.post('/payments/create-reset-checkout-session', req);
  }

  createGroupCourseRegistrationCheckoutSession(req) {
    return APIService.post('/payments/create-group-course-reg-checkout-session', req);
  }

  createGroupPlanCheckoutSession(groupId, subId) {
    return APIService.post('/payments/create-group-plan-checkout-session', {groupId: groupId, subscriptionId: subId});
  }

  createGroupMembershipCheckoutSession(groupId, productId, returnParams){
    return APIService.post('/payments/create-group-membership-checkout-session', {groupId: groupId, productId: productId, returnParams: (returnParams ? returnParams: null)});
  }

  createGroupCompetitionCheckoutSession(groupContest){
    return APIService.post('/payments/create-group-competition-checkout-session',groupContest);
  }


  createGroupPortalSession(groupId) {
    return APIService.post('/payments/create-group-portal-session/'+groupId, {});
  }


  createFreeTrial(subTypeId) {
    return APIService.post('/payments/create-free-trial', {
      subscriptionTypeId: subTypeId
    });
  }

  getAllSubscriptions(userId) {
    return APIService.get(`/payments/${Number(userId)}/subscriptions`);
  }

  issueUsagePass(passType) {
    return APIService.post('/payments/issue-pass', {passTypeId: passType});
  }

  getSellerOnboardingLink(){
    return APIService.get(`/payments/seller/onboarding/link`);
  }

  getSellerAccountStatus(){
    return APIService.get(`/payments/seller/status`);
  }

  getGroupSubscriptions(groupId){
    return APIService.get(`/payments/group/${groupId}/subscriptions`);
  }

  updateGroupSubscriptions(req){
    return APIService.post(`/payments/group/${req.groupId}/subscriptions`, req);
  }

  disableGroupSubscriptions(groupId){
    return APIService.put(`/payments/group/${groupId}/subscriptions/disable`, {});
  }

  getGroupCompetitionProduct() {
    return APIService.get('/payments/group-comp-prod');
  }
}

const PaymentService = new PaymentServiceImpl();

export default PaymentService;