<template>
    <Dialog header="Clone Course" v-model:visible="display" :modal="true" :closable="true" class="clone-course-modal"  >
            <!-- <div><span style="font-size: 20px;">
                               Select the target education group. 
                            </span>
            </div> -->
       
            <div style="font-size: 20px;margin-left:20px;">
                              Select the target education group.
                            </div>
           <ScrollPanel style="width: 100%; height: 90%">
            <template v-for="group in groups" :key="group.groupId">
                <div class="card">
                    <div class="flex-row" style="width: 80%;">
                        <UserAvatar :imageSrc="group?.avatar" size='lg' shape='roundedSquare'/>
                        <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
                            <span style="font-size: 20px;">
                                {{ group?.name }}
                            </span>
                        
                            <Button label="Select" @click='selectGroup(group)' class="green-button__secondary action-button">
                                
                            </Button>
                            
                        </div>
                    </div>
                </div>
            </template>
           </ScrollPanel>
    </Dialog>
    <ConfirmModal headerText='Confirm Clone' confirmButtonLabel='Yes'  @confirmed="startClone()" ref='confirmCloneModal' >
        <template #content>
            <div style="padding: 10px;">
            Are you sure you want to clone the course to <strong>{{selectedGroup.name}}</strong>?
            </div>
        </template>
    </ConfirmModal>

</template>

<script>

import Dialog from 'primevue/dialog';
import UserAvatar from '../../profile/UserAvatar.vue'
//import EducationGroupList from '../../education/EducationGroupList.vue';
import GroupService from '../../../service/GroupService';
import ScrollPanel from 'primevue/scrollpanel';
import ConfirmModal from '../../modal/ConfirmModal.vue';
export default {
    name: 'CloneCourse',
    components: {
        Dialog,
        UserAvatar,
        ScrollPanel,
        ConfirmModal,
        //EducationGroupList,
    },
    data() {
        return {
            display: false,
            groupsLoading: true,
            groups: [],
            selectedGroup: null,
            confirmMessage: '',
            srcCourse: null,
        }
    },

    methods: {
        open(srcCourse) {
            this.selectedGroup = null;
            this.groups = [];
            this.confirmMessage = '';
            this.display = true;
            this.srcCourse = srcCourse;
            this.fetchEducationGroups();
            
        },

        fetchEducationGroups() {
            this.groupsLoading = true;
            return GroupService.getEducationalGroups(100,0).then(resp => {
                this.groupsLoading = false;
                if (resp.data.status == "success") {

                    this.groups = resp.data.groups;

                    
                }
            });
        },

        selectGroup(group){
            this.selectedGroup = group;
            this.confirmMessage = "Are you sure you want to clone the course to "+ group.name+"?";
            this.$refs.confirmCloneModal.open();
        },

        startClone() {
            GroupService.cloneGroupCourse(this.srcCourse, this.selectedGroup).then(resp => {
                 if (resp.data.status == "success") {
                    this.$toast.add({severity:'success', summary: 'Course cloning started', detail:'This may take a few minutes to complete, depending on the size of the course.', life: 5000, group: 'center'});
                    this.display = false;
                 }
                 else {
                    if( resp.data.message.startsWith('Not Authorized')){
                         this.$toast.add({severity:'error', summary: 'Error cloning course', detail:'You must be the owner or admin for the target group.', life: 5000, group: 'center'});
                   
                    }
                    else {
                        this.$toast.add({severity:'error', summary: 'Error cloning course', detail:'An expected error occurred cloning the course.', life: 5000, group: 'center'});
                    }
                 }
            })
            .catch(error => {
                console.log("unexpected network error", error);
            });
        }

    }
}
</script>

<style scoped>
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.action-button {
    padding: 8px 16px;
    border-style: solid;
    border-radius: 28px;
    text-align: center;
    width: 200px;
}

.action-button span {
    width: 100%;
}
</style>

<style>
.clone-course-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    height: 380px;
    border-radius: 16px;
}

.clone-course-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.clone-course-modal .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    /* overflow: hidden; */
    padding: 0px;
    height: 100%;
}
</style>
<style scoped>

</style>