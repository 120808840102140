<template>
    <Dialog header="Manage Cohorts" v-model:visible="display" :modal="true" class='cohort-modal' :draggable='false'>
        <div class="container">
            
         <Button class='p-button' icon="pi pi-plus" label="Add Cohort" style="width:150px;margin-bottom:5px;margin-left:auto;"
                         @click='openEditModal(null)' />  
        <DataTable id='cohortsTable' :value="cohorts" :loading='showLoading' :totalRecords="totalCohorts"
           :resizableColumns="true" columnResizeMode="expand" :paginator="true"
            :rows="limitPerPage" :lazy="true" @page="onPage">

            <Column field="cohortName" header="Name" headerClass="motions-header" class="motions-column" />
            
            <Column header="Max Size" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ slotProps.data.maxMembers }}
                </template>
            </Column>
            <Column header="Start Date" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ format(new Date(slotProps.data.startDate), 'yyyy/MM/dd') }}
                </template>
            </Column>
            <Column header="End Date" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{  format(new Date(slotProps.data.endDate), 'yyyy/MM/dd') }}
                </template>
            </Column>
            <Column header="Reg. Deadline" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ (slotProps.data.registrationDeadline ? format(new Date(slotProps.data.registrationDeadline), 'yyyy/MM/dd') : '-') }}
                </template>
            </Column>
            <Column header="Created" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ slotProps.data.createdDate }}
                </template>
            </Column>
             <Column header="Revised" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ slotProps.data.revisionDate }}
                </template>
            </Column>
            <Column header="full" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    {{ slotProps.data.full }}
                </template>
            </Column>
           
            <Column header="Actions" headerClass="motions-header" class="motions-column">
                <template #body="slotProps">
                    <Button class='p-button-icon-only action-button-blue blue-button' icon="pi pi-link" style="margin-right:3px;"
                        v-tooltip.bottom="'Copy Registration Link'" @click='getCourseCohortRegistrationParam(slotProps.data.cohortId)' />
                    <Button class='p-button-icon-only action-button-blue blue-button' icon="pi pi-pencil" style="margin-right:3px;"
                        v-tooltip.bottom="'Edit'" @click='openEditModal(slotProps.data)' />
                    <Button class='p-button-icon-only action-button-blue blue-button' icon="pi pi-users" style="margin-right:3px;"
                        v-tooltip.bottom="'Members'" @click='openMembersModal(slotProps.data)' />
                   
                    
                    <Button class='p-button-icon-only action-button-blue blue-button' icon="pi pi-trash"
                        v-tooltip.bottom="'Delete'" @click='openDeleteModal(slotProps.data)'>
                       
                    </Button>
                </template>
            </Column>
            <template #empty v-if='!showLoading'>
                <div style="text-align: center;">
                    <p>No Cohorts Yet</p>
                   
                </div>
            </template>
        </DataTable>
    
        </div>

        <template #footer>
            <!-- <Button class='join-button' label='Join' :disabled="joinDisabled" @click='joinCourse()' :loading="checkingOut"/> -->
            <Button class='cancel-button' label='Close' @click='close()'/>
        </template>
    </Dialog>
   <AddEditCohortModal ref="addEditCohortModal" @cohort-saved="onCohortSaved()" :selectedCourse="selectedCourse"/>
   <CohortMembersModal ref="membersModal"/>
   <ConfirmModal :headerText="deleteCohortHeaderText" ref="confirmDeleteCohortModal" @confirmed="onDeleteCohortConfirmed"/>
</template>

<script>
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import GroupService from '../../../service/GroupService';
import AddEditCohortModal from './AddEditCohortModal.vue';

import format from 'date-fns/format';
import CohortMembersModal from './CohortMembersModal.vue';

//import PaymentService from '../../../service/PaymentService';
//import format from 'date-fns/format';
//import Dropdown from 'primevue/dropdown';
import EventBus from '../../../event-bus';
import ConfirmModal from '../../modal/ConfirmModal.vue';


import {APP_URL} from '../../../common/config';

export default {
    name: 'CohortModal',
    emits: ['cohorts-changed'],
    props: {
        selectedCourse: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            format,
            display: false,
            cohorts: [],
            selectedCohort: null,
            totalCohorts: 0,
            showLoading: false,
           currentOffset: 0,
           limitPerPage: 5,

            
        }
    },
    components: {
        Dialog, 
        DataTable,
        Column,
        AddEditCohortModal,
        CohortMembersModal,
        ConfirmModal
        
    },

    watch: {
       

    },

    computed: {
        deleteCohortHeaderText() {
            return "Delete "+this.selectedCohort?.cohortName+"?";
        }
    },

   
    methods: {

        getCourseCohortRegistrationParam(cohortId) {
            GroupService.generateGroupCourseCohortRegisterParam(this.selectedCourse.groupId, this.selectedCourse.courseId, cohortId).then(resp => {
                if (resp.data.status === 'success') {
                    try {
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(`${APP_URL}/index.html#/signup?courseRegCode=${resp.data.param}`);
                        }
                        this.$toast.add({ severity: 'success', summary: 'The link  was copied to your clipboard.', life: 2000, group: 'center' });
                    } catch (err) {
                        console.debug('Oops, unable to copy'+err);
                    }
                } else {
                    this.$toast.add({ severity: 'error', detail: resp.data.message, summary: 'Unexpected Error', life: 2000, group: 'center' });
                }
            })
        },

        onCohortSaved() {
            this.loadCohorts(this.selectedCourse);
            this.$emit('cohorts-changed');
            EventBus.emit('refresh-my-feed-list');
            EventBus.emit('refresh-my-channel-list');
            this.$store.commit('MARK_CHANNELS_STALE');
        },

        openEditModal(cohort) {
            console.log("open edit modal", cohort);
            this.$refs.addEditCohortModal.open(cohort);
        },

        openMembersModal(cohort){
            console.log("open members modal", cohort);
            this.$refs.membersModal.open(this.selectedCourse, cohort);
        },

        onDeleteCohortConfirmed() {
            GroupService.deleteCohort(this.selectedCourse, this.selectedCohort).then(resp=> {
                if( resp.data.status == 'success'){
                    this.loadCohorts(this.selectedCourse);
                    this.$emit('cohorts-changed');
                }
                else {
                    console.log("error deleting cohort:", resp.data.message);
                }
            })
        },

        openDeleteModal(cohort){
            console.log("open delete modal", cohort);
            this.selectedCohort = cohort;
            this.$refs.confirmDeleteCohortModal.open();
        },
       
        loadCohorts(course) {
            console.log("loading cohorts for coures:", course);
            this.showLoading = true;
             GroupService.getAllCourseCohorts(course, this.limitPerPage, this.currentOffset).then(resp => {
                if( resp.data.status == "success") {
                    this.cohorts = resp.data.cohorts;
                    this.totalCohorts = resp.data.totalCohorts;
                }
                else {
                    console.log("error getting cohorts: "+ resp.data.message);
                }
                this.showLoading = false;
             });

        },
       
        open() {
           
            this.selectedCohort = null;
            this.currentOffset = 0;
            this.limitPerPage = 5;
            this.cohorts = [];
            this.display = true;
            
            this.loadCohorts(this.selectedCourse);
        },

        close() {
            this.display = false;
            this.showLoading = false;
            this.cohorts = [];
            this.currentOffset = 0;
            this.limitPerPage = 5;
            this.selectedCohort = null;
            
        },

        onPage(event) {
            this.currentOffset = event.first;
            this.loadCohorts(this.selectedCourse);
        },


    },
}
</script>

<style>

.cohort-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 80rem;
    border-radius: 16px;
}

.cohort-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.cohort-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.cohort-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.cohort-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}


.course-selector {
    margin: 5px;
}
.cohort-selector {
    margin: 5px;
}

</style>