<template>
  
<div class="group-course-list">
    <DataTable v-if="!initLoading && (courses && courses.length > 0)" scrollable  scrollHeight="flex"
        @rowReorder="onRowReorder" :value="courses" class="p-datatable-sm">
        <Column v-if="isGroupOwnerOrAdmin && !isMobile()" rowReorder class="column reorder-column" />
        <Column class="column">
            <template #body="course">
                <GroupCourseListItem :key='course.data.courseId' @course-clicked="onCourseClick" @module-clicked="onFeedClick" :course="course.data"
                    :isGroupOwnerOrAdmin="isGroupOwnerOrAdmin" :showModules="showModules"/>
            </template>
        </Column>
    </DataTable>
</div>

</template>

<script>
import GroupService from '../../service/GroupService';

import GroupCourseListItem from './GroupCourseListItem.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import UserUtils from '../../utilities/UserUtils';
import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'GroupCourseList',
    emits: ['course-selected','module-clicked'],
    props: {
        selectedGroup: {
            type: Object,
            required: true
        },
        sideMenuWidth: {
            type: Number,
            default: 0
        },
        showModules: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            courses: [],
            noMoreResults: false,
            totalMatches: 0,
            header: '',

            currentlyVisible: false,

            deleteHandler: null,
            wasUnmounted: false,
            fetchInProgress: false,
        }
    },
    components: {
        GroupCourseListItem,
        DataTable,
        Column
    },
    computed: {
        ...mapState(['feedNotificationCounts','selectedGroupConduit']),

        isGroupOwnerOrAdmin() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },
    },

    mounted() {
        EventBus.off('refresh-courses-list');
        EventBus.on('refresh-courses-list', () => {
            //this.initFetchGroupCourses("refresh-courses-list event");
            this.refetchGroupCourses();
        });
        EventBus.off('select-default-course');
        EventBus.on('select-default-course', this.selectDefaultCourse);
        this.initFetchGroupCourses("mounted");
    },

    unmounted() {
        this.wasUnmounted = true;
    },

    watch: {
        selectedGroup(newVal, oldVal) {

            if (oldVal && newVal) {
                if (this.wasUnmounted || (oldVal.groupId != newVal.groupId) || oldVal.isMember != newVal.isMember) {

                    this.initFetchGroupCourses("selectGroupWatcher");
                }

            }
        },

        $route(newRoute, oldRoute) { //eslint-disable-line
            if (!newRoute.fullPath.startsWith('/groups/profile/')) {
                this.wasUnmounted = true;
            }

        },
    },

    methods: {

        selectDefaultCourse(courseId) {
            if( this.courses?.length > 0 ){
                if( courseId ){
                    for( var i = 0; i < this.courses.length; ++i ){
                        if( this.courses[i].courseId == courseId){
                            EventBus.emit("select-group-course", this.courses[i]);
                            break;
                        }
                    }
                }
                else {
                    if( !this.isMobile()){
                        EventBus.emit("select-group-course", this.courses[0]);
                    }
                }
            }
        },

         isMobile() {
            return BrowserUtils.isMobile();
        },

        onRowReorder(event) {
            this.courses = event.value;
            const groupCourseIds = this.courses.map(course => course.courseId);
            //console.log("onRowReorder", event.value, groupCourseIds);
            GroupService.updateGroupCourseOrdering(this.selectedGroup.groupId, groupCourseIds).then(resp => {
                if (resp.data.status == "success") {
                   // console.log("success", resp);
                }
            });
        },

        initFetchGroupCourses(context) {//eslint-disable-line
            //console.log("initFetchGroupCourses called from ", context);
            this.courses = [];
            this.initLoading = true;

            this.fetchGroupCourses();
        },

        refetchGroupCourses() {
            this.courses = [];
           

            this.fetchGroupCourses();
        },

        fetchGroupCourses() {
            if (!this.fetchInProgress) {
                this.fetchInProgress = true;
                GroupService.getGroupCourses(this.selectedGroup.groupId).then(resp => {

                    try {

                        this.handleLoadingResp(resp);
                    } finally {
                        this.fetchInProgress = false;
                    }

                }).catch(err => {
                    console.log("error getting group courses: ", err);
                    this.fetchInProgress = false;
                });
            }

        },

        handleLoadingResp(resp) {


            this.loading = false;
            //TODO handle paging - depends on how we're going to implement ui
            if (resp.data.status == "success") {
                //console.log("got  "+ resp.channels.length+" channels")
                if (resp?.data?.courses?.length > 0 && this.selectedGroup.groupId == resp.data.courses[0].groupId) {
                    this.courses = resp.data.courses;
                }
                this.noMoreResults = true;
                // if( this.courses?.length > 0 ) {
                //     EventBus.emit("select-group-course", this.courses[0]);
                // }
                // console.log("selectedGroupConduit", this.selectedGroupConduit);
                // if( !this.$store.state.lastViewedCourse[this.selectedGroup.groupId] && this.courses?.length > 0 ) {
                //     EventBus.emit("select-group-course", this.courses[0]);
                // }
            }
            else {
                console.error("error getting group courses :" + resp.data.message)
            }

            if (this.initLoading) {
                this.initLoading = false;
                this.wasUnmounted = false;
                this.$nextTick(() => {
                    EventBus.emit("initial-group-courses-loaded");
                })

            }
            else {
                EventBus.emit("group-courses-reloaded", this.courses); 
            }
            
        },

        onCourseClick(courseSelection) {
            this.$emit('course-selected', courseSelection);
        },

        onFeedClick(data) {
            this.$emit('module-clicked', data);
        },

    }
}
</script>

<style scoped>
.group-course-list {
    width:100%;
}

::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.column) {
    padding: 0 !important;
    border: unset !important;
}

::v-deep(.reorder-column) {
    width: 16px !important;
    max-width: 16px !important;
    align-items: unset !important;
    margin-top: 12px !important;
}

::v-deep(.p-datatable-scrollable) {
    width: 100% !important;
}

::v-deep(.p-datatable-thead) {
    display: none !important;
}

::v-deep(.p-datatable-tbody tr td:last-child) {
    margin-right: 0 !important;
    flex-grow: 1 !important;
}

::v-deep(.p-datatable-dragpoint-bottom) {
    border-bottom: 2px solid #32364e !important;
}

::v-deep(.p-datatable-wrapper) {
    scrollbar-color: #bfbfbf #f2f2f2 !important;
    scrollbar-width: thin !important;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar) {
    width: 10px !important;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-track) {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
    transition: background-color 0.2s !important;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box !important;
    background: #bfbfbf !important;
    border-radius: 10px !important;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box !important;
    background: #bfbfbf !important;
    border-radius: 10px !important;
}
</style>