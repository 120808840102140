<template>
    <div class="group-profile-side-menu" ref="groupProfileSideMenu">
        <LoadingSpinner :showLoading="initLoading" :minHeight='(isMobile() ? "500px; zoom:75%;" : "200px; zoom:75%;")'/> 

        <div class="main-container" v-show="!initLoading">

            <TabMenu :model="sideMenuTabsItems" :activeIndex="sideMenuTabsActiveIndex">
                <template #item="{item}">
                    <button @click="selectMenuTab(item)">
                        {{ item }}
                        <span class="tab-item-notification-badge" v-if="getNotificationCount(item) > 0" v-tooltip.bottom="getNotificationCount(item) + ' unread'"></span>
                    </button>
                    <!-- <Badge v-if="getNotificationCount(item.index)" class="tab-item-notification-badge" 
                        :value="getNotificationCount(item.index)"/> -->
                </template>
            </TabMenu>
            
            

            <div class="tab-container" v-show="sideMenuTabsActiveLabel === sideMenuTabsLabels.community">
                <div class="feeds-section-container">
                    <div class="header-container">
                        <span>Feeds</span>
                        <Button v-if="canCreateFeeds()" icon="pi pi-plus-circle" class="action-button p-button-text" 
                            @click="createFeed" v-tooltip.bottom="{value:'Create Discussion Feed', escape: true}"/>
                    </div>

                    <GroupFeedList :sideMenuWidth="sideMenuWidth" @feed-selected="selectFeed" ref="myFeedList"  :selectedGroup="selectedGroup"/>
                </div>

                <div class="channels-section-container">
                    <div class="header-container">
                        <span>Text Channels</span>
                        <Button v-if="canCreateChannels()" icon="pi pi-plus-circle" class="action-button p-button-text" 
                            @click="createChannel" v-tooltip.bottom="'Create Chat Channel'" />
                    </div>
                    
                    <GroupChannelList :sideMenuWidth="sideMenuWidth" @channel-selected="selectChannel" ref="myChannelList" 
                        :selectedGroup="selectedGroup"/>
                </div>
            </div>
            
            <div class="tab-container" v-show="sideMenuTabsActiveLabel === sideMenuTabsLabels.courses">
                <div class="header-container">
                    <span>Courses</span>
                    <Button v-if="isGroupOwner || isGroupAdmin" icon="pi pi-plus-circle" class="action-button p-button-text" 
                        @click="createCourse" v-tooltip.bottom="{value:'Create Course', escape: true}"/>
                </div>
                <div style="flex-grow: 1; display: flex; height: 0;">
                    <GroupCourseList :sideMenuWidth="sideMenuWidth" @course-selected="selectCourse" @module-clicked="selectCourseModule" 
                        ref="myCourseList" :selectedGroup="selectedGroup"/>
                </div>
            </div>

            <div class="tab-container" v-show="sideMenuTabsActiveLabel === sideMenuTabsLabels.portfolios">
                <template v-if='isPortfolioFeatureEnabled() && selectedGroup.groupPortfoliosEnabled && (isGroupOwner || isGroupAdmin)'>
                    <div class="header-container">
                        <span>Cash Accounts</span>
                    </div>
                    <Button icon="pi pi-dollar" :class="cashButtonClass" :label="selectedGroup.name"
                        @click="selectCashAccount" />
                </template>
                <template v-if='isPortfolioFeatureEnabled() && selectedGroup.groupPortfoliosEnabled'>
                    <div class="header-container">
                        <span>Portfolios</span>
                        <Button v-if="isGroupOwner || isGroupAdmin" icon="pi pi-plus-circle" class="action-button p-button-text" 
                            v-tooltip.bottom="{value:'Create Portfolio', disabled: false, escape: true}" @click="createGroupPortfolio" />
                    </div>
                    <GroupPortfolioList :sideMenuWidth="sideMenuWidth" @portfolio-selected="selectPortfolio" ref="myFeedList"  :selectedGroup="selectedGroup"/>
                </template>
            </div>

        </div>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import GroupChannelList from '../chat/GroupChannelList.vue';
import GroupFeedList from './GroupFeedList.vue';
import GroupCourseList from './GroupCourseList.vue';
import GroupPortfolioList from './GroupPortfolioList.vue';
import TabMenu from 'primevue/tabmenu';

import BrowserUtils from '../../utilities/BrowserUtils';
import UserUtils from '../../utilities/UserUtils';

import EventBus from '../../event-bus';
import {mapState} from 'vuex';
//import ChatService from '../../service/ChatService';
// import EventBus from '../../event-bus';
import { hasPremiumGroupSubscription, isINVRSGroup} from './';

const sideMenuTabsLabels = {
    courses: 'Courses',
    community: 'Discuss',
    portfolios:'Portfolios'
}

const sideMenuItemTypes = {
    course: {
        tabEducational: 1,
        type: "course"
    },
    courseFeed: {
        tabEducational: 1,
        type: "courseFeed"
    },
    feed: {
        tab: 0,
        tabEducational: 0,
        type: "feed"
    },
    channel: {
        tab: 0,
        tabEducational: 0,
        type: "channel"
    },
    portfolio: {
        tab: 1,
        tabEducational: 2,
        type: "portfolio"
    },
    cashAccount: {
        tab: 1,
        tabEducational: 2,
        type: "cashAccount"
    },
}

export default {
    name: 'GroupProfileSideMenu',
    emits:['group-sidemenu-change'],
     props: {
        selectedGroup: {
            type: Object,
            required: true
        },
    }, 
    data() {
        return {
            hasPremiumGroupSubscription,
            initLoading:true,
            sideMenuTabsLabels,
    
            sideMenuTabsActiveIndex: (this.showCourses ? sideMenuItemTypes.courseFeed.tabEducational: sideMenuItemTypes.feed.tab),
            sideMenuTabsActiveLabel: (this.showCourses ? sideMenuTabsLabels.courses: sideMenuTabsLabels.community),

            sideMenuWidth: null,
            ro: null,
            channels: [],
            cashButtonSelected: false,
            userUtils: UserUtils,
            
        }
    },
    components:{
    GroupChannelList,
    GroupFeedList,
    GroupPortfolioList,
    LoadingSpinner,
    TabMenu,
    GroupCourseList,
},

    computed: {
        ...mapState(['feedNotificationCounts','groupChannelsUnreadCounts','selectedGroupConduit']),
         
        cashButtonClass() {
            if( this.selectedGroupConduit?.groupId === this.selectedGroup.groupId 
                && this.selectedGroupConduit?.type == sideMenuItemTypes.cashAccount.type ) {
                return "cash-button p-button-text selected";
            }
            else {
                return "cash-button p-button-text";
            }
        },

        isGroupOwner() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId);
        },

        isGroupAdmin() {
            return UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },

        showCourses() {
            return (this.selectedGroup?.coursesEnabled && (this.selectedGroup?.educational || hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId) ));
        },
        
        sideMenuTabsItems() {
            
            let items =  this.showCourses ? [
                
                sideMenuTabsLabels.community,
                sideMenuTabsLabels.courses
                
            ] : [
                sideMenuTabsLabels.community,
               
            ];

            if( this.isPortfolioFeatureEnabled()) {
                items.push( sideMenuTabsLabels.portfolios);
            }
            return items;
        }
    },

    watch: {
        selectedGroupConduit(newVal, oldVal) { // eslint-disable-line
            if( newVal && newVal != oldVal){
                //console.log("new group conduit ", newVal);
                //console.log("old group conduit ", oldVal);
                
                this.refreshSelectedMenuTab("selectedGroupConduit");
            }
        }
    },

    mounted() {
        EventBus.off("initial-side-menu-data-loading");
        EventBus.on("initial-side-menu-data-loading", () => {
            this.initLoading = true;
        });

        EventBus.off("initial-side-menu-data-loaded");
        EventBus.on("initial-side-menu-data-loaded", () => {
            this.initLoading = false;
            if(!this.ro){
                this.initObserver();
            }
        });

        this.refreshSelectedMenuTab("mounted");
    },

    unmounted() {
        if (this.ro) {
            this.ro.unobserve(this.$refs.groupProfileSideMenu);
        }
    },

    methods: {
        canCreateFeeds() {
            return (this.isGroupOwner || this.isGroupAdmin);
        },

        canCreateChannels() {
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId)) && (this.isGroupOwner || this.isGroupAdmin);
        },

        getNotificationCount(item){
            let count = 0;
            if(item == sideMenuTabsLabels.community && !this.showCourses){
                count = this.feedNotificationCounts?.unreadGroupNotifications[this.selectedGroup.groupId]?.allCount 
                    ? this.feedNotificationCounts.unreadGroupNotifications[this.selectedGroup.groupId].allCount : 0;
                count += this.groupChannelsUnreadCounts[this.selectedGroup.groupId]?.unreadCount 
                    ? this.groupChannelsUnreadCounts[this.selectedGroup.groupId].unreadCount : 0;
            } else if(item == sideMenuTabsLabels.community && this.showCourses){
                count += this.groupChannelsUnreadCounts[this.selectedGroup.groupId]?.unreadCount 
                    ? this.groupChannelsUnreadCounts[this.selectedGroup.groupId].unreadCount : 0;
            }

            if(count > 10){
                return "10+";
            }
            return count;
        },

        // eslint-disable-next-line no-unused-vars
        refreshSelectedMenuTab(context) {
            //console.log("refreshSelectedMenuTab start", this.selectedGroupConduit?.groupId, this.selectedGroup.groupId, context);
            if (this.selectedGroupConduit && this.selectedGroupConduit?.groupId === this.selectedGroup.groupId) {
                const index = sideMenuItemTypes[this.selectedGroupConduit.type][this.showCourses ? 'tabEducational' : 'tab'];
                
                this.sideMenuTabsActiveIndex = index;
                this.sideMenuTabsActiveLabel = this.sideMenuTabsItems[index];
                this.$emit('group-sidemenu-change', {newItem:   this.sideMenuTabsActiveLabel, oldItem: null});
                // console.log("refreshSelectedMenuTab", this.selectedGroup.educational, index, this.sideMenuTabsActiveIndex, this.sideMenuTabsActiveLabel);
            }
        },

        selectMenuTab(item) {
            this.$emit('group-sidemenu-change', {newItem: item, oldItem:this.sideMenuTabsActiveLabel});
            this.sideMenuTabsActiveLabel = item;
            this.sideMenuTabsActiveIndex = this.sideMenuTabsItems.indexOf(item);
            // EventBus.emit('group-side-menu-tab-change', item);
            //this.refreshSelectedMenuTab();
            
        },

        selectTabByIndex(index) {
            
            this.selectMenuTab(this.sideMenuTabsItems[index]);
            
        },

        isPortfolioFeatureEnabled(){
             
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId))
                &&
                (
                    (!this.isTeamGroup() && this.isGroupPortfolioEnabled())
                    || 
                    (this.isTeamGroup() && this.isTeamPortfolioEnabled())
                );
        },

        isGroupPortfolioEnabled(){
            return this.$store.state.featureSettings.groupPortfolioEnabled;
        },

        isTeamPortfolioEnabled(){
            return this.$store.state.featureSettings.teamPortfolioEnabled;
        },

        isTeamGroup() {
            return this.selectedGroup.groupTypeId == 2;
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        initObserver() {
            this.$nextTick(() => {
                //this.ro = new ResizeObserver(this.getSideMenuWidth).observe(this.$refs.groupProfileSideMenu);
            });
        },

        getSideMenuWidth(){
            if(this.$refs.groupProfileSideMenu){
                this.sideMenuWidth = this.$refs.groupProfileSideMenu.clientWidth;
            }
        },

        selectChannel(channelObj) {
            //console.log("selectChannel");
            EventBus.emit("select-channel", channelObj);
        },

        createGroupPortfolio() {
            EventBus.emit("create-group-portfolio");
        },

        selectFeed(feed) {
            // console.log("selectFeed");
            EventBus.emit("select-group-feed", feed);
        },

        selectCourse(course) {
            //console.log("selectCourse", course);
            EventBus.emit("select-group-course", course);
        },

        selectCourseModule(data) {
            //console.log("selectCourseModule", data);
            EventBus.emit("select-group-course-module", data);
        },

        selectPortfolio(portfolio) {
            // console.log("selectPortfolio");
            EventBus.emit("select-group-portfolio", {portfolio: portfolio});
        },

        selectCashAccount() {
            // console.log("selectPortfolio");
            EventBus.emit("select-cash-account");
        },

        createChannel(){
            if(this.isGroupOwner || this.isGroupAdmin) {
                EventBus.emit("open-create-channel-modal");
            }else {
                 this.$toast.add({severity:'error', summary: 'Only group owners and admins can add channels.', detail:'', life: 5000, group: 'tl'});
            }
        },

         createFeed(){
            if(this.isGroupOwner || this.isGroupAdmin) {
                EventBus.emit("open-create-edit-group-feed-modal");
            }else {
                 this.$toast.add({severity:'error', summary: 'Only group owners and admins can add feeds.', detail:'', life: 5000, group: 'tl'});
            }
        },

        createCourse(){
            if(this.isGroupOwner || this.isGroupAdmin) {
                EventBus.emit("open-create-edit-group-course-modal");
            }else {
                 this.$toast.add({severity:'error', summary: 'Only group owners and admins can add courses.', detail:'', life: 5000, group: 'tl'});
            }
        },
       
    }
}
</script>

<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}

.tab-item-notification-badge {
  position: absolute;
  top: -1px;
  font-size: 10px;
  text-align: center;
  width: 8px !important;
  height: 8px !important;
  background-color: #e63e3e;
  border-radius: 30px;
  display: inline-block;
}

.group-profile-side-menu {
    height: 0;
    flex-grow: 1;
}

.main-container {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.tab-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    height: 0;
    flex-direction: column;
}

/* .material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' -25,
  'opsz' 48

} */

.feeds-section-container {
    display: flex;
    flex-direction: column;
    max-height: 50%;
}

.channels-section-container {
    display: flex;
    flex-direction: column;
    max-height: 50%;
}

.header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.header-container span{
    font-size: 16px;
    font-weight: bold;
}
.header-container button{
    color: #32364E;
}

.feed-item {
    width:100%; 
    /* margin-bottom:10px; */
    color: #32364E;
   
    align-items: center;
    display: flex !important;
    padding: 8px 4px;
    border-radius: 8px;
    display: inline-block;
}
.feed-item:hover {
    color: #32364E;
    background: #f2f4fa;
    cursor: pointer;
}
.feed-item.selected {
    color: #32364E;
    background: #f2f4fa;
    cursor: unset;
}
.feed-item-icon {
    margin-right: 4px;
    font-size: 16px;
}

.feed-item-prime-icon {
    margin-right: 4px;
    font-size: 14px;
    position: relative;
    top: 1px;
}

.feed-item-title {
    /* font-weight: bold; */
    color: #32364e;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
}
.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;
}

.cash-button {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items:center;
    padding: 4px 4px;
    margin: 2px 0px;
    border: unset !important;
    border-radius: 8px;
    background: #fff !important;
    color: #32364e !important;
    box-shadow: unset !important;
    flex: unset !important;
}

.cash-button :last-child {
    flex: unset !important;
}

.cash-button:hover {
    background: #f2f4fa !important;
    cursor: pointer;
    color: #32364e !important;
}

.cash-button.selected {
    background: #f2f4fa !important;
    cursor: unset;
    color: #32364e !important;
}

::v-deep(.p-tabmenu) {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: unset;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
    background: #ffffff;
    color: #32364E;
    border: solid #ffffff;
    border-width: 0 0 2px 0;
    padding: 4px;
    cursor: pointer;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button:hover) {
    background: #ffffff;
    color: #32364E;
    border: solid #32364E;
    border-width: 0 0 2px 0;
    padding: 4px;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
    background: #ffffff;
    color: #33CC99;
    border: solid #33CC99;
    border-width: 0 0 2px 0;
    padding: 4px;
}

::v-deep(.p-tabmenu-nav) {
    text-align: center;
    border-width: 0;
    display: flex;
    justify-content:space-around;
    padding-bottom: 16px;
}

::v-deep(.p-tabmenuitem) {
    padding-top: 0 !important;
}
</style>