<template>
    <div :style="style">
        <div class="top-container">
            <SearchBar ref='groupMembersSearchBar' @search-input="searchInput" @loading="onLoading" placeholderText="Search for a cohort member" />
           
        </div>
        <LoadingSpinner :showLoading="initLoading"/>
        <span v-if="totalMembers">Found {{(totalMembers > 1 ? totalMembers+' members': totalMembers+" member")}}.</span>
        <infinite-scroll class="members-scroll" @infinite-scroll="loadMoreResults" message="" :noResult="noMoreSearchResults" :style='scrollStyle'>
            <UserListItem :user='member.analyst' v-for='member in searchResults' :key='member.analyst.userId' >
               <template #actions>
                    
                    
                    <template v-if="isGroupOwner || hasAdminModRights($store.getters['users/groupRoleList'])">
                        <Button class='user-actions-button red-button__secondary' icon='pi pi-delete-left' @click='confirmRemove(member)' v-tooltip.bottom="'Remove user'"/>
                    </template>
                    
                        
                </template>
                
            </UserListItem>
            <LoadingSpinner :showLoading="loading" minHeight='100px'/>
        </infinite-scroll>
    </div>

    
    <ConfirmModal headerText='Remove user?' confirmButtonLabel='Yes' @confirmed="removeUser()" ref='removeUserModal' />
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';

import SearchBar from '../query/SearchBar.vue';
import UserListItem from '../list/UserListItem.vue';


import ConfirmModal from '../modal/ConfirmModal.vue';


import GroupService from '../../service/GroupService';
import StringUtils from '../../utilities/StringUtils';
import UserUtils from '../../utilities/UserUtils';
import {USER_TYPES} from '../../common/constants.js';
import { mapState } from 'vuex';
import EventBus from '../../event-bus';

import Mark from 'mark.js';

export default {
    name: 'CurrentMemberSearch',
    
    components: {
        LoadingSpinner, InfiniteScroll, SearchBar, UserListItem,   ConfirmModal, 
    },
     props: {
        context: {
            type: String,
            required: true
        },

        style: {
            type: String,
            required: false
        },
        selectedCourse:{
            type: Object,
            required:true
        },
        selectedCohort:{
            type: Object,
            required:true
        }


    },

    data() {
        return {
            //CONSTANTS
            USER_TYPES,

            placeholderText: 'Search cohort members',
            includeBanned: false,
            selectedMember: null,
            loading: false,
            initLoading: false,
            currentText: '',
            searchResults: [],
            noMoreSearchResults: false,
            scrollStyle: "width: 100%; max-height: 100%; height: 500px;",

            // UTILITIES/SERVICES
            stringUtils: StringUtils,
            userUtils: UserUtils,
            totalMembers: null,
        }
    },
    computed: {
        ...mapState(['selectedGroup']),

        isGroupOwner() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId);
        }
      
      
    },
    

    created() {
        EventBus.off('refresh-cohort-members-list');
        EventBus.on('refresh-cohort-members-list', this.initLoadMembers);
    },
    mounted() {
        this.initLoadMembers();

       
    },

    methods: {

        hasAdminModRights(roles) {
            return UserUtils.isGroupAdmin(roles) || UserUtils.isGroupMod(roles);
        },

        removeUser() {
            GroupService.removeFromCohort(this.selectedCourse, this.selectedCohort, this.selectedMember).then(resp => {
                if( resp.data.status == 'success'){
                     this.$toast.add({severity:'success', summary: "The user was removed.", detail:'', life: 3000, group: 'center'});
                     this.initLoadMembers();
                   
                }
                else {
                    console.log("error removing user from cohort", resp.data.message);
                    this.$toast.add({severity:'error', summary: "We were unable to remove the user.  Please contact support if the problem persists.", detail:'', life: 3000, group: 'center'});
                }
            }).catch(err => {
                console.log("unexpected error removing user from cohort", err);
                this.$toast.add({severity:'success', summary: "We were unable to remove the user.  Please contact support if the problem persists.", detail:'', life: 3000, group: 'center'});
            });
        },
        
        confirmRemove(member){
            this.selectedMember = member;
            this.$refs.removeUserModal.open();
        },
        refresh() {
            this.initLoadMembers();
        },

      
       
        onLoading() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
        },
        searchInput(val) {
            this.currentText = val;
            this.fetchCohortMembersFromSearch(val, 10, 0);
        },

        initLoadMembers() {
           // this.currentText = '';
            this.loading = false;
            this.searchResults = [];
            this.initLoading = true;
            this.fetchCohortMembersFromSearch(this.currentText, 10, 0);
        },

       
       

        async loadMoreResults() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreSearchResults) {
                    this.fetchCohortMembersFromSearch(this.currentText, 10, this.searchResults.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchCohortMembersFromSearch(query, limit, offset) {
            GroupService.searchCohortMembers(this.selectedCourse, this.selectedCohort, limit, offset, query).then( resp => {
                if( resp.data.status == "success") {
                    this.initLoading = false;
                    this.loading = false;
                    const members = resp.data.members;
                    this.totalMembers = resp.data.total;
                    console.log("received "+ members.length+" members ")
                    if (members.length > 0) {
                        const results = this.searchResults.concat(members);

                        this.searchResults = results;
                        console.log("now showing "+ this.searchResults.length+" members out of "+resp.data.totalCount)

                        if(this.searchResults.length < resp.data.totalCount) {
                            this.noMoreSearchResults = false;
                        }
                        else {
                            this.noMoreSearchResults = true;
                        }

                        // Highlighting of matches in results
                        this.$nextTick(() => {
                            let instance = new Mark(document.querySelectorAll('.user-list-item-container .text-container'));

                            const regex = new RegExp(`\\b${query}|(?<=[@(])\\b${query}`, 'gi');

                            instance.unmark();
                            instance.markRegExp(regex, {
                                'className': 'search-match-highlight'
                            });
                        })
                    } else {
                        this.noMoreSearchResults = true;
                    }
                }
                else {
                    console.log("error searching cohort members:"+resp.data.message);
                }
            })
        },

    }

}
</script>

<style scoped>
::v-deep(.list-item-container) {
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.list-item-container:last-child) {
    border-bottom: none;
}

.top-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #BFBFBF;
}

::v-deep(.search-bar-container){
    flex-grow: 1;
    border-bottom: unset;
}

.include-banned-checkbox-container {
    display: flex;
    align-items: center;
    padding: 0px 16px 16px 0px;
}

.include-banned-checkbox-label {
    margin-left: 4px;
    cursor: pointer;
}

.user-actions-button {
    /* border: 1px solid #e63e3e; */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    /* background: #FFFFFF;
    color: #e63e3e; */
}

.role-pill {
    padding: 0px 8px;
    border: 1px solid #32364e;
    background: #FFFFFF;
    border-radius: 20px;
}
.role-pill ::v-deep(img) {
    width: 16px;
    margin-right: 4px;
}
.role-pill ::v-deep(.p-chip-text) {
    font-size: 11px;
}
/* .user-actions-button:hover {
    background: #e63e3e;
    border-color: #e63e3e;
}
.user-actions-button:focus {
    box-shadow: none;
    border-color: #e63e3e;
} */

@media (max-width: 760px) {
    .user-list-item-container ::v-deep(.header-text) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>