<template>
    <Dialog :header="header" id="add-edit-cohort" v-model:visible="display" :modal="true" class='cohort-modal' :draggable='false'>
        <div class="container">
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="cohortName" class='input-label'>Name:</label>
                <InputText id="cohortName" class="input-text" v-model="cohortName" style="margin-left:auto;"/>
            </div>
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="maxMembers" class='input-label'>Max Size:</label>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Limits the size of the cohort.  Optional. `"></i>
                </div>
                <InputNumber id="maxMembers" class="input-text" :maxFractionDigits="0" v-model="maxMembers" style="margin-left:auto;" />
            </div>
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="full" class='input-label'>Full:</label>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Prevents any further registration to the cohort.  Overrides Max Size if one was specified. `"></i>
                </div>
                <InputSwitch id="full" class="input-switch" v-model="full" style="margin-left:auto;" :disabled="!this.selectedCohort?.cohortId"/>
            </div>
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="startAndEndDate" class='input-label'>Cohort Date Range:</label>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`The expected start and end date for the cohort.  The idea being that it is during this period that the enrolled students are actively participating in the course.  Required.`"></i>
                </div>
                <Calendar id="startAndEndDate" dateFormat="yy/mm/dd" showIcon v-model="dateRange" :minDate="minDate"
                        selectionMode="range" :manualInput="false" placeholder="Select start and end dates." style="margin-left:auto;"/>
            </div>
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="regDeadline" class='input-label'>Reg. Deadline:</label>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Allows you to limit when new students can register for this cohort.  Recommended.`"></i>
                </div>
                <Calendar id="regDeadline" dateFormat="yy/mm/dd" showIcon v-model="regDeadline" 
                       :manualInput="true" placeholder="Select registration deadline." style="margin-left:auto;" />
            </div>
            <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
                <label for="accessCode" class='input-label' v-tooltip.top="'Forces users to enter an access code in order to register.'">Access Code:</label>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Users will only be able register for this cohort if they enter this access code.  Optional`"></i>
                </div>
                <InputText id="accessCode" class="input-text" v-model="accessCode" style="margin-left:auto;"/>
            </div>
    
        </div>

        <template #footer>
            <Button class='join-button' label='Save'  @click='saveCohort()' :loading="saving"/>
            <Button class='cancel-button' label='Close' @click='close()'/>
        </template>
    </Dialog>
   

</template>

<script>
import Dialog from 'primevue/dialog';

import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';

import GroupService from '../../../service/GroupService';
import Calendar from 'primevue/calendar';

export default {
    name: 'AddEditCohortModal',
    emits: ['cohort-saved'],
    props: {
        selectedCourse: {
            type: Object,
            required: true
        },
        
    },
    data() {
        return {
            display: false,
            dateRange: null,
            minDate: null,
            full: false,
            maxMembers: null,
            cohortName: null,
            regDeadline: null,
            saving: false,
            selectedCohort: null,
            accessCode: null,
            
        }
    },
    components: {
        Dialog, 
        InputSwitch,
        InputNumber,
        InputText,
        Calendar,
        
    },

    watch: {
       

    },

    computed: {
        header() {
            if(this.selectedCohort?.cohortId){
                return "Update Cohort";
            }
            else {
                return "Create Cohort";
            }
        }
    },

   
    methods: {

       saveCohort() {
            if( !this.cohortName || this.cohortName.trim() == ''){
                this.$toast.add({ severity: 'error', summary: 'Cohort name required.', life: 3500, group: 'center' });
                
            }
            else if( !this.dateRange || this.dateRange.length < 2) {
                this.$toast.add({ severity: 'error', summary: 'Start and end dates required.', life: 3500, group: 'center' });
                
            }
            else {
                this.selectedCohort['courseId'] = this.selectedCourse.courseId;
                this.selectedCohort['startDate'] = this.dateRange[0];
                this.selectedCohort['endDate'] = this.dateRange[1];
                this.selectedCohort['full'] = this.full;
                this.selectedCohort['maxMembers'] = this.maxMembers;
                this.selectedCohort['registrationDeadline'] = this.regDeadline;
                this.selectedCohort['cohortName'] = this.cohortName;
                this.selectedCohort['accessCode'] = this.accessCode;
                if( this.selectedCohort.cohortId ) {

                    GroupService.updateCohort(this.selectedCourse, this.selectedCohort).then(resp => {
                        if( resp.data.status == 'success'){
                             this.$emit('cohort-saved');
                            this.close();
                        }
                        else {
                            console.log("error updating cohort " + resp.data.message);
                            this.$toast.add({ severity: 'error', summary: 'Unable to update cohort.', life: 3500, group: 'center' });
                        }
                    });
                }
                else {
                     GroupService.saveCohort(this.selectedCourse, this.selectedCohort).then(resp => {
                        if( resp.data.status == 'success'){
                          
                            this.$emit('cohort-saved');
                            this.close();

                        }
                        else {
                            console.log("error updating cohort " + resp.data.message);
                            this.$toast.add({ severity: 'error', summary: 'Unable to update cohort.', life: 3500, group: 'center' });
                        }
                    });
                }
            }


        
       },
      
       
        open(cohort) {
             this.minDate = new Date();
            if( cohort )  {
                this.selectedCohort = cohort;
                this.dateRange = [ new Date(this.selectedCohort.startDate), new Date(this.selectedCohort.endDate)];
                if( this.selectedCohort.registrationDeadline) {
                    this.regDeadline = new Date(this.selectedCohort.registrationDeadline);
                }
                this.cohortName = this.selectedCohort.cohortName;
                this.full = this.selectedCohort.full;
                this.maxMembers = this.selectedCohort.maxMembers;
                this.accessCode = this.selectedCohort.accessCode;
            }
            else {
                this.selectedCohort = {};
                this.maxMembers = null;
            this.full = false;
            this.dateRange = null;
            this.regDeadline = null;
            this.cohortName = null;
            this.accessCode = null;
            this.saving = false;
            }
            this.display = true;
           
            

          
        },

        close() {
            this.display = false;
            this.minDate = new Date();
            this.maxMembers = null;
            this.full = false;
            this.dateRange = null;
            this.regDeadline = null;
            this.cohortName = null;
            this.saving = false;
            this.selectedCohort = null;
            this.accessCode = null;
            
        },

      


    },
}
</script>

<style >



#add-edit-cohort.cohort-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 400px;
    border-radius: 16px;
}

#add-edit-cohort.cohort-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#add-edit-cohort.cohort-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

#add-edit-cohort.cohort-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

#add-edit-cohort.cohort-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}
.container {
    display: flex;
    flex-direction: column;
}


.course-selector {
    margin: 5px;
}
.cohort-selector {
    margin: 5px;
}

</style>