<template>
    <div :class="(isChannelPaused ? 'paused-channel-container' : 'channel-container')">
       
        <Message v-if='displayMessage' severity='warn' > Channel is deleted </Message>
        <LoadingSpinner :showLoading="channelStateLoading"/> 
       
        <template v-if='!channelStateLoading && channel'>
            <MessageList :showReactionCounts="true" :channelMessages="chatMessages"  class='message-list-container' :channelParent='channel' :descriptionHeight="descriptionHeight" :rootElementId="rootElementId" type='groups'/>
            <div class='footer-container' v-if="isShowingGroupChatChannel()">
                <ChatEditor :placeholderText="placeholder" :isMember="isMember" @join-channel="onJoinChannel" @on-send="sendMessage" :key="isMember" ref='messageEditor' />
            </div>
           
        </template>
    </div>
</template>

<script>

import ChatEditor from './ChatEditor.vue';

import MessageList from '../chat/MessageList.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import ChatService from '../../service/ChatService';

import Message from 'primevue/message';

import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import {mapState} from 'vuex';
/* You may see a bit of if-else structure when handling with channelData, because the resp data structure being fed into channelData actually differ
when you use it from your own current list of channels (getMyChannels()) or from searching up public channels */

export default {
    name: 'Channel',
    
    props: {
        channelData: {
            type: Object,
            required: true
        },
        rootElementId: {
            type: String,
            required: false,
            default: "#appChatDrawer"
        }
       
    },
    data() {
        return {
            loading: false,
            isMember: false,
            channelStateLoading: false,
            channelEventListener: null,
            selectedMenuItem: null,
            headerText: '',
            displayMessage: false,
            channel: null,
            chatMessages: [],
            // channelDescription: '',
            descriptionHeight: 0,
            descriptionEl: null,
            descriptionVisible: true,
            descriptionAvailable: true,
            closingChannel: false,
            isChannelPaused: false,
            
        }
    },

    components: {
        ChatEditor, MessageList, Message,LoadingSpinner
    },

    computed: {
        ...mapState(['chatMode']),

        isCreator() {
            const userId = this.$store.state.users.user.userId.toString();

            if (this.channelData.data) {
                return userId === this.channelData.data.created_by.id;
            } else {
                return userId === this.channelData.channelOwnerId;
            }
        },
        placeholder() {
            return this.isMember ? 'Add a message': 'Channel members only.';
        },
    },

    watch: {
        channelData( newChannel, oldChannel) {
            if( oldChannel ) {
                
                if( BrowserUtils.isMobile() || ( newChannel.id != oldChannel.id)) {
                    this.isMember = false;
                    this.cleanupChannel();
                    this.reconnect("channelData watcher");

               }
            }
            
        },

        chatMode(newVal, oldVal) { //eslint-disable-line
            if( this.isShowingGroupChatChannel()) {
                if( newVal == 'dm') {
                    console.log("pausing group chat for dm ")
                    this.pauseConnection();
                }
                else {
                    this.reconnect("chatMode watcher");
                }
            }
        }
    },

   

    created() {
        
        EventBus.off('chat-reconnect-required');
        EventBus.on('chat-reconnect-required', this.reconnectOnVisibleAgain);

        
        EventBus.off('chat-pause-required');
        EventBus.on('chat-pause-required', this.pauseConnection);

        
        EventBus.off('delete-message');
        EventBus.on('delete-message', this.deleteMessage);

        // if (this.channelData.data && this.channelData.data.description) {
        //     this.channelDescription = this.channelData.data.description
        // } else {
        //     this.descriptionAvailable = false
        // }
    },

    mounted() {
       //console.log("Channel.vue mounting " +this.channelData.data.channelName)
       
       this.reconnect("mounted");
        
        // this.initDescription();

    },
    unmounted() {
        //console.log("Channel.vue unmounting")
        // Stops listening in on channel events to prevent memory leaks or random errors and exceptions
        if( !this.closingChannel) {
            try {
                this.cleanupChannel();
            }catch( error){
                console.log("error cleaningup Channel.vue on unmount: ", error);
            }
        }
       
        
    },

    errorCaptured(err, vm, info) {// eslint-disable-line
        console.warn("Channel capturing unexpected component error : "+err)
        return false;
    },

    methods: { 
        isShowingGroupChatChannel() {
            return this.channelData && !this.channelData.data.isDiscussion ;
        },
        async close() {
            this.closingChannel = true;
            try {
                this.cleanupChannel();
            }finally {
                this.closingChannel = false;
            }

        },

        deleteMessage(messageId) {
            ChatService.deleteMessage(messageId).then( resp => {
                
                if( resp.data.status == "success") {
                   // nothing to do the channelEventListener will handle triggering removing the message.
                   // we can change this in future if its too slow

                   this.$toast.add({severity:'success', summary: `Successfully deleted message!`, life: 3000, group: 'tc'});
                }
                else {
                    // notify user delete failed?
                    this.$toast.add({severity:'error', summary: `Failed deleting message`, life: 3000, group: 'tc'});
                    
                }
            });
        },

        async pauseConnection() {
            console.debug("pausing chat connection");
            this.isChannelPaused = true;
            await this.closeConnections();
        },

        reconnectOnVisibleAgain() {
            this.reconnect('on chat-reconnect-required event trigger');
        },

        async reconnect(context) {// eslint-disable-line
            //console.log("reconnect called from context "+ context)
            ChatService.waitForClient().then( async () => {
                this.isChannelPaused = false;
                this.channelStateLoading = true;
                
                

                //console.debug("connecting/reconnecting to channel ", this.channelData);
                
                this.channel = await ChatService.connectToChannel(this.channelData);
                
                try {
                    let resp = await this.channel.watch();
                    this.channelStateLoading = false;

                    
                    ChatService.setCurrentChannel( (this.channelData.data) ? this.channelData.data.chatChannelId : this.channelData.chatChannelId )
                        .catch(error => { console.log("error setting current channel "+error);});
                    // If we're trying to obtain membership for a channel we selected from searching channels
                    if (resp.membership) {
                        this.isMember = true;
                    }
                    

                    this.chatMessages = resp.messages;
                        

                    // When users are in the channel - Watching it and listen to all types of events
                    this.channelEventListener = this.channel.on(event => {
                        /* This is for other members within the same channel, they will get a message saying that the channel is deleted 
                        and it will refresh their current list of channels */
                        if (event.type === 'channel.deleted') {
                            if (!this.isCreator) {
                                this.displayMessage = true;
                                
                                this.$store.commit('MARK_CHANNELS_STALE')
                            }
                        } else if (event.type === 'message.new') {
                            EventBus.emit('update-message-list', event.message);
                        
                        } else if( event.type === 'message.deleted') {
                        
                            EventBus.emit('message-deleted', event.message);
                        }else if( event.type == 'reaction.new' || event.type == 'reaction.updated'|| event.type == 'reaction.deleted') {

                            EventBus.emit('update-message', event.message);
                        } else {
                           // console.log("received unknown event : "+ JSON.stringify(event));
                        }
                        
                        
                        
                    });

                    this.markChannelMessagesRead();
                    

                
                } catch(error ) {
                    //const summary = `Something went wrong with trying to access the channel data. Contact Support and write a ticket.`;

                    console.debug('There was an error attempting to watch channel:', error);

                    if(error.message.includes("not allowed to access Resource ReadChannel on channel type privateMessaging")){
                        this.$toast.add({severity:'error', summary: `Go to the Settings and unblock the user`, life: 3000});
                        EventBus.emit('deselect-channel');
                    }
                }
            });
           
        },

       
        async cleanupChannel() {
            if( this.channel ) {
                EventBus.emit('mark-messages-read', this.channel.id);
                this.$store.commit('REMOVE_GROUP_CHANNEL_UNREAD_COUNTS', this.channel.data);
            }
            ChatService.setCurrentChannel(null).catch(error => {console.error("error clearning current channel "+error);});       
            //     console.log("cleaning up channel ");
            //     await this.channel.markRead().then( async () => {
            //         console.log("mesages marked as read ");
            //         await this.closeConnections();
            //     })
            //     .catch( async error => {
            //         console.warn("error marking messages as read when closing channel: ", error);
            //         await this.closeConnections();
            //     });
            // }
            // else {
                await this.closeConnections();
            //}
        },

        async closeConnections() {
        
            try {
                
                if( this.channelEventListener) {
                    //console.debug("unsubscribing channel event listener");
                    await this.channelEventListener.unsubscribe();
                }
                    
            } catch( error) {
                console.warn("error unsubscribing channel on unmount "+error );
            }
            try {
                
                ChatService.freeChatClient("Channel.closeConnections()");
                

            }catch( error) {
                console.warn("error disconnecting chat client"+error );
            }
        },


        markChannelMessagesRead() {
            // console.log("markChannelMessagesRead");
            this.channel.markRead().then(()=> {
                
            })
            .catch( error => {
                console.warn("error marking channel messages read: "+ error);
            });
            
            this.$store.commit('REMOVE_GROUP_CHANNEL_UNREAD_COUNTS', this.channel.data);
        },

        sendMessage(msg) {
            if( msg != null && msg.message.content.trim().length > 0 ) {
                let id = null;

                if (this.channelData.data) {
                    id = this.channelData.data.chatChannelId;
                } else {
                    id = this.channelData.chatChannelId;
                }

                ChatService.sendMessage(id, 
                    {
                        'token': this.$store.state.chatToken,
                        'requesterId': this.$store.state.users.user.userId, 
                        'text': msg.message.content, 
                        'mentions': msg.mentions
                    }).then(() => {
                        if( this.isShowingGroupChatChannel()){
                            this.$refs.messageEditor.resetEditor();
                        }
                        else {
                            this.$refs.dmMessageEditor.resetEditor();
                        }
                    });
            }

        },

        onJoinChannel() {
            this.loading = true;

            let chatChannelId = null;
            let channelName = null;

            if (this.channelData.data) {
                chatChannelId = this.channelData.data.chatChannelId;
                channelName = this.channelData.data.channelName;
            } else {
                chatChannelId = this.channelData.chatChannelId;
                channelName = this.channelData.name;
            }
            ChatService.joinChannel(chatChannelId).then((resp) => {
                this.loading = false;

                if (resp.data.status === 'success') {
                    this.$toast.add({severity:'success', summary: `Successfully joined "${channelName}"!`, life: 3000, group: 'tc'});
                    
                    this.$store.commit('MARK_CHANNELS_STALE');
                    this.isMember = true;
                   
                    
                } else {
                    if( resp.data.message == 'Not Authorized') {
                        this.$toast.add({severity:'error', summary: `We were unable to add you to the channel "${channelName}".`, detail:'Remember, you must join the group before attempting to join any group channels.', life: 5000, group: 'center'});
                    }
                    else {
                        this.$toast.add({severity:'error', summary: `There was an issue trying to add you to the channel "${channelName}"`, detail: 'Please try again, if the problem persists please contact support@invrs.com', life: 5000, group: 'center'});
                    }
                }
            })
            

        },

        // initDescription() {
        //     if (!this.descriptionAvailable) return
        //     this.descriptionEl = document.querySelector('.channel-description');
        //     this.getDescriptionHeight();
        //     this.ro = new ResizeObserver(this.getDescriptionHeight).observe(this.descriptionEl);
        // },

        // getDescriptionHeight() {
        //     this.descriptionHeight = this.descriptionEl.clientHeight
        // },

        // toggleDescription() {
        //     this.descriptionVisible = !this.descriptionVisible;
        // }

        
    }
}
</script>

<style scoped>
.channel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.paused-channel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0.3;
    pointer-events: none;
}

.channel-description {
    position: relative;
}

.rotated {
    transform: rotate(180deg);
}

.description-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 8px;
    cursor: pointer;
    top: 0px;
}

.pi-angle-up {
    transition: all 0.1s ease;
}

.channel-description p {
    line-height: 1.2;
    padding: 0 16px 16px;
    position: absolute;
    display: block;
    width: 100%;
    z-index: 10;
    background-color: #fff;
}

.channel-description strong, .channel-description span {
    color: #32364e;
}

.footer-container {
    position: absolute;
    width: 100%;
    padding: 16px 16px 0px;
    bottom: 0px;
    z-index: 1;
    background-color: #fff;
}

::v-deep(.security-mention) {
    color: #33CC99;
    font-weight: bold;
}
::v-deep(.tag-mention) {
    color: #33CC99;
    font-weight: bold;
}

::v-deep(.analyst-mention) {
    color: #693BF5;
    font-weight: bold;
}

.message-list-container {
    max-height: calc(100% - 82px) !important;
}

</style>
