<template v-if="!initGroupLoading"> 
    <div v-if="selectedGroup">
        <ProfileCard class='group-profile' 
            :headerText="selectedGroup.name" 
            :subText="(selectedGroup.isMember? '': groupSubInfo(selectedGroup.memberCount, selectedGroup.groupTypeId, selectedGroup.isPrivate))" 
            :bioText="(isMobile()&& selectedGroup.isMember? '': selectedGroup.bio)"
            :avatar="selectedGroup.avatar ? selectedGroup.avatar : generateGroupAvatar(selectedGroup.name)"
            :userLink="selectedGroup.links ? selectedGroup.links[0]?.url : null"
            :notOwnProfile="!isGroupOwner()" :showBio="!isLastSelectedGroupViewChannel()">
            <template #actions>
                <template v-if='!isMobile()'>
                     <div v-if="(isGroupOwner() || isGroupAdmin())" class="send-email-button-container" >
                        <Button   :class="{'action-button-blue blue-button send-email-button': true, 'selected': landingPageSelected}" 
                            v-tooltip.bottom="'Manage group landing page.  Non-members see this this page when visiting your group profile.'"  @click="toggleLandingPageSelected()">

                        <img src="/assets/images/landing.png" />

                        </Button>
                    </div>
                      <div v-if="(isGroupOwner() || isGroupAdmin())" class="send-email-button-container" >
                        <Button   :class="{'action-button-blue blue-button send-email-button': true, 'selected': competitionsSelected}"
                            v-tooltip.bottom="'Competitions:  Manage the competitions hosted by this group. '"  @click="toggleCompetitionsSelected()">

                        <img src="/assets/images/contest.png" />

                        </Button>
                    </div>
                    <Button v-if="(isGroupOwner() && selectedGroup?.isPrivate && isGroupMonetizationFeatureEnabled())" class="action-button-blue blue-button" icon='pi pi-dollar' @click='openSubscriptions()'
                    v-tooltip.bottom="'Subscriptions:  Monetize your group by creating membership subscriptions. '"/>
                    <div class="send-email-button-container" v-if="canSendGroupEmail()">
                      
                        <Button  class="action-button-blue blue-button send-email-button" @click="openSendEmailModal" v-tooltip.bottom="'Send Email'" >
                            <img src="/assets/images/transfer_funds.png" />
                        </Button>
                    </div>
                    <Button v-if="selectedGroup.isMember" class="action-button-blue blue-button members-list-button" @click='openGroupMembersModal' v-tooltip.bottom="'Members'">
                        <img src="/assets/images/group_mobile.png" />
                    </Button>
                    <Button v-if="(isGroupOwner() || isGroupAdmin()) && selectedGroup.isPrivate" class="action-button-blue blue-button" 
                        icon='pi pi-inbox' @click='openGroupRequestsModal' v-tooltip.bottom="'Pending Requests'" 
                        v-badge.info="pendingRequestCount" :disabled="pendingRequestCount == 0"/>
                    <Button v-if="(isGroupOwner() || isGroupAdmin())" class="action-button-blue blue-button settings-button" @click='openGroupSettingsModal' v-tooltip.bottom="'Settings'">
                        <img src="/assets/images/settings.png" />
                    </Button>
                    
                    <template v-if=' !isInvrsGroup()'>
                        <Button v-if='selectedGroup.isMember && canInvite()' class="action-button-blue blue-button" icon="pi pi-user-plus" v-tooltip.bottom="'Invite'" @click='openInviteFriendsModal'/>
                        <Button v-else-if='!isTeamGroup() && !selectedGroup.isMember && hasPendingRequest' class='action-button-blue green-button__primary' :label="'Pending'" :disabled='true'  />
                        <Button v-else-if='!isTeamGroup() && !selectedGroup.isMember && !hasPendingRequest && !isProxiedUser' class='action-button-blue green-button__primary' :label="'Join'" :disabled='!selectedGroup.acceptingMembers'  @click="join"/>
                    </template>
                    <Button  v-if='(hasAdminModRights() || isProxiedUser)' :class="{'send-dm-button blue-button': true}" :icon="switchToAccountIcon" v-tooltip.bottom="switchToAccountTooltip" @click='toggleGroupOwnerAccount()' />
                
                </template>
            
                <MoreOptionsMenu @click="toggleMenu" v-if="groupMenuItems.length > 0"/>
                <Menu ref="menu" :model="groupMenuItems" :popup="true" />
                
            </template>
            <template #content v-if="isMobile()">

                <div class='action-buttons'>
                    <div class="open-side-menu-modal-button-container">
                        <Button class="menu-floating-button" icon="pi pi-ellipsis-v" @click='openGroupProfileSideMenuModal()' />
                        <Badge :value="getGroupNotificationCount()" class='open-side-menu-modal-button-badge ' v-if="getGroupNotificationCount()"></Badge>
                    </div>

                    <div class="selected-channel-label" v-if="selectedChannel && selectedChannel.data">
                        <i class="pi pi-comments"></i>
                        {{selectedChannel.data.channelName}}
                    </div>
                    <div class="selected-feed-label" v-else-if="selectedFeed">
                        
                        <span>{{selectedFeed?.courseName ? selectedFeed?.courseName : ""}}</span>
                        <span>{{selectedFeed.name}}</span>
                    </div>
                    <div class="selected-portfolio-label" v-else-if="selectedPortfolio">
                        <i class="pi pi-briefcase portfolio-item-icon"></i>
                        {{selectedPortfolio.name ? selectedPortfolio.name : selectedPortfolio.accountName}}
                    </div>

                    <Dropdown style="border: 2px solid #33CC99;" v-if="(selectedFeed && selectedFeed?.courseId) && selectedGroup.isMember" :options="postsStatsOptions" v-model="selectedPostsStatsOption" />

                  
                    
                    <template v-if='!isInvrsGroup()'>
                        
                        <Button v-if='!isTeamGroup() && !selectedGroup.isMember' class='action-button-blue green-button__primary' :label="hasPendingRequest ? 'Pending' : 'Join'" :disabled='hasPendingRequest'  @click="join"/>
                    </template>
                </div>
                
            </template>
            <template #content v-else>
                <template  v-if='!selectedGroup.acceptingMembers ' >
                        <div style="text-align:center;">
                            
                            <span><strong>Membership is currently closed.</strong></span>
                            <span v-if="(isGroupOwner() || isGroupAdmin())" ><br>To allow new members to join update your group settings.</span>

                        </div>
                </template>
               
                <div v-if="showGroupPlan()" style="display:flex;margin-left:auto;">
                    
                    <span style="display:flex;margin-left:auto;align-items:baseline;"><strong>Group Plan:&nbsp;</strong>
                    <Button class='action-button-blue green-button__primary' :label="getGroupPlan()"  @click="manageGroupPlan()" v-tooltip.bottom="'Click to manage group plan.'"/>
                    </span>
                    
                    

                </div>
                
            </template>
        </ProfileCard>
      
       <template v-if="selectedGroup.groupLandingPage && !selectedGroup.isMember && !selectedGroup.isBeingAudited">
            <div class="card" > 
                <ScrollPanel class="landing-container">
                <div class="landing-preview" v-html="selectedGroup.groupLandingPage.content" ></div>
                <div v-if="selectedGroup.groupLandingPage.includeCourses"  style="margin-top:15px;">
                
                    <GroupLandingPageCourseList @join-course="onJoinCourse" :courses="selectedGroup.openCourses" style="margin-bottom:10rem;display:flex;flex-direction:column;"/>
                </div>
                </ScrollPanel>
            </div>
           
        </template>
           
        <!-- private group not member -->
        <template  v-else-if='selectedGroup.isPrivate && !selectedGroup.isMember' >
           
            <div class='private-message-container'>
                <span class="lock-icon material-icons-outlined">lock</span>
                <template v-if="selectedGroup.educational">
                    <span  class='message-text'> This is a private educational group. </span>
                    <span v-if="selectedGroup.acceptingMembers" class='message-text'>  
                        <br>Click Join to register for the course offerings. 
                    </span>

                
                </template>
                <template v-else>
                    <span class='message-text'> This group is private.</span>
                    <span v-if="selectedGroup.acceptingMembers" class='message-text'> To see this group's feed and members, request to join! </span>
                </template>
                
            </div>
            
        </template>
        
        
        <!-- public group or user is member -->
        
        <template v-else-if='isPrivateGroupMemberOrPublicGroup && !competitionsSelected && !landingPageSelected'>
            
            <!--============================= DESKTOP ==================================================-->
            <template v-if='!isMobile()'>
               
                <div :class="(readOnly ? 'p-grid conduits-read-only': 'p-grid')" >
                    <div class='p-md-3'>
                        <GroupProfileSideMenu class="card column-container" :selectedGroup="selectedGroup" ref="groupSideMenu" @group-sidemenu-change="trackSideMenuChange($event)"/>
                    </div>

                    
                    <!--=============== Discuss ================-->
                    <template v-if="activeSideGroupMenu == 'Discuss'">
                        <div class="p-md-9">
                            <template v-if="selectedChannel && isChannelConnected">
                                <div  class="card column-container">
                                    <Channel ref="channelComp" :channelData="selectedChannel" rootElementId="body"/>
                                </div>
                            </template>
                            <template v-else-if="(selectedFeed && !selectedFeed?.courseId )">
                                <FeedActivityList  class="column-container feed-activity-list" context="group.discuss"
                                    :loader="loadGroupPosts" ref="activityList" 
                                    :selectedGroupFeed="feedObject()" :loadOnMount="true" :allowComments="feedObject().allowMemberComments || isGroupOwner() || isGroupAdmin()">
                                </FeedActivityList>
                            </template>

                            <template v-if="shouldShowDiscussionFeedActions()">
                                <a class="post-floating-button " @click="openStatusEditorDialog" v-tooltip.left="'Make a Post'">
                                    <img src="/assets/images/post.png" />
                                </a>
                                <a class="question-floating-button " @click="openQuestionEditorDialog" v-tooltip.left="'Ask a Question or Create a Poll'">
                                    
                                    <img src="/assets/images/ask.png" />
                                </a>
                                <a v-if="canCreateMotion()" class="motion-floating-button " @click="openCreateMotionModal" v-tooltip.left="'Create a Motion'" >
                                    
                                    <img src="/assets/images/motion_icon2.png" />
                                </a>
                            </template>
                        </div>

                    </template>
                        
                    <!--=============== Courses ================-->
                    <template v-else-if="activeSideGroupMenu == 'Courses'" >
                        <div class="p-md-9">
                            <!--=============== Course Level Details ================-->
                            <template v-if="selectedCourse && (!selectedFeed || !selectedFeed.courseId)">
                                <ScrollPanel  class="column-container">
                                    <div v-if="selectedCourse  && (!selectedFeed || !selectedFeed.courseId)" class="card"  style="white-space: pre-wrap;padding-bottom:0;">
                                        <div style="display:flex;align-items:center;">
                                        <UserAvatar :imageSrc="(selectedCourse.iconUrl ? selectedCourse.iconUrl: '/assets/images/eduAccount.png')" size='lg' shape='roundedSquare'/>
                                        <div style="font-size: 18px;font-weight:bold;margin-left:5px;">{{selectedCourse.name}}</div>
                                        <Button v-if="isGroupOwnerAdminOrMod()" class="green-button__secondary registration-link-button" style="margin-left:auto;" label="Copy Registration Link" @click="getCourseRegistrationParam" />
                        
                                        <Button v-if='showJoinCourseButton' class='action-button-blue green-button__primary' style="margin-left:auto;width:5rem;height:2rem;" :label="'Join'" :disabled='!selectedCourse.open'  @click="joinCourse"/>
                                        </div>
                                        <div>
                                            {{selectedCourse.description}}
                                        
                                        <TabMenu v-model:activeIndex="activeCourseTab" :model="courseTabs" style="position:unset;margin-top:10px;" ></TabMenu>
                                        </div>
                                    </div>
                                    <div class="card" v-if="selectedCourse && (!selectedFeed || !selectedFeed.courseId)">
                                        <div v-if="selectedGroup?.isMember && !cohortActive "><strong>Your class cohort starts {{getCohortStartDate()}}</strong></div>
                                        <template v-if="activeCourseTab == 0 && cohortActive">
                                            <CourseProgress v-if="selectedCourse.hasCohorts" :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" />
                                            <ContestDetails v-else :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" />
                                        </template>
                                        
                                    
                                        <Resources :selectedGroup="selectedGroup" :courseId="selectedCourse?.courseId" v-if="activeCourseTab == 1 && cohortActive"/>
                                    </div>
                                </ScrollPanel>
                            </template>
                        
                            <!--=============== Module Level Details ================-->
                            <template v-if="selectedCourse && selectedFeed ">
                                <div class="card"  style="white-space: pre-wrap;padding-bottom:0;" >
                                    
                                    <span style="font-size: 18px;font-weight:bold;">{{selectedFeed.name}}</span><br><br>
                                        {{selectedFeed.description}}
                                        
                                    <TabMenu v-model:activeIndex="activeModuleTab" :model="moduleTabs" style="position:unset;margin-top:10px;" >
                                        
                                    </TabMenu>
                                </div>
                                <div class="card" v-if="selectedGroup?.isMember && !cohortActive"><strong>Your class cohort starts {{getCohortStartDate()}}</strong></div>
                            

                                <template v-if="showCourseLessons">
                                    <FeedActivityList 
                                        class="column-container feed-activity-list" 
                                        :loader="loadGroupPosts" ref="activityList" context="group.course"
                                        :selectedGroupFeed="feedObject()" :loadOnMount="true" :allowComments="feedObject().allowMemberComments || isGroupOwner() || isGroupAdmin()">
                                    </FeedActivityList>
                                </template>
                                <template v-if="activeModuleTab == 2 && cohortActive">
                                    <div class="card" style="margin-bottom:50px;">
                                    <Resources :selectedGroup="selectedGroup" :courseId="selectedCourse?.courseId" :feedId="selectedFeed?.groupFeedId" />
                                    </div>
                                </template>
                                <template  v-else-if="selectedFeed?.courseId &&  activeModuleTab == 1 && cohortActive" >
                                    <div class="card">
                                        <CourseProgress v-if="selectedCourse.hasCohorts" :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" :selectedFeed="selectedFeed" />
                                        <ContestDetails v-else :selectedGroup="selectedGroup" :selectedFeed="selectedFeed" />
                                    </div>
                                </template>


                                <template v-if="shouldShowCourseFeedActions()">
                                    <a class="lesson-floating-button " @click="openStatusEditorDialog" v-tooltip.left="'Add a Lesson or Task'">
                                        <img src="/assets/images/lesson.png" />
                                    </a>
                                    <a class="question-floating-button " @click="openQuestionEditorDialog" v-tooltip.left="'Add a Question'">
                                        
                                        <img src="/assets/images/ask.png" />
                                    </a>
                                    <!-- <a class="motion-floating-button " @click="openCreateMotionModal" v-tooltip.left="'Create a Motion'">
                                        
                                        <img src="/assets/images/motion_icon2.png" />
                                    </a> -->
                                </template>
                            </template>
                        </div>
                    </template>

                    <!--=============== Portfolios ================-->
                    <template v-if="activeSideGroupMenu == 'Portfolios'">
                        <template v-if="selectedPortfolio">
                            <div  class="p-md-9" >
                                <GroupPortfolio/>
                            </div>
                        </template>
                        <template  v-else-if="activeSideGroupMenu == 'Portfolios' && selectedCashAccount" >
                            <div class="p-md-9 cash-accounts-container" >
                                <CashAccounts :selectedGroup="selectedGroup"/>
                            </div>
                        </template>
                    </template>
                    
                </div>
            </template>

            <!--======================= MOBILE ==========================-->
            <template  v-else>
                <div class="mobile-container" ref="mobileContainer">

                     <!--=============== Discuss ================-->
                    <template v-if="!activeSideGroupMenu || activeSideGroupMenu == 'Discuss'">
                        <template v-if='selectedChannel && isChannelConnected'>
                            <div  class="channel-comp card">
                                <Channel ref="channelComp" :channelData="selectedChannel" rootElementId="body"/>
                            </div>  
                        </template>

                        <template v-else-if="(selectedFeed && !selectedFeed?.courseId )">
                            <FeedActivityList  :loader="loadGroupPosts"   :selectedGroupFeed="feedObject()"  ref="activityList"  :loadOnMount="true" 
                                :allowComments="feedObject().allowMemberComments || isGroupOwner()" context="group.discuss"/>
                        </template>
                        <template v-if="selectedFeed ">
                            <a class="post-floating-button p-d-md-none"  v-touch:tap="openStatusEditorDialog">
                                <img src="/assets/images/post.png" />
                            </a>
                            <a class="question-floating-button p-d-md-none"  v-touch:tap="openQuestionEditorDialog">
                                
                                <img src="/assets/images/ask.png" />
                            </a>
                            <a v-if="canCreateMotion()" class="motion-floating-button p-d-md-none"  v-touch:tap="openCreateMotionModal">
                                
                                <img src="/assets/images/motion_icon2.png" />
                            </a>
                        </template>

                    </template>

                    <!--=============== courses ================-->
                    <template v-else-if="activeSideGroupMenu == 'Courses'" >
                       

                        <template v-if="!selectedCourse && !selectedFeed && !selectedChannel && !selectedPortfolio" >
                            <GroupCourseList  :sideMenuWidth="100" ref="myCourseList" :selectedGroup="selectedGroup" :showModules="false"  @course-selected="selectCourse"/> 
                        </template> 
                        <!--=============== Course Level Details ================-->
                        <template v-if="selectedCourse && !selectedFeed?.courseId">
                            <ScrollPanel  class="column-container">
                                <div class="card" style="white-space: pre-wrap;margin-bottom:50px;padding-top:0;padding-bottom:0;">
                                    <p style="font-size: 18px;font-weight:bold;margin-top:0;padding-bottom:0;">{{selectedCourse.name}}</p>
                                    
                                    <TabMenu v-model:activeIndex="activeCourseTab" :model="courseTabs" style="position:unset;margin-top:5px;" ></TabMenu>
                                    <template v-if="activeCourseTab == 0 && cohortActive">
                                        <CourseProgress v-if="selectedCourse.hasCohorts" :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" />
                                        <ContestDetails v-else :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" />
                                    </template>
                                    <Resources :selectedGroup="selectedGroup" :courseId="selectedCourse?.courseId" v-if="activeCourseTab == 1 && cohortActive"/>
                                </div>
                            </ScrollPanel>
                        </template>
                        <!--=============== Module Level Details ================-->
                        <template v-if="selectedCourse && selectedFeed?.courseId">

                            <template v-if="(!selectedPostsStatsOption || selectedPostsStatsOption === postsStatsOptionLabels.posts) && cohortActive">
                                <FeedActivityList  :loader="loadGroupPosts" :selectedGroupSubFeed="selectedFeed" ref="activityList"  :loadOnMount="true" 
                                    :allowComments="feedObject().allowMemberComments || isGroupOwner()" context="group.course"/>
                            </template>

                            <template v-else-if="selectedPostsStatsOption === postsStatsOptionLabels.stats && cohortActive">
                                <div class="card column-container">
                                    <CourseProgress v-if="selectedCourse.hasCohorts" :selectedGroup="selectedGroup" :selectedCourse="selectedCourse" :selectedFeed="selectedFeed"/>
                                    <ContestDetails v-else :selectedGroup="selectedGroup" :selectedFeed="selectedFeed" />
                                </div>
                            </template>
                            <template v-if="selectedPostsStatsOption === postsStatsOptionLabels.resources && cohortActive">
                                <div class="card" style="margin-bottom:50px;">
                                <Resources :selectedGroup="selectedGroup" :courseId="selectedFeed?.courseId " :feedId="selectedFeed?.groupFeedId" />
                                </div>
                            </template>

                            <!-- <template v-if="cohortActive && selectedFeed.allowMemberPosts">
                                <a class="post-floating-button p-d-md-none" @click="openStatusEditorDialog" v-touch:tap="openStatusEditorDialog">
                                    <img src="/assets/images/post.png" />
                                </a>
                                <a class="question-floating-button p-d-md-none" @click="openQuestionEditorDialog" v-touch:tap="openQuestionEditorDialog">
                                    
                                    <img src="/assets/images/ask.png" />
                                </a>
                                <a class="motion-floating-button p-d-md-none" @click="openCreateMotionModal" v-touch:tap="openCreateMotionModal">
                                    
                                    <img src="/assets/images/motion_icon2.png" />
                                </a>
                            </template> -->
                        </template>

                    </template>

                    <!--=============== Portfolios ================-->
                    <template v-if="activeSideGroupMenu == 'Portfolios'">
                    
                    
                        <template v-if="selectedPortfolio">
                            <ScrollPanel  class="column-container">
                                <GroupPortfolio />
                            </ScrollPanel>
                        </template>

                        <template v-else-if="selectedCashAccount" >
                            <ScrollPanel  class="column-container">
                                <CashAccounts :selectedGroup="selectedGroup"/>
                            </ScrollPanel>
                        </template>
                    </template>
                </div>
            </template>
        </template>
        
        <ConfirmModal :headerText="headerText" :cancelButtonLabel="cancelConfirmLabel" :confirmButtonLabel="confirmConfirmLabel" ref="confirmModal" @confirmed="onConfirmed">
            <template #content>
                <div class="confirm-container"> {{confirmText}}</div>

                
            </template>
        </ConfirmModal>
        <InviteFriendsModal ref="inviteFriendsModal"/>
        <EditProfileModal ref="editProfileModal" :data="{
            'type': USER_TYPES['GROUP'], 
            'fullName': selectedGroup.name, 
            'bio': selectedGroup.bio, 
            'avatar': selectedGroup.avatar,
            'link': selectedGroup.links ? selectedGroup.links[0]?.url : null
            }"/>
        <StatusEditorDialog ref="statusEditorModal" />
        <GroupMembersModal ref="groupMembersModal" :selectedGroup="selectedGroup"/>
        <GroupSettingsModal ref="groupSettingsModal" :selectedGroup="selectedGroup"/>
        <GroupRequestsModal ref="groupRequestsModal" :selectedGroup="selectedGroup"/>
        <GroupProfileSideMenuModal ref="groupProfileSideMenuModal" v-if="isMobile()" :selectedGroup="selectedGroup" @on-modal-closed="onCloseGroupProfileSideMenuModal" @group-sidemenu-change="trackSideMenuChange($event)"/>
        <CreateChannel ref='createChannelModal' v-if="isGroupOwner() || isGroupAdmin()" @create-channel-successful="onNewChannelCreated()"/>
        <CreateEditFeedModal ref='createEditFeedModal' v-if="isGroupOwner() || isGroupAdmin()"/>
        <CreateEditCourseModal ref='createEditCourseModal' v-if="isGroupOwner() || isGroupAdmin()"/>
        <JoinGroupModal ref='joinGroupModal' v-if="!isGroupOwner()"/>
        <ChoosePortfolioTypeModal ref='choosePortfolioTypeModal' v-if="isGroupOwner() || isGroupAdmin()" />
        <CreateEditGroupPortfolioModal ref='createEditGroupPortfolioModal' v-if="isGroupOwner() || isGroupAdmin()" />
        <AddGroupPortfolioAccountModal ref='addGroupPortfolioAccountModal'/>
        <AddCollaborativePortfolioAccountModal ref='addCollaborativePortfolioAccountModal' v-if="isGroupOwner() || isGroupAdmin()"/>
        <SendEmailModal ref="sendEmailModal" :selectedGroup="selectedGroup"/>
        <SelectCourseAndCohortModal ref="selectCohortModal" :selectedGroup="selectedGroup" @education-group-joined="onEducationGroupJoined"/>
        <ManagePlanModal ref="managePlanModal" />
        <GroupCompetitionsCard ref="groupCompetitionsCard" :selectedGroup="selectedGroup"  v-if="(isGroupOwner() || isGroupAdmin()) && competitionsSelected"/>
        <GroupLandingPageEditor ref="groupLandingPageEditor" :selectedGroup="selectedGroup"  v-if="(isGroupOwner() || isGroupAdmin()) && landingPageSelected"/>
        
    </div>

    <CoursePaymentConfirmationModal ref="paymentConfirmationModal" @course-payment-confirmed="onCoursePaymentConfirmed"/>
    <SubscriptionPaymentConfirmationModal ref="subscriptionConfirmationModal" />
    <MembershipPaymentConfirmationModal ref="membershipConfirmationModal"  @membership-payment-confirmed="onMembershipPaymentConfirmed"/>
    <GroupSubscriptionsModal ref="groupSubscriptionsModal"/>
    <SubscribeToGroupModal ref="subscribeToGroupModal"/>
    
</template>

<script>
import { defineAsyncComponent } from 'vue'
import MoreOptionsMenu from '../../menu/MoreOptionsMenu.vue';
import ProfileCard from '../../profile/ProfileCard.vue';
import GroupProfileSideMenu from '../GroupProfileSideMenu.vue';
import GroupProfileSideMenuModal from '../modal/GroupProfileSideMenuModal.vue';
//import InviteFriendsModal from '../modal/InviteFriendsModal.vue';
import ConfirmModal from '../../modal/ConfirmModal.vue';
// import WhoToFollowList from '../../feed/WhoToFollow/WhoToFollowList.vue'
//import GroupMembersModal from '../modal/GroupMembersModal.vue';
//import GroupSettingsModal from '../modal/GroupSettingsModal.vue';
//import GroupRequestsModal from '../modal/GroupRequestsModal.vue';
//import CreateEditFeedModal from '../modal/CreateEditFeedModal.vue';
//import CreateEditCourseModal from '../modal/CreateEditCourseModal.vue';
//import SendEmailModal from '../modal/SendEmailModal.vue';
import JoinGroupModal from '../modal/JoinGroupModal.vue';


//import GroupCompetitionsCard from '../GroupCompetitionsCard.vue';
import FeedActivityList from '../../feed/FeedActivityList.vue';
// import StatusEditor from '../../feed/StatusEditor.vue';
//import EditProfileModal from '../../modal/EditProfileModal.vue';
import StatusEditorDialog from '../../feed/StatusEditorDialog.vue';
//import CreateChannel from '../../chat/CreateChannel.vue';
import Channel from '../../chat/Channel.vue';

//import CreateEditGroupPortfolioModal from '../modal/CreateEditGroupPortfolioModal.vue';
//import AddCollaborativePortfolioAccountModal from '../modal/AddCollaborativePortfolioAccountModal.vue';
//import AddGroupPortfolioAccountModal from '../modal/AddGroupPortfolioAccountModal.vue';
//import GroupPortfolio from '../GroupPortfolio.vue';
//import ChoosePortfolioTypeModal from '../modal/ChoosePortfolioTypeModal.vue';
//import CashAccounts from '../../portfolio/CashAccounts.vue';

import ScrollPanel from 'primevue/scrollpanel';
import Badge from 'primevue/badge';

//import ContestDetails from '../../contests/components/ContestDetails.vue';
//import CourseProgress from '../../contests/components/CourseProgress.vue';

import FeedService from '../../../service/FeedService';
import GroupService from '../../../service/GroupService';
import ChatService from '../../../service/ChatService';
import PortfolioService from '../../../service/PortfolioService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import UserUtils from '../../../utilities/UserUtils';
import {groupSubInfo} from './../';
import {WHO_TO_FOLLOW_TYPES, USER_TYPES, COLLABORATIVE_PORTFOLIO_TYPE} from '../../../common/constants';
import {APP_URL} from '../../../common/config';
import { generateGroupAvatar, hasPremiumGroupSubscription, isINVRSGroup, getGroupSubscription} from '../';
import EventBus from '../../../event-bus';



import {mapState} from 'vuex';

import GroupCourseList from '../GroupCourseList.vue';
//import SelectCourseAndCohortModal from '../modal/SelectCourseAndCohortModal.vue';
import CoursePaymentConfirmationModal from '../modal/CoursePaymentConfirmationModal.vue';
import Dropdown from 'primevue/dropdown';
import TabMenu from 'primevue/tabmenu';

//import Resources from '../Resources.vue';
import isAfter from 'date-fns/isAfter'
import parse from 'date-fns/parse'

//import ManagePlanModal from '../modal/ManagePlanModal.vue';
import SubscriptionPaymentConfirmationModal from '../modal/SubscriptionPaymentConfirmationModal.vue';
import MembershipPaymentConfirmationModal from '../modal/MembershipPaymentConfirmationModal.vue';

import GroupSubscriptionsModal from '../modal/GroupSubscriptionsModal.vue';
import SubscribeToGroupModal from '../modal/SubscribeToGroupModal.vue';
//import GroupLandingPageEditor from '../GroupLandingPageEditor.vue';
//import GroupLandingPageCourseList from '../GroupLandingPageCourseList.vue';
import UserAvatar from '../../profile/UserAvatar.vue';
// import LoadingSpinner from '../../common/LoadingSpinner';
import UserService from '../../../service/UserService';
import AuthService from '../../../service/AuthService';

// All group owners are group members

const postsStatsOptionLabels = {
    posts: "Posts",
    stats: "Progress",
    resources: "Resources",
};

const postsStatsOptions = [
    postsStatsOptionLabels.posts,
    postsStatsOptionLabels.stats,
    postsStatsOptionLabels.resources,
];

const communityPortfolio = {
  "groupPortfolioId": null,
  "investmentAccountId": -999,
  "accountTypeId": 53,
  "name": "Community Portfolio",
  "officialName": "Community Portfolio",
  "partialAccountNum": "INVRS",
  "lastSyncStatus": "Import Complete",
  "effectiveDate": "Mar 15, 2023, 3:37:21 PM",
  "hidden": false,
  "sharingLevel": 1,
  "totalBorrowed": 0,
  "currentShortPosition": 0,
  "currentLongPosition": 0,
  "currentBuyingPower": 0,
  "equityPositions": [],
  "fundPositions": [],
  "cashPositions": [],
  "fixedIncomePositions": [],
  "derivativePositions": [],
  "otherAssetPositions": [],
  "accountLoans": [],
  "cryptoPositions": [],
  "realEstatePositions": [],
  "fullName": "Community Portfolio (INVRS)"
};

export default {
    name: 'GroupProfile',
    components: {
    ProfileCard,
    MoreOptionsMenu,
    InviteFriendsModal: defineAsyncComponent(() =>
        import('../modal/InviteFriendsModal.vue')
        ),
    FeedActivityList,
    ConfirmModal,
    // StatusEditor,
    EditProfileModal: defineAsyncComponent(() =>
        import('../../modal/EditProfileModal.vue')
        ),
    StatusEditorDialog,
    GroupMembersModal: defineAsyncComponent(() =>
        import('../modal/GroupMembersModal.vue')
        ),
    GroupSettingsModal: defineAsyncComponent(() =>
        import('../modal/GroupSettingsModal.vue')
        ),
    GroupRequestsModal: defineAsyncComponent(() =>
        import('../modal/GroupRequestsModal.vue')
        ),
    GroupProfileSideMenu,
    Channel,
    GroupProfileSideMenuModal,
    CreateChannel: defineAsyncComponent(() =>
        import('../../chat/CreateChannel.vue')
        ),
    
    ScrollPanel,
    
    
    CreateEditFeedModal: defineAsyncComponent(() =>
        import('../modal/CreateEditFeedModal.vue')
        ),
    CreateEditCourseModal: defineAsyncComponent(() =>
        import('../modal/CreateEditCourseModal.vue')
        ),
    JoinGroupModal,
    
    Badge,
    SendEmailModal: defineAsyncComponent(() =>
        import('../modal/SendEmailModal.vue')
        ),
    
    ContestDetails: defineAsyncComponent(() =>
        import('../../contests/components/ContestDetails.vue')
        ),
    Dropdown,
    GroupCourseList,
    SelectCourseAndCohortModal: defineAsyncComponent(() =>
        import('../modal/SelectCourseAndCohortModal.vue')
        ),
    CoursePaymentConfirmationModal,
    Resources: defineAsyncComponent(() =>
        import('../Resources.vue')
        ),
    TabMenu,
    CourseProgress: defineAsyncComponent(() =>
        import('../../contests/components/CourseProgress.vue')
        ),
    SubscriptionPaymentConfirmationModal,
    ManagePlanModal: defineAsyncComponent(() =>
        import('../modal/ManagePlanModal.vue')
        ),
    GroupSubscriptionsModal,
    SubscribeToGroupModal,
    MembershipPaymentConfirmationModal,
    GroupCompetitionsCard: defineAsyncComponent(() =>
        import('../GroupCompetitionsCard.vue')
        ),
    GroupLandingPageEditor: defineAsyncComponent(() =>
        import('../GroupLandingPageEditor.vue')
        ),
    GroupLandingPageCourseList: defineAsyncComponent(() =>
        import('../GroupLandingPageCourseList.vue')
        ),
    UserAvatar,


    CreateEditGroupPortfolioModal: defineAsyncComponent(() =>
      import('../modal/CreateEditGroupPortfolioModal.vue')
    ),
    AddCollaborativePortfolioAccountModal: defineAsyncComponent(() =>
      import('../modal/AddCollaborativePortfolioAccountModal.vue')
    ),
    CashAccounts: defineAsyncComponent(() =>
      import('../../portfolio/CashAccounts.vue')
    ),
    GroupPortfolio: defineAsyncComponent(() =>
      import('../GroupPortfolio.vue')
    ),
    ChoosePortfolioTypeModal: defineAsyncComponent(() =>
      import('../modal/ChoosePortfolioTypeModal.vue')
    ),
    AddGroupPortfolioAccountModal: defineAsyncComponent(() =>
      import('../modal/AddGroupPortfolioAccountModal.vue')
    ),
},
    data() {
        return {
            //CONSTANTS
            WHO_TO_FOLLOW_TYPES,
            USER_TYPES,
            postsStatsOptions,
            postsStatsOptionLabels,

            selectedPostsStatsOption: postsStatsOptionLabels.posts,

            initGroupLoading:true,

            selectedMenuOptions: 0,
            headerText: '',
            confirmText: '',
            cancelConfirmLabel: 'Cancel',
            confirmConfirmLabel: 'OK',

            groupLink: null,
            userUtils: UserUtils,
            hasPendingRequest: false,
            pendingRequestCount: 0,

            selectedCashAccount: null,
            selectedPortfolio: null,
            fetchPortfoliosInProgress: false,

            selectedChannel: null,
            isChannelConnected: false,
            selectedFeed: null,
            readOnly: false,
            selectedTab: "Markets",
            sectorIndicesQuotes: [
                { symbol: 'SPY', qmSymbol: 'SPY', name: 'S&P 500' },
                { symbol: 'QQQ', qmSymbol: 'QQQ', name: 'Nasdaq 100' },
                { symbol: 'DIA', qmSymbol: 'DIA', name: 'Dow Jones' },
                { symbol: 'XIC', qmSymbol: 'XIC:CA', name: 'TSX Composite' },
            ],
            sectorPerformancesQuotes: [
                { symbol: 'IYW', qmSymbol: 'IYW', name: 'Technology' },
                { symbol: 'IYH', qmSymbol: 'IYH', name: 'Healthcare' },
                { symbol: 'KXI', qmSymbol: 'KXI', name: 'Consumer Staples' },
                { symbol: 'RXI', qmSymbol: 'RXI', name: 'Consumer Discretionary' },
                { symbol: 'IYG', qmSymbol: 'IYG', name: 'Financial Services' },
                { symbol: 'XLI', qmSymbol: 'XLI', name: 'Industrials' },
                { symbol: 'IDU', qmSymbol: 'IDU', name: 'Utilities' },
                { symbol: 'VNQ', qmSymbol: 'VNQ', name: 'Real Estate' },
                { symbol: 'VOX', qmSymbol: 'VOX', name: 'Communications' },
                { symbol: 'IYE', qmSymbol: 'IYE', name: 'Energy' },
                { symbol: 'IYM', qmSymbol: 'IYM', name: 'Materials' },
            ],
            factorPerformancesQuotes: [
                { symbol: 'IWF', qmSymbol: 'IWF', name: 'Growth' },
                { symbol: 'IWD', qmSymbol: 'IWD', name: 'Value' },
                { symbol: 'PDP', qmSymbol: 'PDP', name: 'Momentum' },
                { symbol: 'SPLV', qmSymbol: 'SPLV', name: 'Low Volatility' },
                { symbol: 'VYM', qmSymbol: 'VYM', name: 'High Dividend' },
                { symbol: 'IJR', qmSymbol: 'IJR', name: 'Small Cap' },
                { symbol: 'IJH', qmSymbol: 'IJH', name: 'Mid Cap' },
                { symbol: 'IVV', qmSymbol: 'IVV', name: 'Large Cap' },
            ],
            indicesParam: 'SPY,QQQ,DIA,XIC:CA',
            sectorPerformancesParam: 'IYW, IYH, KXI, RXI, IYG, XLI, IDU, VNQ, VOX, IYE, IYM',
            factorPerformancesParam: 'IWF, IWD, PDP, SPLV, VYM, IJR, IJH, IVV',
            marketsDiscoverMenuItems: [
                { label: 'Markets', action: this.viewMarketsFeed, selected: true },
                { label: 'Discover', action: this.viewDiscoverFeed, selected: false },
                { label: 'News', action: this.viewNewsFeed, selected: false },
            ],
            selectedCourse: null,
            courseFeedLoaders: [],
            activeCourseTab: 0,
            courseTabs : [
                {
                    label: 'Progress',
                    icon: 'pi pi-chart-bar',
                   
                },
                {
                    label: 'Resources',
                    icon: 'pi pi-folder',
                   
                },
            ],
            activeModuleTab: 0,
            moduleTabs : [
                {
                    label: 'Lessons',
                    icon: 'pi pi-list',
                   
                },
                {
                    label: 'Progress',
                    icon: 'pi pi-chart-bar',
                   
                },
                {
                    label: 'Resources',
                    icon: 'pi pi-folder',
                   
                },
            ],

            activeSideGroupMenu: null,
            competitionsSelected: false,
            landingPageSelected: false,
           
        }
    },

    created() {
        let iconMapForPerformanceQuotes = {
            indicesPerformance: {
                ['SPY']: 'SPY',
                ['QQQ']: 'NDQ',
                ['DIA']: 'DOW',
                ['XIC']: 'TSX',
            },
            sectorPerformance: {
                ['IYW']: 'devices',
                ['IYH']: 'health_and_safety',
                ['KXI']: 'shopping_basket',
                ['RXI']: 'shopping_cart',
                ['IYG']: 'account_balance',
                ['XLI']: 'engineering',
                ['IDU']: 'lightbulb',
                ['VNQ']: 'business',
                ['VOX']: 'phone_in_talk',
                ['IYE']: 'bolt',
                ['IYM']: 'park',
            },
            factorPerformance: {
                ['IWF']: 'GWTH',
                ['IWD']: 'VALU',
                ['PDP']: 'MTUM',
                ['SPLV']: 'LVOL',
                ['VYM']: 'HDIV',
                ['IJR']: 'SCAP',
                ['IJH']: 'MCAP',
                ['IVV']: 'LCAP',
            },
        };

        this.sectorIndicesQuotes = this.sectorIndicesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'text',
                    value: iconMapForPerformanceQuotes['indicesPerformance'][quote.symbol],
                },
            };
        });
        this.sectorPerformancesQuotes = this.sectorPerformancesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'symbol',
                    value: iconMapForPerformanceQuotes['sectorPerformance'][quote.symbol],
                },
            };
        });
        this.factorPerformancesQuotes = this.factorPerformancesQuotes.map((quote) => {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
                icon: {
                    type: 'text',
                    value: iconMapForPerformanceQuotes['factorPerformance'][quote.symbol],
                },
            };
        });
    },

    watch:{
        $route(newRoute, oldRoute) { //eslint-disable-line
            this.selectedCourse = null;
            this.selectedFeed = null;
            this.selectedPortfolio = null;
            this.selectedCashAccount = null;
            this.selectedChannel = null;
            if(newRoute.fullPath.startsWith('/groups/profile/') ) {
               
                    this.initGroup();
                
            } else if( this.chatMode == 'groups') {
                    //console.log('non group route closing chat client connection');
                    ChatService.disconnectUserFromChatClient("GroupProfile - watch( group route))");
            }
        },
        chatMode(newVal, oldVal) { //eslint-disable-line
            this.readOnly = newVal == 'dm';
        },
        showNotesOnly(newVal, oldVal) { //eslint-disable-line
            this.$refs.activityList?.refreshFeed("GroupProfile.watch.showNotesOnly");
        },
    },

    computed: {
        ...mapState(['selectedGroup', 'users', 'chatMode', 'feedNotificationCounts', 'groupChannelsUnreadCounts', 'showNotesOnly']),

        groupMenuItems() {
            return this.getGroupMenuItems();
        },

        isPrivateGroupMemberOrPublicGroup() {
            
            return (this.selectedGroup.isMember && this.selectedGroup.isPrivate) || !this.selectedGroup.isPrivate
        },

        showJoinCourseButton(){
            return this.selectedCourse.hasCohorts && !this.selectedCourse.isMember && !this.isGroupOwnerAdminOrMod()
        },

        canViewCourseLessons() {
            if( this.isGroupOwnerAdminOrMod()){
                return true;
            }
            else if( this.selectedGroup.isMember ){
                if( this.selectedCourse?.hasCohorts){
                   return this.selectedCourse.isMember && this.cohortActive
                }
                else if( this.selectedCourse?.open ){
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },

        cohortActive() {
            if( this.isGroupOwnerAdminOrMod() )  {
                return true;
            }
            else if( this.selectedCourse?.hasCohorts){
                if( this.selectedCourse?.userCohorts ) {// TBD should be checking against course as well when there are multiple course available in group
                    let now = new Date();
                    let cohortIsActive = (cohort) => cohort.startDate && isAfter(now, parse(cohort.startDate, 'MMM d, yyyy h:mm:ss a', now));
                    return this.selectedCourse?.userCohorts.some(cohortIsActive);
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        },
        showCourseLessons() {
            
            return this.selectedFeed?.courseId && this.activeModuleTab == 0 && this.cohortActive ;
        },

        switchToAccountIcon() {
            if( this.$store.state.hasProxy ) {
                return "pi pi-eye-slash";
            }
            else {
                return "pi pi-eye";
            }
        },

        switchToAccountTooltip() {
            if( this.$store.state.hasProxy ) {
                return "Switch from Group Owner Account";
            }
            else {
                return "Switch to Group Owner Account";
            }
        },

        isProxiedUser() {
            //console.log("isproxied user : "+ this.$store.state.hasProxy);
            return  this.$store.state.hasProxy;
        },

    },
    
    beforeCreate(){
        // console.log("beforeCreate", this.selectedGroup);
        this.$store.commit('SET_SELECTED_GROUP', undefined);
        this.initGroupLoading = true;
    },

    beforeMount(){
        // console.log("beforeMount", this.selectedGroup);
        this.$store.commit('SET_SELECTED_GROUP', undefined);
        this.initGroupLoading = true;
    },

    mounted() {
        this.$store.commit('SET_SELECTED_GROUP', undefined);
        if( this.justAfterCoursePaymentComplete() ){

            this.waitForPaymentConfirmation();
        }
        else if( this.justAfterSubscriptionPaymentComplete()){
            this.waitForSubscriptionConfirmation();
        }
        else if( this.justAfterMembershipPaymentComplete()){
            this.waitForMembershipConfirmation();
        }
        else {
            this.initComponent();
        }

        

    },

    unmounted(){
        this.initGroupLoading = true;
    },

    methods: {

        getCourseRegistrationParam() {
            GroupService.generateGroupCourseRegisterParam(this.selectedGroup.groupId, this.selectedCourse.courseId).then(resp => {
                if (resp.data.status === 'success') {
                    try {
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(`${APP_URL}/index.html#/signup?courseRegCode=${resp.data.param}`);
                        }
                        this.$toast.add({ severity: 'success', summary: 'The link  was copied to your clipboard.', life: 2000, group: 'center' });
                    } catch (err) {
                        console.debug('Oops, unable to copy'+err);
                    }
                } else {
                    this.$toast.add({ severity: 'error', detail: resp.data.message, summary: 'Unexpected Error', life: 2000, group: 'center' });
                }
            })
        },

        hasAdminModRights() {
                return UserUtils.userIsAdmin() || UserUtils.userIsFeedModerator() || UserUtils.userIsChatModerator();
        },

        toggleGroupOwnerAccount() {
             if( this.$store.state.hasProxy ) {
                UserService.switchBack(this.$store.state.users.user.sudoer.analyst.analystId).then(
                    (result) => {
                        AuthService.authorizationSuccess(result);
                        this.selectedGroup['isBeingAudited'] = false;
                        this.$router.push({path:'/groups/profile/'+this.selectedGroup.groupId})
                    },
                    (error) => {
                            console.log("error switching account: "+ error);
                            this.$toast.add({ severity: 'error', summary: 'Unexpected error switching back from account see console', detail: '', life: 3000, group: 'tc' });
                    }
                );
             }
             else {
                UserService.switchToGroupOwnerAccount(this.selectedGroup.groupId).then(
                    (result) => {
                        AuthService.authorizationSuccess(result);
                        this.selectedGroup['isBeingAudited'] = true;
                        this.$router.push({path:'/groups/profile/'+this.selectedGroup.groupId})
                    },
                    (error) => {
                            console.log("error switching account: "+ error);
                            this.$toast.add({ severity: 'error', summary: 'Unexpected error switching account see console', detail: '', life: 3000, group: 'tc' });
                    }
                );

                     
             }
        },

        toggleLandingPageSelected() {
            this.landingPageSelected = !this.landingPageSelected;
            if (!this.landingPageSelected){
                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId);
            } else {
                this.competitionsSelected = false;
                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId+'/landing');
            }
        },

        toggleCompetitionsSelected() {
            this.competitionsSelected = !this.competitionsSelected;
            
            if (!this.competitionsSelected){
                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId);
            } else {
                this.landingPageSelected = false;
                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId+'/competitions');
            }
        },

        isPremiumGroup() {
            return hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId);
        },

        isGroupMonetizationFeatureEnabled(){
            return this.$store.state.featureSettings.groupMonetizationEnabled;
        },


        openSubscriptions() {
            this.$refs.groupSubscriptionsModal.open(this.selectedGroup);
        },

        manageGroupPlan() {
            this.$refs.managePlanModal.open(this.selectedGroup );
        },

        showGroupPlan() {
            return (this.isGroupOwner() || this.isGroupAdmin()) && (!isINVRSGroup(this.selectedGroup.groupId) &&  !this.selectedGroup.educational);
        },

        getGroupPlan() {
            return getGroupSubscription(this.selectedGroup.subscriptionId).name;
        },

        canSendGroupEmail() {
           
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId) )&& (this.isGroupOwner() || this.isGroupAdmin());
        },

        canCreateMotion() {
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational ||hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId));
        },

        shouldShowCourseFeedActions() {
           
            if( this.selectedFeed && this.selectedFeed?.courseId ) {
                if( this.isGroupOwnerAdminOrMod()) {
                    return true;
                }
                else if( this.activeModuleTab==0 && this.selectedGroup?.isMember 
                    && this.cohortActive && this.selectedFeed?.allowMemberPosts ) {
                        return true;
                }
                else {
                    return false;
                }

            }
        },
        
        shouldShowDiscussionFeedActions() {
            
            if( this.selectedFeed  && !this.selectedFeed.courseId && this.selectedGroup?.isMember ) {
                if( this.isGroupOwnerAdminOrMod()) {
                    return true;
                }
                else if( this.selectedFeed.groupFeedId == 0 && this.selectedGroup?.allowMemberPosts ){
                    return true;
                }
                else if( this.selectedFeed.allowMemberPosts ){
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
            
        },

        getCohortStartDate() {
            if( this.selectedCourse?.userCohorts ){
                let lastCohort = this.selectedCourse?.userCohorts[this.selectedCourse?.userCohorts.length-1];
                return (lastCohort? lastCohort.startDate: "");
            }
            else {
                return "";
            }
        }, 
        trackSideMenuChange(e) {
            //console.log("active side group Menu changing from "+this.activeSideGroupMenu+" to "+ e.newItem)
            this.activeSideGroupMenu = e.newItem;
            if( this.activeSideGroupMenu == "Courses"){
                if( !this.$store.state.lastViewedCourse[this.selectedGroup.groupId] ) {
                   EventBus.emit('select-default-course');
                    
                }
            }
            
        },

        onCoursePaymentConfirmed() {
            EventBus.emit('track-complete-join-course', this.$route.query.courseId);
            this.initComponent();
        }, 

        onMembershipPaymentConfirmed() {
             this.initComponent();
        },

        justAfterCoursePaymentComplete(){
            //console.log("params=", this.$route.params);
            return (this.$route.query.courseId && this.$route.query.payment && this.$route.query.payment == 'complete' && !this.$route.query.membership); // last param is only there if we had to first sub to group before joining course
        },
        justAfterSubscriptionPaymentComplete(){
            //console.log("params=", this.$route.params);
            return (this.$route.query.subscription && this.$route.query.payment && this.$route.query.payment == 'complete');
        },
         justAfterMembershipPaymentComplete(){
            //console.log("params=", this.$route.params);
            return (this.$route.query.membership && this.$route.query.payment && this.$route.query.payment == 'complete');
        },
        waitForPaymentConfirmation() {
            //this.$route.params.groupId
            this.$nextTick( () => {
                 this.$refs.paymentConfirmationModal.open(this.$route.params.groupId, this.$route.query.courseId, this.$route.query.cohortId);
            });
        },

         waitForSubscriptionConfirmation() {
            //this.$route.params.groupId
            this.$nextTick( () => {
                 this.$refs.subscriptionConfirmationModal.open(this.$route.params.groupId, this.$route.query.subscription);
            });
        },

        waitForMembershipConfirmation() {
            //this.$route.params.groupId
            this.$nextTick( () => {
                 this.$refs.membershipConfirmationModal.open(this.$route.params.groupId);
            });
        },

        initComponent() {
            this.initGroup();
           
            if( this.$route.query.joinGroupByInvite && this.$route.query.inviteCode ){
                this.joinGroupByInvite(this.$route.query.inviteCode);
            }

           
            
            EventBus.off('edit-group-profile');
            EventBus.on('edit-group-profile', this.openEditProfileModal);
            EventBus.off("updated-group-feed-actions");
            EventBus.on("updated-group-feed-actions", this.updatedGroupFeedActions);
            EventBus.off("refresh-pending-requests");
            EventBus.on("refresh-pending-requests", this.refreshPrivateGroupPendingRequests);
            EventBus.off("select-channel");
            EventBus.on("select-channel", this.selectChannel);
            EventBus.off("select-group-feed");
            EventBus.on("select-group-feed", feed => {
                if(feed.courseId){
                    this.selectCourseFeed(feed);
                } else {
                    this.selectFeed(feed);
                }
            });
            EventBus.off("select-group-course");
            EventBus.on("select-group-course", this.selectCourse);
            EventBus.off("select-group-course-module");
            EventBus.on("select-group-course-module", this.selectCourseFeed);
            EventBus.off("open-create-channel-modal");
            EventBus.on("open-create-channel-modal", this.openCreateChannelModal);
            EventBus.off("open-create-edit-group-feed-modal");
            EventBus.on("open-create-edit-group-feed-modal", this.openCreateEditFeedModal);
            EventBus.off("open-create-edit-group-course-modal");
            EventBus.on("open-create-edit-group-course-modal", this.openCreateEditCourseModal);
            EventBus.on("open-create-channel-modal", this.openCreateChannelModal);
            EventBus.off("open-join-group-modal");
            EventBus.on("open-join-group-modal", this.openJoinGroupModal);
            EventBus.off("create-group-portfolio");
            EventBus.on("create-group-portfolio", this.createGroupPortfolio);
            EventBus.off("open-create-edit-group-portfolio-modal");
            EventBus.on("open-create-edit-group-portfolio-modal", this.openCreateEditGroupPortfolioModal);
            EventBus.off("open-add-group-portfolio-account-modal");
            EventBus.on("open-add-group-portfolio-account-modal", this.openAddGroupPortfolioAccountModal);
            EventBus.off("select-group-portfolio");
            EventBus.on("select-group-portfolio", this.selectPortfolio);
            EventBus.off("select-cash-account");
            EventBus.on("select-cash-account", this.selectCashAccount);
            EventBus.off('refresh-group-portfolios');
            EventBus.on('refresh-group-portfolios', () => {
                this.fetchGroupPortfolios();
            });

            EventBus.off("group-feed-deleted", this.onFeedDeleted);
            EventBus.on("group-feed-deleted", this.onFeedDeleted);
            EventBus.off("group-channel-deleted", this.onChannelDeleted);
            EventBus.on("group-channel-deleted", this.onChannelDeleted);
            EventBus.off("group-portfolio-deleted", this.onPortfolioDeleted);
            EventBus.on("group-portfolio-deleted", this.onPortfolioDeleted);
            EventBus.off("group-course-deleted", this.onCourseDeleted);
            EventBus.on("group-course-deleted", this.onCourseDeleted);
            EventBus.off("group-course-feed-deleted", this.onCourseFeedDeleted);
            EventBus.on("group-course-feed-deleted", this.onCourseFeedDeleted);
            EventBus.off("refresh-feed", this.onRefreshFeed);
            EventBus.on("refresh-feed", this.onRefreshFeed);
            EventBus.off("group-courses-reloaded", this.onCoursesReloaded);
            EventBus.on("group-courses-reloaded", this.onCoursesReloaded);
             EventBus.off("refresh-current-module", this.onRefreshCurrentModule);
            EventBus.on("refresh-current-module", this.onRefreshCurrentModule);
                
            
        },

        feedObject() {
            if (this.selectedFeed?.groupFeedId > 0) {
                return this.selectedFeed;
            } else {
                return this.selectedGroup;
            }
        },

        getGroupNotificationCount(){
            const groupId = this.selectedGroup.groupId;
            if(groupId){
                let count = this.groupChannelsUnreadCounts[groupId]?.unreadCount 
                    ? this.groupChannelsUnreadCounts[groupId].unreadCount : 0;
                count += this.feedNotificationCounts?.unseenGroupNotifications[groupId]?.allCount 
                    ? this.feedNotificationCounts.unseenGroupNotifications[groupId].allCount : 0;

                if(count > 10){
                    return "10+";
                }
                // console.log("GroupProfile getGroupsNotificationCount", this.groupChannelsUnreadCounts?.unreadCount,  this.feedNotificationCounts?.unseen?.numGroupPosts);
                return count;
            } else {
                return 0;
            }
        },

         portfoliosSettingsEnabled() {
            return PortfolioService.portfoliosSettingsEnabled();
        },

        portfoliosFeatureEnabled() {
            return PortfolioService.portfoliosFeatureEnabled();
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        toggleShowNotesOnly() {
            this.$store.commit("SET_SHOW_NOTES_ONLY", !this.showNotesOnly);
            EventBus.emit('update-show-notes-only-pref');
        },

        getLastConduitFromGroupHistory(){
            const history = this.$store.state.groupHistory ? this.$store.state.groupHistory[this.selectedGroup.groupId] : null;
            return history?.lastConduit;
        },

        isLastSelectedGroupViewChannel(){
            const lastConduit = this.getLastConduitFromGroupHistory();
            return lastConduit?.type == "channel";
        },

        selectGroupViewFromHistory(context) { //eslint-disable-line
            const lastConduit = this.getLastConduitFromGroupHistory();
            // console.log("selectGroupViewFromHistory", context, history);
            //console.log("selectGroupViewFromHistory", lastConduit);

            if (!lastConduit) {
                //console.log("last condiuite is null")
                // if( this.selectedGroup.educational) {
                //     this.selectCourses();
                // }
                // else {
                    this.selectLatestFeed();
                //}
                
            } else if(lastConduit?.type == "channel") {
                this.selectChannel(lastConduit.obj);
            } else if(lastConduit?.type == "feed") {
                this.selectFeed(lastConduit.obj);
            }  else if(lastConduit?.type == 'portfolio') {

                if (lastConduit?.tab) {
                    this.selectPortfolio({portfolio: lastConduit.obj, tab: lastConduit.tab});
                } else {
                    this.selectPortfolio({portfolio: lastConduit.obj});
                }
            } else if (lastConduit?.type === 'cashAccount') {
                this.selectCashAccount();
            } else if(lastConduit?.type == 'course') {
                this.selectCourse(lastConduit.obj);
            } else if(lastConduit?.type == 'courseFeed') {
                if( lastConduit.parent ) {
                    this.selectCourse(lastConduit.parent);
                }
                let feed = lastConduit.obj;
                
                this.selectCourseFeed(feed);
                
                
            }
        },

        generateGroupAvatar,

        isGroupOwner() {
            return this.userUtils.isUser(this.selectedGroup.groupOwnerId);
        },

        isGroupAdmin() {
            return UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },

        isGroupOwnerAdminOrMod() {
            return this.isGroupOwner() || this.isGroupAdmin() || UserUtils.isGroupMod(this.$store.getters['users/groupRoleList']);
        },

        isInvrsGroup() {
            return this.selectedGroup.groupId == 1;
        },

         isPortfolioFeatureEnabled(){
             
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId))
                &&
                (
                    (!this.isTeamGroup() && this.isGroupPortfolioEnabled())
                    || 
                    (this.isTeamGroup() && this.isTeamPortfolioEnabled())
                );
        },

        isGroupPortfolioEnabled(){
            return this.$store.state.featureSettings.groupPortfolioEnabled;
        },

        isTeamPortfolioEnabled(){
            return this.$store.state.featureSettings.teamPortfolioEnabled;
        },

        isTeamGroup() {
            return this.selectedGroup.groupTypeId == 2;
        },

        initGroup() {
            this.initGroupLoading = true;

            EventBus.emit("initial-side-menu-data-loading");

            this.selectedChannel= null;
            this.isChannelConnected = false;
           
            if(this.$route.params.groupId){
                //console.log("init group for "+this.$route.params.groupId, this.selectedGroupConduit);
                GroupService.getGroup(this.$route.params.groupId).then(async resp=> {
                    if( resp.data.status == 'success'){
                        //console.log("setting selected group to "+ JSON.stringify(resp.data.group) );
                        
                        this.$store.commit('SET_SELECTED_GROUP', resp.data.group);

                        const groupRolesResp = await GroupService.getGroupMemberRoles(this.selectedGroup.groupId, this.$store.state.users.user.userId);
                        
                        if (groupRolesResp.data.status === 'success') {
                            this.$store.commit('users/SET_GROUP_ROLES', [...groupRolesResp.data.roles]);
                        }
                    
                        if ((this.isGroupOwner() || this.isGroupAdmin()) && this.selectedGroup.isPrivate) {
                            this.refreshPrivateGroupPendingRequests();
                        }

                        if (this.selectedGroup.links && this.selectedGroup.links.length > 0) {
                            this.groupLink = this.selectedGroup.links[0].url;
                        } else {
                            this.groupLink = null;
                        }

                        GroupService.getGroupJoinRequest(this.selectedGroup.groupId).then(resp => {
                            if( resp.data.status == 'success') {
                                let req = resp.data.request;
                                if( req != null && req.retiredDate == null && req.accepted == false ){
                                    this.hasPendingRequest = true;
                                }
                                else {
                                    this.hasPendingRequest = false;
                                }
                            }

                            //console.log("has pending request = " + this.hasPendingRequest);
                        });

                        if (this.isPortfolioFeatureEnabled()) {
                            this.fetchGroupPortfolios();
                        } else {
                            this.$store.commit("SET_CURRENT_GROUP_PORTFOLIOS", [communityPortfolio]);
                        }


                        if( this.$route.query.postId ) {
                            FeedService.getInAppSharedPost( this.$route.query.postId).then((activity) => {
                            
                                if( activity.groupFeed ) {

                                    if( activity.groupFeed.courseId) {
                                    
                                        activity.groupFeed['openPost'] = activity;
                                        this.selectCourseFeed(activity.groupFeed);
                                    }
                                    else {
                                        this.selectFeed(activity.groupFeed);
                                    }
                                }
                            });
                        }
                        else if( this.$route.params.courseId){
                            //console.log("navigating to course")
                            this.activeSideGroupMenu = "Courses";
                          
                                EventBus.emit('select-default-course', this.$route.params.courseId);
                                
                        }
                        else {
                            this.selectGroupViewFromHistory("initGroup");
                        }
                    }
                    else {
                        console.log("error getting group "+ resp.data.message);
                    }

                    
                    //console.log("route full path is "+ this.$route.fullPath)
                    if( this.$route.fullPath.indexOf('/competitions') > 0){
                        if( !this.competitionsSelected  ){
                            this.competitionsSelected = true;
                            this.landingPageSelected = false;
                            if(this.$route.query.contestId && this.$route.query.payment && this.$route.query.payment == 'complete') {
        
                                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId+'/competitions?contestId='+this.$route.query.contestId+"&payment=complete");
                            }
                            else {
                                this.$router.push('/groups/profile/'+this.selectedGroup?.groupId+'/competitions');
                            }

                           
                        }
                    }
                    else if(this.$route.fullPath.indexOf('/landing') > 0){
                        if( !this.landingPageSelected ){
                            this.landingPageSelected = true;
                            this.competitionsSelected = false;
                        }
                    }
                    else {
                        this.competitionsSelected = false;
                        this.landingPageSelected = false;
                    }
                    if(this.$route.query.courseReg == 'true'){
                        this.startCourseRegistration();
                    }

                    this.initGroupLoading = false;
                });
            }
        },

        queryString(fullPath){
            let i = fullPath.indexOf('?');
             //console.log("route queryString = ", fullPath.substring(i+1));
            return fullPath.substring(i+1);
        },

        startCourseRegistration() {
             
            GroupService.getGroupCourse(this.$route.params.groupId, this.$route.query.courseId).then(resp => {
               
                if( resp.data.status == 'success'){
                    if( this.selectedGroup.subscriptionRequired && !this.selectedGroup.isMember){
                        
                        this.$refs.subscribeToGroupModal.open(this.selectedGroup, "&"+this.queryString(this.$route.fullPath));
                    }
                    else {
                        this.selectedCourse = resp.data.course;
                        this.$refs.selectCohortModal.open(this.selectedCourse, this.$route.query.cohortId);
                    }
                }
                else {
                    console.log("error getting course for registration", resp.data);
                }
            }).catch(err => {
                    console.log("error getting course for registration", err);
            })
            

        },

        updatedGroupFeedActions(updatedFeed) {
            if(this.selectedFeed && this.selectedFeed.groupFeedId == updatedFeed.groupFeedId){
                if(this.feed?.courseId){
                    this.selectCourseFeed(updatedFeed);
                } else {
                    this.selectFeed(updatedFeed);
                }
            } 
        },

        onFeedDeleted() {
            EventBus.emit('refresh-my-feed-list');
            this.selectLatestFeed();
        },

        onCourseDeleted() {
            EventBus.emit('refresh-courses-list');
            this.selectLatestFeed();
        },

        onCourseFeedDeleted() {
            EventBus.emit('refresh-courses-list');
            this.selectLatestFeed();
        },

        onRefreshFeed() {
            //console.log("on refresh feed called")
            if( this.showCourseLessons){
                this.loadModuleLessons(this.selectedFeed);
                 //console.log("on refresh feed called and showing course lesson")
                //this.selectCourseFeed(this.selectedFeed);
                //this.$emit('module-clicked', this.selectedFeed);
            }
        },

        openStatusEditorDialog() {
            this.$refs.statusEditorModal.open(this.selectedGroup, this.selectedFeed);
        },

        openQuestionEditorDialog() {
            this.$refs.statusEditorModal.open(this.selectedGroup, this.selectedFeed, true);
            
        },

         openCreateMotionModal() {
            EventBus.emit('open-create-motion-modal', {group:this.selectedGroup, accountId: null});
        },

        openEditProfileModal() {
            this.$refs.editProfileModal.open();
        },
        openGroupMembersModal() {
            this.$refs.groupMembersModal.open();
        },
        openGroupSettingsModal() {
            this.$refs.groupSettingsModal.open();
        },
        openGroupRequestsModal() {
            this.$refs.groupRequestsModal.open();
        },
        openInviteFriendsModal() {
            this.$refs.inviteFriendsModal.open();
        },
        openGroupProfileSideMenuModal() {
            if( this.$refs.channelComp ) {
                this.$refs.channelComp.pauseConnection();
            }
            if( this.$refs.groupProfileSideMenuModal) {
                this.$refs.groupProfileSideMenuModal.open();
            }
        },
        closeGroupProfileSideMenuModal() {
            this.$refs.groupProfileSideMenuModal?.close();
        },
        onCloseGroupProfileSideMenuModal() {
            if( this.$refs.channelComp ) {
                //console.log("this.$refs.channelComp.reconnectOnVisibleAgain();");
                this.$refs.channelComp.reconnectOnVisibleAgain();
            }
        },
        openCreateChannelModal() {
            this.$refs.createChannelModal.open(this.selectedGroup);
        },
        openCreateEditFeedModal(data) {
            this.$refs.createEditFeedModal.open(this.selectedGroup, data?.feed, data?.course);
        },
        openCreateEditCourseModal(course) {
            this.$refs.createEditCourseModal.open(this.selectedGroup, course);
        },
        openJoinGroupModal(data) {
            this.$refs.joinGroupModal.open(data.group, data.joinGroupFunc);
        },
        createGroupPortfolio() {
            if(this.selectedGroup.aggregatePortfoliosEnabled && this.selectedGroup.collaborativePortfoliosEnabled){
                this.$refs.choosePortfolioTypeModal.open();
            } else if (this.selectedGroup.collaborativePortfoliosEnabled) {
                this.openCreateEditGroupPortfolioModal({ isCollaborativePortfolio: true });
            } else {
                this.openCreateEditGroupPortfolioModal();
            }
        },
        openCreateEditGroupPortfolioModal(data) {
            this.$refs.createEditGroupPortfolioModal.open(data);
        },
        openAddGroupPortfolioAccountModal(data) {
            if(data?.portfolio.groupPortfolioTypeId === COLLABORATIVE_PORTFOLIO_TYPE.ID){
                this.$refs.addCollaborativePortfolioAccountModal.open(data);
            } else {
                this.$refs.addGroupPortfolioAccountModal.open(data);
            }
        },

        onNewChannelCreated() {
            if( this.selectedChannel ) {
                this.$refs.channelComp.close();
            }
            EventBus.emit('refresh-my-channel-list');
            if( this.selectedChannel ) {
                this.$refs.channelComp.reconnect("after channel created");
            }

            
        },

        onChannelDeleted(deletedChannel) {
            if( this.selectedChannel ) {
                this.$refs.channelComp.close();
            }
            EventBus.emit('refresh-my-channel-list');

            if( this.selectedChannel ) {
                if( this.selectedChannel.id != deletedChannel.id) {
                    this.$refs.channelComp.reconnect("after channel deleted");
                }
                else {
                    this.selectLatestFeed();
                }
            }

            
        },

        onPortfolioDeleted() {
            EventBus.emit('refresh-group-portfolios');
            this.selectLatestFeed();
        },

        canInvite() {
            //console.log("private? = " + this.selectedGroup.isPrivate);
            //console.log("owner is "+ this.selectedGroup.groupOwnerId + " and current user is "+this.$store.state.users.user.userId)
            return (UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']) || UserUtils.isGroupMod(this.$store.getters['users/groupRoleList']));
        },
        onEducationGroupJoined(group) {
            // callback when joining a private not for profit education group via access code
            
            this.$store.commit('SET_SELECTED_GROUP', group);
            this.$store.commit('MARK_CHANNELS_STALE');
            this.$toast.add({ severity: 'success', summary: `You have now joined ${group.name}!`, life: 3500, });
            if( this.$refs.curMemberSearch ){
                this.$refs.curMemberSearch.refresh();
            }
            this.selectLatestFeed();
            EventBus.emit('refresh-app-group-menu');
            this.fetchGroupPortfolios();

            EventBus.emit('track-joining-group');
            
        },
        onJoinCourse(course){
             if( this.selectedGroup.subscriptionRequired && !this.selectedGroup.isMember){
                this.$refs.subscribeToGroupModal.open(this.selectedGroup);
            }
            else {
                this.$refs.selectCohortModal.open(course);
            }
        },
        joinCourse() {
            if( this.selectedGroup.subscriptionRequired && !this.selectedGroup.isMember){
                this.$refs.subscribeToGroupModal.open(this.selectedGroup);
            }
            else {
                this.$refs.selectCohortModal.open(this.selectedCourse);
            }
        },

        join() {
            //const joinGroupFunc = () => {
                if( this.selectedGroup.educational ) {
                    this.$refs.selectCohortModal.open();
                }
                else if( this.selectedGroup.subscriptionRequired){
                    this.$refs.subscribeToGroupModal.open(this.selectedGroup);
                }
                else if( this.selectedGroup.isPrivate ) {
                    GroupService.requestToJoin(this.selectedGroup.groupId).then(resp=> {
                        if( resp.data.status == "success"){
                            this.hasPendingRequest = true;
                            this.$toast.add({ severity: 'success', summary: `A request has been sent to the group administrator for ${this.selectedGroup.name}.   You will be notified when your request is accepted.`, life: 2500, });
                        }
                    });
                }
                else {
                    GroupService.joinGroup(this.selectedGroup).then(resp => {
                        if( resp.data.status === "success") {
                            resp.data.group.isMember = true;
                            this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                            this.$store.commit('MARK_CHANNELS_STALE');
                            this.$toast.add({ severity: 'success', summary: `You have now joined ${resp.data.group.name}!`, life: 3500, });
                            if( this.$refs.curMemberSearch ){
                                this.$refs.curMemberSearch.refresh();
                            }
                            this.selectLatestFeed();
                            EventBus.emit('refresh-app-group-menu');
                            this.fetchGroupPortfolios();

                            if(this.isTeamGroup()) {
                                EventBus.emit('track-joining-team');
                            } else {
                                EventBus.emit('track-joining-group');
                            }
                        }
                        else {
                            console.log("error joinging group "+ resp.data.message);
                            if(resp.data.message && resp.data.message.startsWith("Membership Full.")){
                                this.$toast.add({severity:'info', summary: "Oops! This group's membership is currently full.", detail:'', life: 4000, group: 'center'});
                            }
                            else if( resp.data.message == 'Not Authorized') {
                                this.$toast.add({ severity: 'error', summary: `You are not authorized to join this group.`, life: 2500, });
                            }
                            else {
                                this.$toast.add({ severity: 'error', summary: `An unexpected error occurred while joining group.  Please try again or contact support@invrs.com.`, life: 2500, });
                            }
                        }
                    })
                }
            //}

            // if(this.selectedGroup && this.isTeamGroup() && this.selectedGroup.groupPortfoliosEnabled){
            //     EventBus.emit("open-join-group-modal", { joinGroupFunc:joinGroupFunc });
            // } else {
               // joinGroupFunc();
            // }
        },

        joinGroupByInvite (inviteCode) {
            //console.log("joinGroupByInvite", inviteCode);
            const joinGroupFunc = () => {
                GroupService.joinGroupByInvite(inviteCode).then(resp=> {
                    if( resp.data.status == 'registration-required') {
                         this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                         this.$nextTick(()=> {
                            this.join();
                         });
                    }
                    else if( resp.data.status === "success") {
                        //console.log("joinGroupByInvite", resp);
                        this.$store.commit("SET_GROUP_INVITE_CODE", null);
                        this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                        this.$router.push('/groups/profile/' + resp.data.group.groupId+"?justJoined=true");
                        EventBus.emit('refresh-app-group-menu');

                        if(this.isTeamGroup()) {
                            EventBus.emit('track-joining-team');
                        } else {
                            EventBus.emit('track-joining-group');
                        }
                    }
                    else {
                        console.log("error joining group "+ resp.data.message)
                        
                        if(resp.data.message && resp.data.message.startsWith("Membership Full.")){
                            setTimeout(() => {
                                this.$toast.add({severity:'info', summary: "Oops! This group's membership is currently full.", detail:'', life: 4000, group: 'center'});
                            }, 2000);
                        }
                    }
                });
            };

            // if(this.selectedGroup && this.isTeamGroup() && this.selectedGroup.groupPortfoliosEnabled){
            //     EventBus.emit("open-join-group-modal", { joipendingRequestCountnGroupFunc:joinGroupFunc });
            // } else {
                joinGroupFunc();
            // }
        },

        refreshPrivateGroupPendingRequests() {
            GroupService.getJoinRequests(this.selectedGroup.groupId, 1, 0, null, true).then(resp => {
                if( resp.data.status == 'success') {
                    let data = resp.data;
                    //console.log("GroupService.getJoinRequests", resp);
                    if(data && data.totalCount){
                        this.pendingRequestCount = data.totalCount;
                    }
                    else {
                        this.pendingRequestCount = 0;
                    }
                }
            });
        },
        
        getGroupMenuItems() {
            let menuItems = [];

            if(this.isMobile()){
                menuItems.push({
                    label: 'Members',

                    icon: 'pi pi-users',
                    command: () => {
                        this.openGroupMembersModal();
                    }
                });
            }

            // <!-- <Button v-if="selectedFeed" :class="{'action-button-blue blue-button': true, 'selected': showNotesOnly}"
            //             v-tooltip.bottom="'Notes Only'" icon='pi pi-pencil' @click="toggleShowNotesOnly"/>
            //         <Button v-if="(isGroupOwner() || isGroupAdmin()) && selectedGroup.isPrivate" class="action-button-blue blue-button" 
            //             icon='pi pi-inbox' @click='openGroupRequestsModal' v-tooltip.bottom="'Pending Requests'" 
            //             v-badge.info="pendingRequestCount" :disabled="pendingRequestCount == 0"/>
            //         <Button v-if="isGroupOwner() || isGroupAdmin()" class="action-button-blue blue-button settings-button" @click='openGroupSettingsModal' v-tooltip.bottom="'Settings'">
            //             <img src="/assets/images/settings.png" />
            //         </Button>
                    
            //         <template v-if='selectedGroup.bio && !isInvrsGroup()'>
            //             <Button v-if='selectedGroup.isMember && canInvite()' class="action-button-blue blue-button" icon="pi pi-user-plus" v-tooltip.bottom="'Invite'" @click='openInviteFriendsModal'/>
            //             <Button v-else-if='!isTeamGroup() && !selectedGroup.isMember' class='action-button-blue green-button__primary' :label="hasPendingRequest ? 'Pending' : 'Join'" :disabled='hasPendingRequest'  @click="join"/>
            //         </template> -->


            if (this.selectedGroup.bio && !this.isInvrsGroup() ){
                if( this.selectedGroup.isMember && this.canInvite()){
                    menuItems.push({
                        label: 'Invite',
                        
                        icon: 'pi pi-user-plus',
                        command: () => {
                            this.openInviteFriendsModal();
                        }
                    });
                }
            }
            
            if (this.isGroupOwner() || this.isGroupAdmin()){
                menuItems.push({
                    label: 'Settings',
                    
                    icon: 'pi pi-cog',
                    command: () => {
                        this.openGroupSettingsModal();
                    }
                });
            }

            if ((this.isGroupOwner() || this.isGroupAdmin()) && this.selectedGroup.isPrivate){
                menuItems.push({
                    label: 'Pending Requests',
                    
                    icon: 'pi pi-inbox',
                    command: () => {
                        this.openGroupRequestsModal();
                    }
                });
            }

            if (UserUtils.isUser(this.selectedGroup.groupOwnerId)) {
                menuItems.push({
                    label: 'Delete group',
                    
                    icon: 'pi pi-trash',
                    command: () => {
                        this.selectedMenuOption = 1;
                        this.headerText = `Delete Group`;
                        this.confirmText = `Are you sure you want to delete ${this.selectedGroup.name}?`
                        this.cancelConfirmLabel = 'Cancel'
                        this.confirmConfirmLabel = 'OK'
                        this.$refs.confirmModal.open();
                    }
                });

                if (this.selectedGroup.bio) {
                    menuItems.push({
                        label: 'Edit profile',

                        icon: 'pi pi-pencil',
                        command: () => {
                            this.$refs.editProfileModal.open(); 
                        }
                    })
                }
            } else if (this.selectedGroup.isMember && !this.isInvrsGroup()) {
                menuItems.push({
                    label: 'Leave group',
                    
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.selectedMenuOption = 0;
                        
                        this.headerText = `Leave Group`;
                        this.confirmText = `Are you sure you want to cancel your membership to  ${this.selectedGroup.name}?`
                        this.cancelConfirmLabel = 'No'
                        this.confirmConfirmLabel = 'Yes'
                        
                        this.$refs.confirmModal.open();
                    }
                });
            }

            return menuItems;
        },

        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },

        groupSubInfo,

        loadGroupPosts(currentFeedItems) {
            //console.log("loadGroupPosts");
            if( !this.loading && this.selectedGroup  && this.selectedFeed ) {
                
                    if( this.isInvrsGroup() ) {
                        return FeedService.getLatestPosts(currentFeedItems.length, false);
                    }
                    else if( this.selectedGroup.isMember || !this.selectedGroup.isPrivate) {
                        if(this.selectedFeed.courseId){
                            if(currentFeedItems.length == 0){
                                //this.courseFeedLoaders[this.selectedFeed.groupFeedId] = this.createCourseFeedLoader(this.selectedFeed.groupFeedId);
                                
                                //EventBus.emit('assign-course-module-outline', {feed: this.selectedFeed, loader:this.courseFeedLoaders[this.selectedFeed.groupFeedId]});
                                // let lessonObj = this.$store.state.courseModuleLessons[this.selectedFeed.groupFeedId];
                                // if( !lessonObj.stale ) {
                                    let lessons =  this.$store.state.courseModuleLessons[this.selectedFeed.groupFeedId];
                                    if( lessons){
                                        return lessons;
                                    }
                                    else {
                                        return [];
                                    }
                                // }
                                // else {
                                //     this.selectCourseFeed(this.selectedFeed);

                                // }
                            } else {
                                return [];
                            }
                        } else {
                            return FeedService.getGroupPosts(this.selectedGroup.groupId, 15, currentFeedItems.length, false, (this.selectedFeed && this.selectedFeed.groupFeedId > 0 ? this.selectedFeed.groupFeedId : null));
                        }
                    }
                    else {
                        return [];
                    }
                
                
               
            }
            else {
                return [];
            }
          
            
        },


        onConfirmed() {
            const id = this.selectedGroup.groupId;

            if (this.selectedMenuOption === 0) {
                GroupService.leaveGroup(id).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$store.commit('MARK_CHANNELS_STALE');
                        this.$router.push('/groups');

                        EventBus.emit("refresh-app-group-menu");
                    } else {
                        console.log("error leaving group "+ resp.data.message);
                        if( resp.data.message == 'Not Authorized') {
                            this.$toast.add({ severity: 'error', summary: `You are not authorized to leave this group.`, life: 2500, });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: `An unexpected error occurred while joining group.  Please try again or contact support@invrs.com.`, life: 2500, });
                        }
                    }
                })
            } else if (this.selectedMenuOption === 1) {
                GroupService.deleteGroup(id).then(resp => {
                    if( resp.data.status === "success") {
                        this.$store.commit('MARK_CHANNELS_STALE');
                        this.$router.push('/groups');

                        EventBus.emit("deleted-group");
                        EventBus.emit("refresh-app-group-menu");
                    }
                    else {
                        console.log("error deleting group "+ resp.data.message);
                        if( resp.data.message == 'Not Authorized') {
                            this.$toast.add({ severity: 'error', summary: `You are not authorized to delete this group.`, life: 2500, });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: `An unexpected error occurred while joining group.  Please try again or contact support@invrs.com.`, life: 2500, });
                        }
                    }
                })
            }
        },

        selectLatestFeed(){
            const latest =  {groupId: this.selectedGroup.groupId, groupFeedId: 0, name:"Latest"};
            this.selectFeed(latest);
        },

        resetSelectedGroupView(closeModal){
            this.isChannelConnected = false;
            this.selectedChannel= null;
            this.selectedFeed= null;
            this.selectedPortfolio = null;
            this.selectedCashAccount = null;
            this.selectedPostsStatsOption = postsStatsOptionLabels.posts;

            if( closeModal && this.isMobile() ){
                this.closeGroupProfileSideMenuModal();
            }
        },

        selectCourses() {
          
            if( !this.isMobile() ) {
               
                this.$refs.groupSideMenu.selectTabByIndex(0);
            }
           // this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "courseFeed", obj : null});
           
            
            // const history = this.$store.state.groupHistory ? this.$store.state.groupHistory[this.selectedGroup.groupId] : null;
            // if( history ) {
            //     history.lastConduit = null;
            // }
            
        },

        async loadModuleLessons(courseModule){
            const resp = await FeedService.getGroupPosts(this.selectedGroup.groupId, 100, 0, false, courseModule.groupFeedId);
            if( resp && resp.length > 0  ) {
                let lessons =  resp.map(post => {
                        if (!post.title) {
                            let escape = document.createElement('div');
                            escape.innerHTML = post.content;
                            post["outlineTitle"] = escape.textContent.length > 40 ? escape.textContent.slice(0, 40) + "..." : escape.textContent;
                        } else {
                            post["outlineTitle"] = post.title;
                        }

                        return post;
                    });
                 this.$store.commit("SET_COURSE_MODULE_LESSONS", {feedId: courseModule.groupFeedId, lessons: lessons});
            }
            else {
                this.$store.commit("SET_COURSE_MODULE_LESSONS", {feedId: courseModule.groupFeedId, lessons: []});
            }
            if(this.$refs.activityList){
                this.$refs.activityList.refreshFeed("GroupProfile.selectCourseFeed");
            }
            if(courseModule.openPost){
                EventBus.emit("open-feed-post", courseModule.openPost);
            }
            
       
        },

        onCoursesReloaded(courses) {
            //console.log("courses reloaded")
            if( this.selectedCourse ){
                for( var i = 0; i < courses.length; ++i){
                    if( courses[i].courseId == this.selectedCourse.courseId){
                         //console.log("selecting course again")
                        this.selectCourse(courses[i]);
                        break;
                    }
                }
               
               // EventBus.emit("select-group-course", this.selectedCourse);
            }
        },

        selectCourse(course) { 
            if(this.selectedGroup.groupId != course?.groupId){
                //console.debug("Selected group and selected course feed don't match. Ignoring.");
                return;
            }

            this.resetSelectedGroupView(true);
        
            if(  this.isPrivateGroupMemberOrPublicGroup ){
                this.selectedCourse = course;
                this.$store.commit("SET_LAST_VIEWED_COURSE", {groupId: this.selectedGroup.groupId, course: course});
                this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "course", obj : course});
                
                this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                    groupId: this.selectedGroup.groupId, 
                    conduit: {
                        groupId: this.selectedGroup.groupId,
                        type: "course", 
                        obj: course
                    }
                });

                GroupService.getGroupCourseAccess(this.selectedGroup.groupId, this.selectedCourse.courseId);
            }
        },

        onRefreshCurrentModule() {
            if( this.selectedFeed ){
                this.selectCourseFeed(this.selectedFeed);
            }
        },

        selectCourseFeed(courseFeed) { 
            if( !this.selectedCourse) {
                this.selectedCourse = {courseId:courseFeed.courseId, groupId:courseFeed.groupId};
                GroupService.getGroupCourseAccess(this.selectedGroup.groupId, this.selectedCourse.courseId);
            }
            if(this.selectedFeed?.groupFeedId == courseFeed.groupFeedId && courseFeed.openPost){
                EventBus.emit("open-feed-post", courseFeed.openPost);
                if( this.isMobile() ){
                    this.closeGroupProfileSideMenuModal();
                }
                return;
            }

            this.resetSelectedGroupView(courseFeed.closeModal);

            if(this.selectedGroup.groupId != courseFeed?.groupId){
                //console.debug("Selected group and selected course feed don't match. Ignoring.");
                return;
            }
        
            if(  this.canViewCourseLessons  ){//this.isPrivateGroupMemberOrPublicGroup && (
                //this.courseFeedLoaders[courseFeed.groupFeedId] = this.createCourseFeedLoader(courseFeed.groupFeedId);
                this.loadModuleLessons(courseFeed);
               
                                
               // EventBus.emit('assign-course-module-outline', {feed: courseFeed, loader:this.courseFeedLoaders[courseFeed.groupFeedId]});

                this.selectedFeed = courseFeed;
                
                this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "courseFeed", obj : courseFeed});
                
                this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                    groupId: this.selectedGroup.groupId, 
                    conduit: {
                        groupId: this.selectedGroup.groupId,
                        type: "courseFeed", 
                        obj: courseFeed,
                        parent: (this.selectedCourse ? this.selectedCourse : null),
                    }
                });

                
                
                // this.$nextTick(() => {

                    
                //     if(this.$refs.activityList){
                //         this.$refs.activityList.refreshFeed("GroupProfile.selectCourseFeed");
                //     }
                //     if(courseFeed.openPost){
                //         EventBus.emit("open-feed-post", courseFeed.openPost);
                //     }
                    
                // });
            }
        },

        selectFeed(feed) { 
            this.resetSelectedGroupView(true);
            
            if(this.selectedGroup.groupId != feed?.groupId){
                //console.debug("Selected group and selected feed don't match. Ignoring.");
                return;
            }
        
            if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){
                this.selectedFeed = feed;
                
                this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "feed", obj : feed});
                
                this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                    groupId: this.selectedGroup.groupId, 
                    conduit: {
                        groupId: this.selectedGroup.groupId,
                        type: "feed", 
                        obj: feed
                    }
                });

                this.$nextTick(() => {
                    if(this.$refs.activityList){
                        this.$refs.activityList.refreshFeed("GroupProfile.selectFeed");
                    }
                });
            }
        },

        selectPortfolio(params) { 
            const portfolio = params.portfolio;
            const tab = params.tab !== undefined ? params.tab : 'Overview';

            this.resetSelectedGroupView(true);
            
            if(this.selectedGroup.groupId != portfolio?.groupId){
                //console.debug("Selected group and selected portfolio don't match. Ignoring.");
                return;
            }
        
            if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){

                this.selectedPortfolio = portfolio;

                this.$store.commit('SET_CURRENTLY_SELECTED_PORTFOLIO', this.selectedPortfolio);

                this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "portfolio", tab: tab, obj : portfolio});

                if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){
                    this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                        groupId: this.selectedGroup.groupId, 
                        conduit: {
                            groupId: this.selectedGroup.groupId,
                            type: "portfolio", 
                            tab: tab,
                            obj: portfolio
                        }
                    });
                } 
            }
        },

        selectCashAccount() { 
            this.resetSelectedGroupView(true);
        
            if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){

                this.selectedCashAccount = {
                    groupId: this.selectedGroup.groupId,
                    type: "cashAccount"
                };

                //this.$store.commit('SET_CURRENTLY_SELECTED_CASH_ACCOUNT', this.selectedCashAccount);

                this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "cashAccount", obj : this.selectedCashAccount});

                if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){
                    this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                        groupId: this.selectedGroup.groupId, 
                        conduit: {
                            groupId: this.selectedGroup.groupId,
                            type: "cashAccount", 
                            obj: this.selectedCashAccount
                        }
                    });
                }
            }
        },

        selectChannel(channelObj) {
            this.resetSelectedGroupView(true);

            const channel = channelObj.channelData ? channelObj.channelData :  channelObj;
            
            if(this.selectedGroup.groupId != channel?.data?.group?.groupId){
                //console.debug("Selected group and selected channel don't match. Ignoring.");
                return;
            }
        
            if( this.selectedGroup.bio && this.isPrivateGroupMemberOrPublicGroup ){
                try {
                    this.selectedChannel = channel;

                    this.$store.commit("SET_SELECTED_GROUP_CONDUIT", {groupId: this.selectedGroup.groupId, type: "channel", obj : this.selectedChannel});

                    this.$store.commit("SET_GROUP_ACCESS_HIST", { 
                        groupId: this.selectedGroup.groupId, 
                        conduit:{
                            groupId: this.selectedGroup.groupId,  
                            type:"channel", 
                            obj:this.selectedChannel
                        }
                    });
                    
                    this.isChannelConnected = true;
                } catch(err) {
                    this.isChannelConnected = false;
                    this.selectedChannel = null;
                    const summary = `Something went wrong with trying to connect to channel "${channelObj.channelTitle}". Contact Support and write a ticket.`;

                    console.debug('There was an error:' + err);
                    this.$toast.add({severity:'error', summary: summary, life: 5000});
                }
            }
        },

        fetchGroupPortfolios() {
            if(this.selectedGroup.isMember){
                this.$store.commit("SET_CURRENT_GROUP_PORTFOLIOS", [communityPortfolio]);
                if (!this.fetchPortfoliosInProgress) {
                    this.fetchPortfoliosInProgress = true;

                    GroupService.getGroupPortfolios(this.selectedGroup.groupId).then(resp => {
                        this.fetchPortfoliosInProgress = false;

                        if (resp.data.status == "success") {
                            //console.log("got  "+ resp.channels.length+" channels");
                            resp.data.groupPortfolios.forEach(p => {
                                p['accountTypeId'] = -1;
                            });
                            if(resp.data.groupPortfolios){
                                this.$store.commit("SET_CURRENT_GROUP_PORTFOLIOS", [communityPortfolio, ...resp.data.groupPortfolios]);
                            } else {
                                this.$store.commit("SET_CURRENT_GROUP_PORTFOLIOS", [communityPortfolio]);
                            }
                            //console.log("this.currentGroupPortfolios", resp.data.groupPortfolios, this.selectedGroup.groupId);
                        } else {
                            console.error("error getting group portfolios :" + resp.data.message)
                        }
                    });
                }
            }
        },

        openSendEmailModal() {
            this.$refs.sendEmailModal.open();
        },
    }
}
</script>

<style >
.landing-preview .ql-align-center {
    text-align: center;
}

.landing-preview .ql-align-right {
    text-align: right;
}

.landing-preview .ql-align-justify {
    text-align: justify;
}


.landing-preview ol li:before {
        content: counter(list-0,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-1:before {
        content: counter(list-1,lower-alpha) ". ";
    }

    .landing-preview ol li.ql-indent-2:before {
        content: counter(list-2,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-3:before {
        content: counter(list-3,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-4:before {
        content: counter(list-4,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-5:before {
        content: counter(list-5,lower-roman) ". ";
    }
    .landing-preview ol li.ql-indent-6:before {
        content: counter(list-6,decimal) ". ";
    }
    .landing-preview ol li.ql-indent-7:before {
        content: counter(list-7,lower-alpha) ". ";
    }
    .landing-preview ol li.ql-indent-8:before {
        content: counter(list-8,lower-roman) ". ";
    }

 .landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.landing-preview li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.landing-preview ul>li:before {
    content: "\2022";
}

.landing-preview li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

  .landing-preview ol>li, .landing-preview ul>li {
    list-style-type: none;
} 

.landing-preview ol, .landing-preview ul{
    
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    list-style-position: inside;
}

 .landing-preview ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.landing-preview ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
} 

/* .landing-preview ol li.ql-indent-1 {
    counter-increment: list-1;
} */

.landing-preview ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-2 {
    counter-increment: list-2;
}

.landing-preview ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-3 {
    counter-increment: list-3;
}

.landing-preview ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-4 {
    counter-increment: list-4;
}

.landing-preview ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-5 {
    counter-increment: list-5;
}

.landing-preview ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}

.landing-preview ol li.ql-indent-6 {
    counter-increment: list-6;
}

.landing-preview ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}

.landing-preview ol li.ql-indent-7 {
    counter-increment: list-7;
}

.landing-preview ol li.ql-indent-8 {
    counter-reset: list-9;
}

.landing-preview ol li.ql-indent-8 {
    counter-increment: list-8;
}

</style>

<style scoped>

::v-deep(.landing-preview ol li:not(.ql-direction-rtl), .landing-preview ul li:not(.ql-direction-rtl) ){
    padding-left: 1.5em;
}

::v-deep(.landing-preview li.ql-indent-1:not(.ql-direction-rtl) ){
    padding-left: 4.5em;
}
::v-deep(.landing-preview li.ql-indent-2:not(.ql-direction-rtl) ){
    padding-left: 7.5em;
}
::v-deep(.landing-preview li.ql-indent-3:not(.ql-direction-rtl) ){
    padding-left: 10.5em;
}
::v-deep(.landing-preview li.ql-indent-4:not(.ql-direction-rtl) ){
    padding-left: 13.5em;
}
::v-deep(.landing-preview li.ql-indent-5:not(.ql-direction-rtl) ){
    padding-left: 16.5em;
}
::v-deep(.landing-preview li.ql-indent-6:not(.ql-direction-rtl) ){
    padding-left: 19.5em;
}
::v-deep(.landing-preview li.ql-indent-7:not(.ql-direction-rtl) ){
    padding-left: 22.5em;
}
::v-deep(.landing-preview li.ql-indent-8:not(.ql-direction-rtl) ){
    padding-left: 25.5em;
}

::v-deep(.landing-preview .ql-indent-1:not(.ql-direction-rtl)){
    padding-left: 3em;
}
::v-deep(.landing-preview .ql-indent-2:not(.ql-direction-rtl)){
    padding-left: 6em;
}
::v-deep(.landing-preview .ql-indent-3:not(.ql-direction-rtl)){
    padding-left: 9em;
}
::v-deep(.landing-preview .ql-indent-4:not(.ql-direction-rtl)){
    padding-left: 12em;
}
::v-deep(.landing-preview .ql-indent-5:not(.ql-direction-rtl)){
    padding-left: 15em;
}
::v-deep(.landing-preview .ql-indent-6:not(.ql-direction-rtl)){
    padding-left: 18em;
}
::v-deep(.landing-preview .ql-indent-7:not(.ql-direction-rtl)){
    padding-left: 21em;
}
::v-deep(.landing-preview .ql-indent-8:not(.ql-direction-rtl)){
    padding-left: 24em;
}

::v-deep(.send-dm-button) {
	border-radius: 16px;
	padding: 6px 16px;
	border-style: solid;
    border-width: 1px;
    margin-right: 8px;
}

.confirm-container {
   

    margin: 12px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):not(:hover) .p-menuitem-link) {
    border-bottom:none;
}
::v-deep(.p-tabmenu .p-tabmenu-nav) {
    margin-bottom: 10px;
    text-align: center;
    border-bottom:none;
}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem ){
    margin:auto;
}



::v-deep(.p-tabmenu .p-tabmenu-nav .p-menuitem-link ){
    padding-top:2px;
    padding-bottom:5px;
}
.card {
    margin-bottom: 20px;
}

.column-container {
    max-height: calc(100vh - 190px) !important;
    height: calc(100vh - 190px) !important;
}

.landing-container {
    max-height: calc(100vh - 250px) !important;
    height: calc(100vh - 250px) !important;
}

::v-deep(.feed-activity-list .p-scrollpanel) {
    height: calc(100vh - 172px) !important;
}

::v-deep(.mobile-container .impression-list .p-scrollpanel) {
    max-height: calc(100dvh - 215px) !important;
    height: calc(100vh - 215px) !important;
}
::v-deep(.mobile-container .channel-comp.card) {
    max-height: calc(100dvh - 200px) !important;
    height: calc(100vh - 200px) !important;
}

/* perf overrides */
::v-deep(.performance-title) {
    font-size: 1.2rem;
}

@media (max-width: 1400px) {
    ::v-deep(.chart-header-container) {
        display:block;
    }
    ::v-deep(.chart-header-container) > div.account-selector  {
        width: 100%;
    }
}

@media (min-width: 1400px) {
    .p-md-3 {
        width: 25% !important;
    }
}

::v-deep(.type-card  .min-quote .qmod-quote .display-name) {
    font-size: .8rem;
}
::v-deep(.type-card  .min-quote .qmod-quote .qmod-change-group) {
    font-size: .8rem;
}

::v-deep(.min-quote.performance-type > div.icon-container > span.material-icons-outlined) {
    font-size: 1.5rem;
}

::v-deep(.min-quote.performance-type > div > span.text){
    font-size: 1rem;
}

::v-deep(.min-quote.performance-type > div.icon-container){
    flex:1 0 40px;
}

::v-deep(.portfolio-header-container > div > div > .chart-header) {
    font-size: 1.4rem;
}

::v-deep(.home-portfolio-container > div.chart-container) {
    font-size: 1.4rem !important;
}




/* ::v-deep(.right-side-bar .p-scrollpanel-content) {
    padding: 0;
}  */



::v-deep(.right-side-bar .p-scrollpanel-wrapper) {
    overflow:visible;
}
.conduits-read-only{
    opacity: 0.3;
    pointer-events: none;
}
.group-profile {
    margin-top: 16px;
}

.p-grid {
    margin: 0px;
    padding: 0px;
}

.p-grid > :first-child {
    padding: 0px;
    
}
.p-grid > :nth-child(2) {
    padding: 0px 0px 0px 16px;
}
.p-grid > :last-child {
    padding: 0px 0px 0px 16px;
}

.send-email-button-container {
    position: relative;
}
.send-email-button-container .p-badge {
    position: absolute;
    top: -6px;
    left: -20px;
    padding: 6px 6px;
    font-size: 8px;
    line-height: 0.6;
    height: 16px;
    z-index: 1;
    background: #33CC99;
}
.send-email-button-container .p-badge:hover {
    cursor: pointer;
}


@media (min-width: 1400px) {
   .p-grid > :first-child {
        
        width: 20%;
    }
    .p-grid > :last-child.member-plugins {
        width: 25%;
    }
   
}

.private-message-container {
    display: flex;
    flex-direction: column;
    margin: 84px auto auto;
    width: 435px;
    text-align: center;
}

.finish-group-setup-message-container {
    display: flex;
    flex-direction: column;
    margin: 84px auto auto;
    width: 845px;
    align-items: center;
}
.finish-group-setup-message-container .p-button {
    justify-content: center;
    margin-top: 24px;
}

.lock-icon {
    font-size: 175px;
    color: #BFBFBF;
}

.message-text {
    color: #BFBFBF;
    font-size: 20px;
}

.green-button__primary {
    color: #FFFFFF;
    background: #33CC99;
    border-radius: 8px;
    border: 1px solid #33CC99;
}
.green-button__primary:hover {
    background: #00C083;
    border-color: #00C083;
}
.green-button__primary:focus {
    box-shadow: none;
    border-color: #00C083;
}

.group-profile .card .user-actions-container {
    align-items: flex-start;
}

.empty-group-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.empty-group-container .header-text {
    color: #323632;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
}

.action-button-blue  {
	border-radius: 16px;
	padding: 6px 16px;
	border-style: solid;
    border-width: 1px;
    margin-right: 4px;
    overflow: visible;
}
.action-button-blue img {
    width: 20px;
}
.action-button-blue:last-child  {
    margin-right: 0px;
}
.action-button-blue:active,
.action-button-blue:enabled:hover,
.action-button-blue.selected {
	background: #32364e;
    border-color: #32364e !important;
    color: #FFFFFF;
 }

 .settings-button, .send-email-button, .members-list-button {
    padding: 4px 8px;
 }

.action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4px;
}

.mobile-container {
    display: flex;
    flex-direction: column;
}

::v-deep(.p-badge-info) {
    background: #00C083 !important;
    transform: translate(40%,-40%) !important;
}

.invite-investors-button {
    width: 80%;
}

::v-deep(.status-editor-card) {
    padding: 16px 0px 0px;
    margin-bottom: 16px;
    display: flex;
}

::v-deep(.status-editor-card .status-form-container) {
    flex-grow: 1;
}

::v-deep(.posts-stats-dropdown) {
    margin-top: 2px;
    margin-right: 16px;
}

::v-deep(.posts-stats-dropdown-container) {
    justify-items: flex-end;
  display: grid;
}

::v-deep(.member-plugins .card) {
    margin-bottom: 16px;
}

::v-deep(.p-scrollpanel-content) {
    /* padding: 0 8px; */
}

::v-deep(.members-scroll .p-scrollpanel-content) {
    padding: 0 0;
}

.post-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    background: #32364e; /*#33cc99;*/
    right: -.5rem;
    bottom: 50vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
    
}
.lesson-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    background: #32364e; /*#33cc99;*/
    right: -.5rem;
    bottom: 50vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
    
}

.post-floating-button > img {
    width: 80%;
    padding-top: .4rem;
    margin-right: .3rem;
}
.lesson-floating-button > img {
    scale: 0.56;
    margin-right: .3rem;
}



.question-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    background: #32364e; /*#33cc99;*/
    right: -.5rem;
    bottom: 45vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
}
.question-floating-button > img {
    width: 80%;
    padding-right: .2rem;
}

.motion-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 2.7rem;
    height: 2rem;
    border-radius: 20%;
    color: #ffffff;
    background: #32364e; /*#33cc99;*/
    right: -.5rem;
    bottom: 40vh;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    z-index: 99;
}
.motion-floating-button > img {
    width: 80%;
    padding: 0;
    margin-right: .5rem;
}

.motion-floating-button > span {
    margin-right: 1rem;
}

.question-floating-button > span {
    margin-right: .2rem;
}

.post-floating-button > span {
    margin-right: .2rem;
}

@media (max-width: 760px) {

    .menu-floating-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 2rem;
        height: 3.5rem;
        border-radius: 20%;
        color: #ffffff;
        /* background: #32364e; #33cc99; */
        background:rgba(50, 54, 78, .5);
        left: -.5rem;
        top: 25rem; 
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
        z-index: 99;
        border-color:rgba(50, 54, 78, .0);
        /* opacity: 0.5; */
    }

    ::v-deep(.menu-floating-button > span.p-button-icon){
        margin-left: 0.5rem !important;
    }

    .post-floating-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 2.7rem;
        height: 2rem;
        border-radius: 20%;
        color: #ffffff;
        /* background: #32364e; #33cc99; */
        background:rgba(50, 54, 78, .5);
        right: -.5rem;
        bottom: 20vh;
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
        z-index: 99;
         /* border-color:#32364e; */
         /* opacity: 0.5; */
    }
    .post-floating-button > img {
        width: 60%;
        padding-right: .2rem;
    }

    .question-floating-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 2.7rem;
        height: 2rem;
        border-radius: 20%;
        color: #ffffff;
        /* background: #32364e; #33cc99; */
        background:rgba(50, 54, 78, .5);
        right: -.5rem;
        bottom: 15vh;
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
        z-index: 99;
        /* opacity: 0.5; */
    }
    .question-floating-button > img {
        width: 60%;
        padding-right: .2rem;
    }

    .motion-floating-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 2.7rem;
        height: 2rem;
        border-radius: 20%;
        color: #ffffff;
        /* background: #32364e; #33cc99; */
        background:rgba(50, 54, 78, .5);
        right: -.5rem;
        bottom: 10vh;
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
        z-index: 99;
        /* opacity: 0.5; */
    }
    .motion-floating-button > img {
        width: 60%;
        padding-right: .2rem;
    }

    .motion-floating-button > span {
        margin-right: .2rem;
    }

    .question-floating-button > span {
        margin-right: .2rem;
    }

    .post-floating-button > span {
        margin-right: .2rem;
    }
}

::v-deep(.footer-container){
    position:unset;
    padding:unset;
}
::v-deep(.chat-messenger-container){
    padding:unset;
}

.open-side-menu-modal-button {
    border-radius: 50%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364e !important;
    height: 30px !important;
}
::v-deep(.open-side-menu-modal-button .p-button-icon) {
    font-size: 1.5rem !important;
}
.open-side-menu-modal-button:active,
.open-side-menu-modal-button:hover {
    background: #32364e !important;
    color: white !important;
}

.open-side-menu-modal-button-container {
    position: relative;
}

.open-side-menu-modal-button-badge {
     display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
    left: 1rem;
    top: 25rem;
    min-width: 18px;
    width: 18px;
    height: 18px;
    font-size: 10px;
    line-height: 1.3rem;
    background: #e63e3e;
     z-index: 100;


     
        
}

.feed-item-icon{
    font-size: 16px;
    margin-right: 4px;
}
.selected-portfolio-label,
.selected-channel-label {
    font-size: 16px;
    margin: auto !important;   /* todo */
}
.selected-feed-label {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto !important;   /* todo */
}

::v-deep(.cash-accounts-container .p-grid) {
    margin: 0 !important; 
}
::v-deep(.cash-accounts-container .tab-content) {
    padding-top: 0 !important; 
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    height: fit-content;
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

@media (max-width: 760px) {
.card {
    border-radius: 0px;
}
.group-profile {
    margin-top: 0px;
    padding: 8px !important;
    margin-bottom: 4px;
}

.private-message-container,
.finish-group-setup-message-container {
    width: 100%;
    text-align: center;
    padding: 0px 16px;
}

.lock-icon {
    font-size: 135px;
}
.message-text {
    font-size: 14px;
}

.channel-comp {
    padding: 8px;
    margin-bottom: calc(30.7px + 1.8em);
}
}
</style>