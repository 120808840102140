<template>
    <div class="main-container">
        <div ref="courseItem" :class="courseItemClass" @click="onCourseClick">
        
            <div class="expend-icon-container" @click.stop="onCourseExpendClick()" v-if="showModules">
                <i :class='"pi " + (showFeeds ? "pi-angle-down" : "pi-angle-right")'></i>
            </div>

            <span class="material-symbols-outlined p-button-icon course-item-icon">school</span>
            <span class="course-item-title">{{ displayCourseTitle() }}</span>
            <template v-if="course.hasCohorts">
                <template v-if="course.isMember">
                    <i :class='"pi pi-check-circle"'  :style="'color:#33cc99'" v-tooltip.bottom="'Registered'"></i>
            
                </template>
                <template v-else>
                    <i :class='"pi " + (course?.open ? "pi-circle-fill" : "pi-ban")' :style="(course?.open ? 'color:#33cc99': '')" v-tooltip.bottom="(course?.open ? 'Admissions open.' : 'Admissions closed.')"></i>
                </template>
            </template>
            
            <template v-if="isGroupOwnerOrAdmin && course">
            <Button class="action-button p-button-text" style="margin-left:auto;" icon="pi pi-ellipsis-v" @click="toggleCourseMenu($event)" aria-haspopup="true" aria-controls="overlay_menu" />
            <Menu ref="courseMenu" id="overlay_menu" :model="courseMenuOptions" :popup="true" />
            </template>

            
            <!-- <i v-if="isMobile()" :class="(showAsActive ? '' : 'hidden ') + 'pi menu-active pi-check'" /> -->

        </div>

        <div v-if="showFeeds && showModules" class="course-feed-container">
            <DataTable v-if="feeds && feeds.length > 0" scrollable scrollHeight="flex"
                @rowReorder="onRowReorder" :value="feeds" class="p-datatable-sm">
                <Column v-if="isGroupOwnerOrAdmin && !isMobile()" rowReorder style="width: 1rem; align-items: unset; margin-top: 12px;" />
                <Column style="width: 100%;">
                    <template #body="feed">
                        <GroupCourseModuleListItem :key='feed.data.groupFeedId' @module-clicked="onCourseModuleClick" :feed="feed.data"
                            :isGroupOwnerOrAdmin="isGroupOwnerOrAdmin" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <ConfirmModal :headerText="'Are you sure you want to delete course and all modules: ' + displayCourseTitle() + '?'" confirmButtonLabel='Yes'
        @confirmed="deleteConfirmed" ref='deleteCourseModal' />

    <CohortModal ref="cohortModal" :selectedCourse="course" @cohorts-changed="onCohortsChanged"/>
    <CloneCourseModal ref="cloneCourseModal"/>
</template>

<script>
import ConfirmModal from '../modal/ConfirmModal.vue';
import CohortModal from './modal/CohortModal.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Menu from 'primevue/menu';
import GroupCourseModuleListItem from './GroupCourseModuleListItem.vue';
import CloneCourseModal from './modal/CloneCourseModal.vue';
import GroupService from '../../service/GroupService';
import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'GroupCourseListItem',
    emits: ['course-clicked', 'module-clicked'],
    props: {
        course: {
            type: Object
        },
        isGroupOwnerOrAdmin: { 
            type: Boolean 
        },
        showModules: {
            type: Boolean,
            default: true
        },
    },
    components: {
        ConfirmModal,
        DataTable,
        GroupCourseModuleListItem,
        Column,
        Menu,
        CohortModal,
        CloneCourseModal
    },

    data() {
        return {
            newPostsCount: 0,

            thisSelected: false,
            initLoading: false,
            showFeeds: false,
            feeds: [],
            
        };
    },


    mounted() {
        //console.log("mounted GroupCourseListItem", this.course);
        this.thisSelected = this.isItemSelected(this.selectedGroupConduit);
        this.showFeeds = this.isSubItemSelected(this.selectedGroupConduit);
        if(this.showFeeds){
            this.loadCourseFeeds();
        }

        EventBus.on('hide-course-module', (course) => {
            if(course.courseId != this.course.courseId){
                this.showFeeds = false;
            }
        });
    },

    computed: {
        ...mapState(['selectedGroup']),
        ...mapState(['selectedGroupConduit']),

        courseMenuOptions() {
            let menu= [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editCourse();
                    }
                },
                {
                    label: 'Add Module',
                    icon: 'pi pi-plus',
                    command: ()=> {
                        this.createModule();
                    }
                },
                {
                    label: 'Manage Cohorts',
                    icon: 'pi pi-users',
                    command: ()=>{
                        this.manageCohorts();
                    }
                },
              
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: ()=> {
                        this.deleteCourse();
                    }
                },

            ];

            if( this.selectedGroup.systemGroup){
                menu.push(  {
                    label: 'Clone',
                    icon: 'pi pi-clone',
                    command: ()=>{
                        this.cloneCourse();
                    }
                });
            }

            return menu;
        },

        courseItemClass() {
            let baseClass = 'course-item';
            if (this.thisSelected) {
                return `${baseClass} selected`;
            } else {
                return `${baseClass}`;
            }
        },
        showAsActive() {
            if (this.thisSelected) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            this.thisSelected = this.isItemSelected(newConduit);
            // this.showFeeds = this.isSubItemSelected(this.selectedGroupConduit);
            if(this.showFeeds && this.feeds.length == 0) {
                this.loadCourseFeeds();
            }
        },
    },

    methods: {
        onCohortsChanged() {
            EventBus.emit('refresh-courses-list');
        },
        manageCohorts() {
            this.$refs.cohortModal.open();
        },

        cloneCourse() {
            this.$refs.cloneCourseModal.open(this.course);
        },

        toggleCourseMenu(event) {
            event.stopPropagation();
            this.$refs.courseMenu.toggle(event);
        },

        isSubItemSelected(item) {
            //console.log("isSubItemSelected courseFeed",item);
            return item 
                && item.type == "courseFeed"
                && item.obj.courseId == this.course.courseId;
        },

        onCourseModuleClick(data) {
            this.$emit('module-clicked', data);
        },

        onRowReorder(event) {
            this.feeds = event.value;
            const groupFeedIds = this.feeds.map(feed => feed.groupFeedId);
            //console.log("onRowReorder", event.value, groupFeedIds);
            GroupService.updateGroupFeedOrdering(this.selectedGroup.groupId, groupFeedIds).then(resp => {
                if (resp.data.status == "success") {
                    //console.log("success", resp);
                }
            });
        },

        onCourseClick() {
            // EventBus.emit("show-course-description", this.course);
            EventBus.emit("select-group-course", this.course);
            this.onCourseExpendClick(true);
        },

        onCourseExpendClick(expend) {
            EventBus.emit('hide-course-module', this.course);
            this.showFeeds = expend == true ? true : !this.showFeeds;
            // console.log("onCourseExpendClick", this.showFeeds, this.showModules);

            if(this.showFeeds) {
                this.loadCourseFeeds();
            }
        },

        loadCourseFeeds() {
            const groupId = this.selectedGroup.groupId;
            GroupService.getGroupCourseFeeds(groupId, this.course.courseId).then(resp => {
                if (resp.data.status == 'success') {
                    if(resp?.data?.groupFeeds?.length > 0 && this.course.courseId == resp.data.groupFeeds[0].courseId){
                        resp.data.groupFeeds.map( feed => {
                            feed["groupId"] = groupId;
                            feed["courseName"] = this.course.name;
                        });
                        this.feeds = resp.data.groupFeeds;
                    }
                    //console.log("getGroupCourseFeeds", this.feeds);
                    // EventBus.emit('initialize-course');
                }
            });
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item) {
            return item && this.course
                && item.groupId == this.course.groupId
                && item.type == "course"
                && item.obj.courseId == this.course.courseId;
        },

        editCourse() {
            EventBus.emit("open-create-edit-group-course-modal", this.course);
        },


        deleteCourse() {
           
            this.$refs.deleteCourseModal.open();
        },

        deleteConfirmed() {
            GroupService.deleteGroupCourse(this.course.groupId, this.course.courseId).then(resp => {// eslint-disable-line
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'The course was deleted.', detail: '', life: 3000, group: 'tc' });

                    EventBus.emit('group-course-deleted');
                } else {
                    this.$toast.add({ severity: 'error', summary: 'The course was not deleted.', detail: '', life: 3000, group: 'tc' });
                }

            });
        },

        displayCourseTitle() {
            return this.course.name;
        },

        createModule(){
            EventBus.emit("open-create-edit-group-feed-modal",{ course: this.course});
        },

    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text {
    background: white !important;
    color: #32364e !important;
}

.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.main-container {
    width: 100%;
}

.course-feed-container {
    margin-left: 16px;
}

.course-item {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}

.course-item:hover {
    background: #f2f4fa;
    cursor: pointer;
}

.course-item.selected {
    background: #f2f4fa;
    cursor: unset;
}

.course-item-icon {
    margin-right: 4px;
    font-size: 14px;
}

.course-item-title {
    color: #32364e;
    font-size: 14px;
    display: inline-block;
    margin-right: 4px;
    flex-grow: 1;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
}

.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;

}
.expend-icon-container {
    display:flex;
    align-items: center;
    height: 25px;
    cursor: pointer;
    width: fit-content;
    padding-right: 4px;
}

::v-deep(.p-datatable-thead) {
    display: none;
}

::v-deep(.p-datatable-tbody tr td) {
    padding: 0 !important;
    border: unset !important;
}

::v-deep(.p-datatable-tbody tr td) {
    margin-right: 4px;
    flex-grow: 0 !important;
}

::v-deep(.p-datatable-tbody tr td:last-child) {
    margin-right: 0;
    flex-grow: 1;
}

::v-deep(.p-datatable-dragpoint-bottom) {
    border-bottom: 2px solid #32364e;
}

::v-deep(.p-datatable-wrapper) {
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar) {
    width: 10px;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-track) {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf;
    border-radius: 10px;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf;
    border-radius: 10px;
}
</style>