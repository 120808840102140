<template>
    <div ref="channelItem" :class="channelItemClass" @click="onChannelClick()"  v-tooltip.bottom="sideMenuWidth < 300 ? getCorrectTime() : ''">
        <span class="material-symbols-outlined channel-item-icon" v-tooltip.bottom="channelTypeToolTip">forum</span>

        
        <div class="channel-item-name-container">
            <div class="channel-item-title-container">
                <span class="channel-title" v-tooltip.bottom="channelTypeToolTip">{{ displayChannelTitle() }}
                    <span class="time-posted" v-if="sideMenuWidth >= 300">{{ getCorrectTime() != '' ? '| ' + getCorrectTime() : '' }}</span>
                </span>
                
            </div>
           
        </div>

    
        <div class="channel-item-counts-container" >
            <span class="channel-mention dot" v-if="mentionCount" v-tooltip.bottom="mentionCount + (mentionCount > 1 ? ' mentions' : ' mention')"></span>
            <span class="channel-unread dot" v-if="unreadCount" v-tooltip.bottom="unreadCount + ' unread'"></span>
            
        </div>

       <Button v-if="canDeleteChannel()" icon='pi pi-trash' class="action-button p-button-text" @click='deleteChannel($event)' v-tooltip.bottom="'Delete'"/>
        

        <!-- <i  v-if="isMobile()" :class="(showAsActive ? '' : 'hidden ') + 'pi menu-active pi-check'" style="display: inline-block;"/> -->
    
    </div>
    <ConfirmModal :headerText="'Delete channel: ' + displayChannelTitle()+'?'" confirmButtonLabel='Yes' @confirmed="deleteConfirmed()" ref='deleteChannelModal' />
</template>

<script>


import TimeUtils from '../../utilities/TimeUtils';
import ChatService from '../../service/ChatService';
import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import {mapState} from 'vuex';
import ConfirmModal from '../modal/ConfirmModal.vue';
import { hasPremiumGroupSubscription, isINVRSGroup} from '../groups/';

export default {
    name: 'ChannelListItem',
    props: ['channel', 'sideMenuWidth', "isGroupOwnerOrAdmin", "selectedGroup"],
    emits: ['channel-clicked'],
    components: {
      ConfirmModal
    },

    data() {
        return {
           
            thisSelected: false,
        };
    },

  
    mounted() {
        const selectedGroupConduit = this.$store.state.selectedGroupConduit;
        this.thisSelected = this.isItemSelected(selectedGroupConduit);
    },

    computed: {
        ...mapState(['selectedGroupConduit']),
       
        unreadCount() {
            // Adding this check, because this indicates you're entering a channel that you're a member of and it would only makes sense to see the read count that way

            if (this.channel.state) {
                if( this.channel.state.unreadCount) {
                    if( this.channel.state.unreadCount == 0 ) {
                        return null;
                    }
                    else if( this.channel.state.unreadCount > 10 ) {
                        return "10+";
                    }
                    else {
                        return this.channel.state.unreadCount;
                    }
                    
                }
                else {
                    return 0;
                }
            }
            else {
                return null;
            }
        },

        mentionCount() {
            if (this.channel['mentionCount']) {
                if (this.channel['mentionCount'] > 10) {
                    return '10+';
                } else {
                    return this.channel['mentionCount'];
                }
                
            }
            else {
                //console.log("no mention count")
                return 0;
            }
        },

         channelItemClass() {
            let baseClass = (this.isGroupOwnerOrAdmin ? 'channel-item-owner' : 'channel-item');
            const channelTypeClass = this.channel.data.isPrivate ? 'private' : 'public';
            if( this.thisSelected ) {
                return `${baseClass} ${channelTypeClass} selected`;
            }
            else {
                return `${baseClass} ${channelTypeClass}`;
            }
        },
        showAsActive() {
            if( this.thisSelected ) {
                return true;
            }
            else {
                return false;
            }
        },

        channelTypeToolTip() {
            return this.channel.data.isPrivate ? 'Private Channel' : 'Public Channel';
        }
    },

    watch:{
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            // console.log("channel list item detected group conduit changed ", newConduit, newConduit.obj.id, this.channel.id);
            this.thisSelected = this.isItemSelected(newConduit);
        },
    },

    methods:{
        
        canDeleteChannel() {
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId)) && (this.isGroupOwnerOrAdmin);
        },
        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item){
            return this.channel 
                && item.groupId == this.channel.data.groupId 
                && item.type == "channel" 
                && item.obj.id == this.channel.id;
        },

        // channel data that has property 'state' will come from your own channels that you belong to, meaning that you're already a member

        onChannelClick() {
            let channelData = this.channel;
            if( this.$store.state.chatMode == 'groups') {
                this.$emit('channel-clicked', {'channelTitle': this.displayChannelTitle(), 'channelData': channelData, 'isMember': this.channel.state !== undefined});
            }
        },
        deleteChannel(e) {
            e.stopPropagation();

            this.$refs.deleteChannelModal.open();

           
           
        },

        deleteConfirmed() {
             // console.log("blockDmChannel",this.dmUser);
           ChatService.deleteChannel(this.channel.data.chatChannelId).then(resp=> {// eslint-disable-line
                  
                this.$toast.add({severity:'success', summary: 'The channel was deleted.', detail:'', life: 3000, group: 'tc'});
                this.$store.commit('MARK_CHANNELS_STALE');
                
                EventBus.emit('group-channel-deleted', this.channel);
                
            });
        },

        getCorrectTime() {
            if(this.channel.state && this.channel.state.last_message_at){
                return TimeUtils.getTimeFromNow(new Date(this.channel.state.last_message_at).toISOString());
            } else {
                return '';
            }
        },

        displayChannelFullName() {
            return this.dmUser.fullName;
        },

        displayChannelTitle() {
            //console.log(JSON.stringify(this.channel))
            let title = '';
            let isDiscussion = null;
            let name = '';


            if (this.channel.data) {
                isDiscussion = this.channel.data.isDiscussion;
                name = this.channel.data.channelName;
            } else {
                isDiscussion = this.channel.discussion;
                name = this.channel.name;
            }

            if (!isDiscussion) {
                title = name;
            } else {
                if( Array.isArray(this.channel.state.members)) {
                    
                    const members = this.channel.state.members.slice(0, 10);

                    title = (members.reduce((total, member) => {
                        total.push(member.name);

                        return total;
                    }, [])).join(', ');
                }
                else {
                    title = name;
                }

            }

            return title;
        },

       
       
    }
}
</script>

<style>
.hidden {
    visibility: hidden;
}

.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text  {
    background: white !important;
    color: #32364e !important;
}
.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}
.channel-item {
    width: 100%;
    display: flex;
    align-items: center;
     padding: 8px 4px;
   
    margin: 2px 0px;
    border-radius: 8px;
}
.channel-item-owner {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}
.channel-item-dm {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px !important;
    border-bottom: 1px solid #f2f4fa;
}
.channel-item:hover, .channel-item-owner:hover {
    background: #f2f4fa;
    cursor: pointer;
}
.channel-item.selected ,  .channel-item-owner.selected{
    background: #f2f4fa;
    cursor: unset;
}
/* .channel-item:hover .channel-title {
    color: #FFFFFF;
} */

.channel-item-icon {
    margin-right: 4px;
    font-size: 14px;
}
.private .channel-item-icon {
    font-variation-settings: 
        'FILL' 1
}

.p-avatar {
    min-width: 48px;
}

.channel-unread {
    background-color: #e63e3e;
}
.channel-mention {
    background-color: #693bf5;
}

.channel-unread,
.channel-mention {
    margin: 2px;
    height: 100%;
    color: #fff;
    padding: 4px 8px;
    border-radius: 30px;
    white-space: nowrap;
}

.dot {
    width: 8px !important;
    height: 8px !important;
    padding: unset !important;
}
/* .channel-item:hover .channel-unread {
    
} */

.channel-title {
    /* font-weight: bold; */
    color: #32364e;
    font-size: 14px;
    /* white-space: nowrap;
    text-overflow: ellipsis; 
    overflow: hidden;*/
    display: inline-block;
    margin-right: 4px;
}

.channel-item .channel-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.channel-item .time-posted {
    white-space: nowrap;
    font-size: 14px;
}

.channel-item .more-options {
    margin-left: auto;
}

.channel-item-owner .channel-item-name-container,
.channel-item .channel-item-name-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 0;
}
.channel-item-dm .channel-item-name-container {
    max-width: 70%;
}

.channel-item-dm-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.channel-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
}

.channel-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover{
    border-color: #FFFFFF !important;
}

.block-btn{
    /* margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px; */
    border-radius: 16px;
    font-size: 0.8rem;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
}

.action-button.active-channel {
   margin-right: 1px;
}
.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;
   
}

</style>