<template>
    <Dialog header="Members" v-model:visible="display" :modal="true" :closable="true" class="group-members-modal" @hide="onClose()">
        <CohortMemberSearch context="GROUP" ref="curMemberSearch" :selectedCourse="selectedCourse" :selectedCohort="selectedCohort"/>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import CohortMemberSearch from '../CohortMemberSearch.vue';

//import EventBus from '../../../event-bus';

export default {
    name: 'GroupMembersModal',
    components: {
        Dialog,
        CohortMemberSearch,
    },
   
    data() {
        return {
            display: false,
            selectedCourse: null,
            selectedCohort:null,
        };
    },

    methods: {
        open(course, cohort) {
            this.selectedCourse = course;
            this.selectedCohort = cohort;
            this.display = true;
            //EventBus.emit('refresh-group-members-list');
        },

        onClose() {
            this.display = false;
            this.selectedCourse = null;
            this.selectedCohort = null;
        }
    },
};
</script>

<style>
.group-members-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    width: 600px;
    height: 610px;
    border-radius: 16px;
}

.group-members-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.group-members-modal .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    padding: 0px;
    height: 100%;
}

@media (max-width: 760px) {
    .group-members-modal {
        height: 100%;
        max-height: 100%;
    }

    .group-members-modal .p-dialog-header {
        border-radius: 0;
    }

    .group-members-modal .p-dialog-content {
        padding-top: 16px! important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
</style>