<template>
    <div class="main-container">
        <div ref="feedItem" class="feed-item" :class="feedItemClass" @click="onModuleClick">

            <div class="expend-icon-container" @click.stop="onModuleExpendClick">
                <i :class='"pi " + (showOutline ? "pi-angle-down" : "pi-angle-right")'></i>
            </div>

            <span class="material-symbols-outlined p-button-icon feed-item-icon">{{(showOutline ? 'menu_book':'book_2')}}</span>
            <span class="feed-item-title" v-tooltip.bottom="moduleDescription">{{ displayFeedTitle() }}</span>

            <div class="item-count-container">
                <span class="dot red" v-if="getFeedNotificationCount()" v-tooltip.bottom="getFeedNotificationCount()"></span>
            </div>
            <template v-if="isGroupOwnerOrAdmin">
                <Button class="action-button p-button-text" style="margin-left:auto;" icon="pi pi-ellipsis-v" @click="toggleModuleMenu($event)" aria-haspopup="true" aria-controls="overlay_menu" />
                <Menu ref="moduleMenu" id="overlay_menu" :model="moduleMenuOptions" :popup="true" />
            </template>
            

        </div>

        <template v-if="showOutline">

            <DataTable scrollable scrollHeight="flex"
                @rowReorder="onRowReorder" :value="moduleOutlineItems" class="p-datatable-sm">
                <Column v-if="isGroupOwnerOrAdmin && !isMobile()" rowReorder style="width: 1rem; align-items: unset; margin-top: 12px;" />
                <Column style="width: 100%;" >
                    <template #body="item">
                        <div class="module-outline-item" @click="onModuleOutlineClick(item.data)">
                        <img :src="getModuleOutlineImg(item.data)" :class="getModuleOutlineImgClass(item.data)">
                        <span :class="(item.question && alreadyAnsweredPoll(item.data)) || (!item.question && isAlreadyReadPost(item.data)) ? 'dot green' : 'dot border'" 
                            v-tooltip.bottom="getFeedNotificationCount()"></span>
                        <span>{{ item.data.outlineTitle }}</span>
                        </div>
                    </template>
                </Column>
            </DataTable>

        </template>
    </div>

    <ConfirmModal :headerText="'Delete feed: ' + displayFeedTitle() + '?'" confirmButtonLabel='Yes'
        @confirmed="deleteConfirmed" ref='deleteFeedModal' />
</template>

<script>

import GroupService from '../../service/GroupService';
import FeedService from '../../service/FeedService';
import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import { mapState } from 'vuex';
import ConfirmModal from '../modal/ConfirmModal.vue';
// import FeedService from '../../service/FeedService';

import { YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL } from '../../common/constants';
import { alreadyAnsweredPoll } from '../feed/index.js';
import PostUtils from '../../utilities/PostUtils';
import Menu from 'primevue/menu';

import DataTable from 'primevue/datatable';

import Column from 'primevue/column';

export default {
    name: 'GroupCourseModuleListItem',
    emits: ['module-clicked', 'remove-feed-notifications', ],
    props: {
        feed: {
            type: Object,
            required: true
        },
        isGroupOwnerOrAdmin: {
            type: Boolean,
            required: true
        },
    },
    components: {
        ConfirmModal,
        Menu,
        DataTable,
        Column,
    },

    data() {
        return {
            alreadyAnsweredPoll,

            newPostsCount: 0,
            thisSelected: false,
            //moduleOutlineItems: null,
            showModuleOutline: false,
            moduleMenuOptions: [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editFeed();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: ()=> {
                        this.deleteFeed();
                    }
                },

            ],
        };
    },

    mounted() {
        
        this.thisSelected = this.isItemSelected(this.selectedGroupConduit);
       
        
        EventBus.on('hide-course-module-outline', this.onHideCourseModuleOutline);

        

        
    },

    unmounted() {
        
         
         EventBus.off('hide-course-module-outline', this.onHideCourseModuleOutline);
        
    },

    computed: {
        ...mapState(['selectedGroup']),
        ...mapState(['selectedGroupConduit']),
        ...mapState(['feedNotificationCounts']),
        ...mapState(['courseModuleLessons']),
        ...mapState(['openCourseModules']),

        moduleOutlineItems() {
            if(this.feed) {
                return this.courseModuleLessons[this.feed.groupFeedId];
            }
            else {
                return [];
            }
        },

        feedItemClass() {
            return this.thisSelected ? 'selected' : '';
        },

        showAsActive() {
            if (this.thisSelected) {
                return true;
            }
            else {
                return false;
            }
        },
        feedTypeToolTip() {
            if (this.feed.privateFeed) {
                return 'Private Feed';
            } else {
                return 'Public Feed';
            }
        },

        moduleDescription() {
            if( this.feed.description != '') {
                return this.feed.description;
            }
            else {
                return this.feed.name;
            }
        },

        showOutline() {
            return this.openCourseModules[this.feed.groupFeedId] === true;
        }


    },

    watch: {
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            // console.log("feed list item detected group conduit changed ", newConduit);
            this.thisSelected = this.isItemSelected(newConduit);
            if (this.thisSelected) {
                this.$emit('remove-feed-notifications', this.feed);
            } 
            // else {
            //     this.showModuleOutline = false;
            // }
        },

       
    },

    methods: {

       

         onHideCourseModuleOutline(feed) {
            if(feed.groupFeedId != this.feed.groupFeedId){
                //console.log("received hide module outline event");
                //this.showModuleOutline = false;
                
                this.$store.commit("REMOVE_OPEN_COURSE_MODULE", this.feed.groupFeedId);
            
            }
        },
      


         onRowReorder(event) {
            let outlineItems = event.value;
            const postIds = outlineItems.map(post => post.foreign_id);
           this.$store.commit("SET_COURSE_MODULE_LESSONS", {feedId: this.feed.groupFeedId, lessons:outlineItems});
            //console.log("onRowReorder", event.value, groupFeedIds);
            FeedService.updateModuleLessonOrdering(this.selectedGroup.groupId, this.feed.courseId, this.feed.groupFeedId, postIds).then(resp => {
                if (resp.data.status == "success") {
                    this.$emit('module-clicked', this.feed);
                }
            });
        },

        toggleModuleMenu(event) {
            event.stopPropagation();
            this.$refs.moduleMenu.toggle(event);
        },

        isAlreadyReadPost(activity){
            return PostUtils.isAlreadyRead(activity);
        },

        getModuleOutlineImgClass(item) {
            //console.log("item", item)
            if( item.task) {
                return "module-outline-item-task-icon";
            }
            else if( item.has_note == 1 || item.type == 'status') {
                return "module-outline-item-lesson-icon";
            }
            else {
                return "module-outline-item-icon";
            }
        },

        getModuleOutlineImg(item) {
            //console.log("item: ", item)
            if(item.question && !item.poll){
                return "/assets/images/gq.png";
            }
            else if( item.task) {
                return "/assets/images/task.png";
            }
            else {
                switch (item.poll?.pollTypeId) {
                    case MULTIPLE_CHOICE_POLL.pollTypeId:
                    case MULTIPLE_SELECT_POLL.pollTypeId:
                        return "/assets/images/mc.png";
                    case YESNO_POLL.pollTypeId:
                        return "/assets/images/poll.png";
                    default:
                        return "/assets/images/lesson.png";
                }
            }
        },

        getFeedNotificationCount() {
            if (this.feed && !this.selectedGroup.educational) {
                const groupNotifications = this.feedNotificationCounts?.unreadGroupNotifications[this.feed.groupId]?.groupFeedNotifications;
                if (groupNotifications) {
                    const groupFeedNotifications = groupNotifications.find(feed => feed.feedId == this.feed.groupFeedId);
                    const count = groupFeedNotifications?.feedCount ? groupFeedNotifications.feedCount : 0;

                    if (count == 0) {
                        return 0;
                    }
                    if (count == 1) {
                        return "1 post";
                    }
                    if (count > 10) {
                        return "10+ posts";
                    }

                    return count + " posts";
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item) {
            return item && this.feed
                && item.groupId == this.feed.groupId
                && (item.type == "feed" || item.type == "courseFeed")
                && item.obj.groupFeedId == this.feed.groupFeedId;
        },

        onModuleOutlineClick(item) {
            const obj = {...this.feed, closeModal:true, openPost:item};
            this.$emit('module-clicked', obj);
        },

        onModuleClick() {
            this.onModuleExpendClick(true, true);
        },

        onModuleExpendClick(expand, closeModal) {
            EventBus.emit('hide-course-module-outline', this.feed);
            let expanded = this.openCourseModules[this.feed.groupFeedId];
           let showModuleOutline = expand === true ? true : !expanded;

            if (showModuleOutline){
                const feed = {...this.feed, closeModal:closeModal};
                this.$emit('module-clicked', feed);
                 
                this.$store.commit("ADD_OPEN_COURSE_MODULE", this.feed.groupFeedId);
                
                
            
            }
            else {
                this.$store.commit("REMOVE_OPEN_COURSE_MODULE", this.feed.groupFeedId);
            }
            
        },

        editFeed() {

            EventBus.emit("open-create-edit-group-feed-modal", { feed: this.feed });
        },


        deleteFeed() {
            

            this.$refs.deleteFeedModal.open();
        },

        deleteConfirmed() {
            GroupService.deleteGroupFeed(this.feed.groupId, this.feed.groupFeedId).then(resp => {// eslint-disable-line
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'The feed was deleted.', detail: '', life: 3000, group: 'tc' });

                    if (this.feed?.courseId) {
                        EventBus.emit('group-course-feed-deleted');
                    } else {
                        EventBus.emit('group-feed-deleted');
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: 'The feed was not deleted.', detail: '', life: 3000, group: 'tc' });
                }

            });
        },

        displayFeedTitle() {
            return this.feed.name;
        },

    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text {
    background: white !important;
    color: #32364e !important;
}

.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.main-container {
    width: 100%;
}

.main-container ::v-deep(.p-scrollpanel-content) {
    padding: 0;
    width: 100%;
}

.module-outline-item,
.feed-item {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}

.module-outline-item:hover,
.feed-item:hover {
    background: #f2f4fa;
    cursor: pointer;
}

.module-outline-item-icon {
    height: 25px;
    scale: 1.3;
}
.module-outline-item-task-icon {
    height: 25px;
}

.module-outline-item-lesson-icon {
    height: 20px;
    width:25px;
    
}


.feed-item.selected {
    background: #f2f4fa;
    cursor: unset;
}

.feed-item-icon {
    margin-right: 4px;
    font-size: 14px;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
}

.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;

}

.item-count-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.dot {
    min-width: 8px !important;
    min-height: 8px !important;
    width: 8px !important;
    height: 8px !important;
    padding: unset !important;
    background-color: #fff;
    margin: 4px;
    color: #fff;
    padding: 4px 8px;
    border-radius: 30px;
    white-space: nowrap;
}
.border {
    border: 1px solid #32364E;
}
.red {
    background-color: #e63e3e;
}
.green {
    background-color: #33CC99;
}

.expend-icon-container {
    display: flex;
    align-items: center;
    height: 25px;
    cursor: pointer;
    width: fit-content;
    padding-right: 4px;
}
</style>