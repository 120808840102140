<template>
    <Dialog header="Join Group" v-model:visible="display" :modal="true" class='group-subs-modal' >
        
         
             <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if="!loading">
                
                <span class="settings-description">
                   <p v-if="errorMessage == null">To join this group you must purchase a group membership subscription.</p>
                   <p v-else>{{errorMessage}}</p>
                       
                    <div  v-if="errorMessage == null" class="settings-section"  >
                        <h4 class="settings-title">Subscription plans:</h4>
                        <div style="display:flex;flex-direction:column;">
                            
                            <div  v-if="monthlyPrice != null" style="display:flex;align-items: center;" class="settings-label">
                                <span >Monthly Subscription:</span>
                                <span style="margin-left:auto;margin-right:auto;">{{monthlyPrice}} {{currency}}</span>
                                <Button label="Subscribe" class='p-button-raised p-button-rounded subscribe-button' @click="subscribe(monthlyProductId)" :loading="subscribing"/>
                            </div>
                            <div  v-if="yearlyPrice != null" style="display:flex;align-items: center;" class="settings-label">
                                <span >Yearly Subscription:</span>
                                <span style="margin-left:auto;margin-right:auto;">{{yearlyPrice}}  {{currency}}</span>
                                <Button label="Subscribe" class='p-button-raised p-button-rounded subscribe-button' @click="subscribe(yearlyProductId)" :loading="subscribing"/>
                            </div>
                           
                            
                        </div>
                    </div>
                </span>
            </div>
        

		<template #footer>
			<Button label="Cancel" class='p-button-raised p-button-rounded view-button' @click="close()"/>
            
           
		</template>
	</Dialog>
</template>

<script>


import Dialog from 'primevue/dialog';

import PaymentService from '../../../service/PaymentService';
import GroupService from '../../../service/GroupService';



import LoadingSpinner from '../../common/LoadingSpinner.vue';
import FormattingUtils from '../../../utilities/FormattingUtils';
// import Textarea from 'primevue/textarea';


export default {
    name: 'SubscribeToGroupModal',
 
    components: {
        Dialog, LoadingSpinner,
    },

    
    data() {
		return {
           formatter: new FormattingUtils(),

			display: false,
            loading: false,
            monthlyPrice: null,
            yearlyPrice: null,
            currency: "USD",
            
            group: null,
           
            subscribing: false,
            monthlyProductId: null,
            yearlyProductId: null,
            errorMessage: null,
            returnParams: null,
		}
    },

   

   

    methods: {
        

        open(group, returnParams) {
            this.display = true;
            this.group = group;
            this.loading = true;
            this.dirty = false;
            this.subscribing = false;
            this.monthlyProductId = null;
            this.yearlyProductId = null;
            this.errorMessage = null;
            this.returnParams = returnParams;
           
            GroupService.canJoinGroup(this.group.groupId).then(resp => {
               
                if( resp.data.status == 'success'  ){
                    if(resp.data.membershipOpen){
                        PaymentService.getGroupSubscriptions(this.group.groupId).then( resp => {
                            
                            console.log("got group subs resp", resp.data);
                            if( resp.data.status == 'success'){
                                console.log("got group subs", resp.data.subscriptions);
                                if( resp.data.subscriptions && resp.data.subscriptions.length > 0 ){
                                    for( var i =0 ; i< resp.data.subscriptions.length; ++i){
                                        let sub = resp.data.subscriptions[i];
                                        if( sub.periodTypeId == 1){
                                            
                                            this.yearlyPrice= this.formatter.format(sub.price, '$0.00');
                                            this.yearlyProductId = sub.productId;
                                        }
                                        else {
                                            
                                            this.monthlyPrice =  this.formatter.format(sub.price, '$0.00');
                                            this.monthlyProductId = sub.productId;
                                        }
                                    }
                                    this.currency = (resp.data.subscriptions[0].currencyId == 1 ? 'CAD' : 'USD');
                                   
                                   
                                }
                                else {
                                    this.errorMessage = 'A subscription is required to join this group but the group owner has not yet setup their subscriptions.  Please try again later.'
                                }
                                this.loading = false;
                            }
                            else {
                                this.loading = false;
                                this.errorMessage = 'A subscription is required to join this group but the group owner has not yet setup their subscriptions.  Please try again later.'
                            }
                        })
                    }
                    else {
                        this.loading = false;
                        if( resp.data.reason == 'Membership Full'){
                            this.errorMessage = "This group's membership is currently at capacity and cannot accept new members at this time."
                        }
                        else {
                            this.errorMessage = "This group is not accepting new members at this time."
                        }
                    }
                    
                }
                else {
                    this.loading = false;
                    this.errorMessage = "There was an error checking if the group is currently accepting new members.  If the problem persists please contact support@invrs.com";
                    console.log(" error checking group membership status ", resp.data.message);
                }
            }).catch(err => {
                 this.loading = false;
                this.errorMessage = "There was an error checking if the group is currently accepting new members.  If the problem persists please contact support@invrs.com";
                console.log("unexpected error checking group membership status ", err);
            });
        },

        close() {
            this.display = false;
            this.loading = false;
            this.monthlyPrice = null;
            this.yearlyPrice = null;
            this.currency = "USD";
           
            this.group = null;
            this.subscribing = false;
            this.monthlyProductId = null;
            this.yearlyProductId = null;
            this.errorMessage = null;
            this.returnParams = null;
        },

        

       subscribe(productId) {
            console.log("creating group membership checkout session");
           
                    
            this.subscribing = true;
            PaymentService.createGroupMembershipCheckoutSession(this.group.groupId, productId, this.returnParams).then( resp => {
                    this.subscribing = false;
                if( resp.data.status == "success") {
                    sessionStorage.setItem('showTourPrompt', false);
                    window.location.href = resp.data.url;
                }
                else {
                    this.$toast.add({ severity: 'error', summary: 'Error subscribing. Contact us if the issue persists.', detail: '', life: 5000,  group: 'center'  });
                }
            }).catch(err => {
                this.subscribing = false;
                    this.$toast.add({ severity: 'error', summary: 'Error subscribing', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
                    console.log("error creating group membership checkout session ", err);
            })
            
           
       }

       
    }
}
</script>

<style>
.group-subs-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
}


.group-subs-modal .p-dialog-content {
    padding: 0px 18px 0px;
}

.group-subs-modal .p-dialog-footer {
    padding: 34px 0px;
    text-align: center;
}
</style>
<style scoped>
 *:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    width: 400px;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 16px 0px 16px 16px;
    text-align: center;
}

.header-highlight {
    color: #693BF5;
}

.body-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
    margin: 12px 0px 30px;
}

.perks-container {
    text-align: center;
}

.perk-container {
    margin-bottom: 16px;
}
.perk-container i {
    color: #33CC99;
}
.perk-container span {
    color: black;
    font-size: 18px;
}
.perk-container:last-child {
    margin-bottom: 0px;
}

.subscribe-button{
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;

    font-size: 14px;
    font-weight: bold;
    padding: 8px 14px;
    margin: 0px;
}

.subscribe-button:hover {
    background-color: #33CC99;
    border: 1px solid #33CC99;
}


.upgrade-button, .view-button {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 64px;
    margin: 0px;
}

.view-button {
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;
}

.view-button:hover {
    background-color: #32364e;
    border: 1px solid #32364e;
}

.p-button:last-child {
    margin-right: 0 !important;
}
</style>