<template>
    
        <GroupFeedListItem :key='latestFeed.groupFeedId' @feed-clicked="onFeedClick" :feed="latestFeed"
            :isGroupOwnerOrAdmin="isGroupOwnerOrAdmin" @remove-feed-notifications="removeReadFeedNotifications" />
        <DataTable v-if="!initLoading && (feeds && feeds.length > 0)" scrollable style="height: 50%; flex-grow: 1; max-height: 100%;" scrollHeight="flex"
            @rowReorder="onRowReorder" :value="feeds" class="p-datatable-sm">
            <!-- <Column v-if="isGroupOwnerOrAdmin" rowReorder cellStyle="width: 1rem;" /> -->
            <Column>
                <template #body="feed">
                    <GroupFeedListItem :key='feed.data.groupFeedId' @feed-clicked="onFeedClick" :feed="feed.data"
                        :isGroupOwnerOrAdmin="isGroupOwnerOrAdmin"
                        @remove-feed-notifications="removeReadFeedNotifications" />
                </template>
            </Column>
        </DataTable>
    

    
</template>

<script>
import GroupService from '../../service/GroupService';
import FeedService from '../../service/FeedService';

import GroupFeedListItem from './GroupFeedListItem.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import UserUtils from '../../utilities/UserUtils';

import EventBus from '../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'GroupFeedList',
    emits: ['feed-selected'],
    props: {
        queryText: {
            type: String,
            default: ''
        },

        selectedGroup: {
            type: Object,
            required: true
        },
        sideMenuWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            feeds: [],
            noMoreResults: false,
            totalMatches: 0,
            header: '',

            currentlyVisible: false,

            deleteHandler: null,
            wasUnmounted: false,
            fetchInProgress: false,
        }
    },
    components: {
        GroupFeedListItem,
        DataTable,
        Column
    },
    computed: {
        ...mapState(['feedNotificationCounts','selectedGroupConduit']),

        isGroupOwnerOrAdmin() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },

        latestFeed() { 
            return {
                groupId: this.selectedGroup.groupId,
                groupFeedId: 0,
                name: 'Latest',
                privateFeed: this.selectedGroup.isPrivate,
                description: (this.selectedGroup.isPrivate ? 
                                'Latest posts across all feeds within this group.  You can also post directly to this feed.'
                                : 'Latest posts from all public feeds within this group.  You can also post directly to this feed.'),
            }
        }
    },

    mounted() {
        //console.log("Group Channel list mounted",this.isListDM);

        EventBus.off('refresh-my-feed-list');
        EventBus.on('refresh-my-feed-list', () => {
            //console.log("received refreshmy channel list event ");
            this.initFetchGroupFeeds("refresh-my-feed-list event");
        });


        EventBus.off('feed-notification-group-feed-list', this.refreshFeedNotifications);
        EventBus.on('feed-notification-group-feed-list', this.refreshFeedNotifications);

        this.initFetchGroupFeeds("mounted");


    },

    unmounted() {
        EventBus.off('feed-notification-group-feed-list');
        this.wasUnmounted = true;
    },


    watch: {
        selectedGroup(newVal, oldVal) {

            if (oldVal && newVal) {
                //console.log("selected group changed from " + oldVal.groupId + " to "+ newVal.groupId)
                if (this.wasUnmounted || (oldVal.groupId != newVal.groupId) || oldVal.isMember != newVal.isMember) {

                    this.initFetchGroupFeeds("selectGroupWatcher");
                }

            }
        },

        $route(newRoute, oldRoute) { //eslint-disable-line
            if (!newRoute.fullPath.startsWith('/groups/profile/')) {
                this.wasUnmounted = true;
            }

        },
    },

    methods: {

        onRowReorder(event) {
            this.feeds = event.value;
            const groupFeedIds = this.feeds.map(feed => feed.groupFeedId);
            console.log("onRowReorder", event.value, groupFeedIds);
            GroupService.updateGroupFeedOrdering(this.selectedGroup.groupId, groupFeedIds).then(resp => {
                if (resp.data.status == "success") {
                    console.log("success", resp);
                }
            });
        },

        initFetchGroupFeeds(context) {//eslint-disable-line
            // console.log("initFetchGroupFeeds called from ", context, this.selectedGroup.groupId);
            this.feeds = [];
            this.initLoading = true;

            this.fetchGroupFeeds();



        },

        fetchGroupFeeds() {
            if (!this.fetchInProgress) {
                this.fetchInProgress = true;
                GroupService.getGroupFeeds(this.selectedGroup.groupId).then(resp => {

                    try {

                        this.handleLoadingResp(resp);
                    } finally {
                        this.fetchInProgress = false;
                    }

                }).catch(err => {
                    console.log("error getting group feeds: ", err);
                    this.fetchInProgress = false;
                });
            }

        },

        handleLoadingResp(resp) {


            this.loading = false;
            //TODO handle paging - depends on how we're going to implement ui
            if (resp.data.status == "success") {
                //console.log("got  "+ resp.channels.length+" channels")
                if (resp?.data?.groupFeeds?.length > 0 && this.selectedGroup.groupId == resp.data.groupFeeds[0].groupId) {
                    this.feeds = resp.data.groupFeeds;
                }
                this.noMoreResults = true;
                // console.log("handleLoadingResp", this.feeds);
            }
            else {
                console.error("error getting group feeds :" + resp.data.message)
            }

            if (this.initLoading) {
                this.initLoading = false;
                this.wasUnmounted = false;
                this.$nextTick(() => {
                    EventBus.emit("initial-group-feeds-loaded");
                })

            }
        },

        onFeedClick(feedSelection) {

            this.$emit('feed-selected', feedSelection);
        },

        removeReadFeedNotifications(feed) {
            if (feed.groupFeedId == 0) {
                const unreadGroupNotifications = this.feedNotificationCounts?.unreadGroupNotifications[feed.groupId];
                if (unreadGroupNotifications) {
                    if (unreadGroupNotifications.latestNotificationIds && unreadGroupNotifications.latestNotificationIds.length > 0) {
                        FeedService.markNotificationsReadByIds(unreadGroupNotifications.latestNotificationIds);
                    }
                    this.$store.commit("REMOVE_GROUP_POST_FEED_NOTIFICATIONS_READ", feed);
                }
            } else {
                const groupFeedNotifications = this.feedNotificationCounts?.unreadGroupNotifications[feed.groupId]?.groupFeedNotifications;
                if (groupFeedNotifications) {
                    const groupFeedNotification = groupFeedNotifications.find(n => feed.groupFeedId == n.feedId);
                    if (groupFeedNotification && groupFeedNotification.feedNotificationIds && groupFeedNotification.feedNotificationIds.length > 0) {
                        FeedService.markNotificationsReadByIds(groupFeedNotification.feedNotificationIds);
                    }
                    this.$store.commit("REMOVE_GROUP_POST_FEED_NOTIFICATIONS_READ", feed);
                }
            }
        },

    }
}
</script>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.channel-item-placeholder-icon {
    margin-right: 4px;
    font-size: 14px;
}

::v-deep(.p-datatable-thead) {
    display: none !important;
}

::v-deep(.p-datatable-tbody tr td) {
    padding: 0 !important;
    border: unset !important;
}

::v-deep(.p-datatable-tbody tr td) {
    margin-right: 4px;
    flex-grow: 0;
}

::v-deep(.p-datatable-tbody tr td:last-child) {
    margin-right: 0;
    flex-grow: 1;
}

::v-deep(.p-datatable-dragpoint-bottom) {
    border-bottom: 2px solid #32364e;
}

::v-deep(.p-datatable-wrapper) {
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar) {
    width: 10px;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-track) {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf;
    border-radius: 10px;
}
::v-deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf;
    border-radius: 10px;
}
</style>