<template>
    <div ref="feedItem" :class="feedItemClass" @click="onFeedClick"  >
        <span class="material-symbols-outlined p-button-icon feed-item-icon" v-tooltip.bottom="feedTypeToolTip">speaker_notes</span>
        <span class="feed-item-title" v-tooltip.bottom="feedTypeToolTip">{{displayFeedTitle()}}</span>

        <div class="item-count-container" >
            <span class="dot" v-if="getFeedNotificationCount()" v-tooltip.bottom="getFeedNotificationCount()"></span>
        </div>

        <Button v-if="isGroupOwnerOrAdmin && feed" icon='pi pi-pencil'
            class="action-button p-button-text" @click.stop='editFeed' v-tooltip.bottom="feed.courseId ? 'Edit module' : 'Edit feed'" />
       <Button v-if="isGroupOwnerOrAdmin && feed && feed.groupFeedId > 0 " icon='pi pi-trash' class="action-button p-button-text" @click='$event=>deleteFeed($event)' v-tooltip.bottom="'Delete'"/>
       <!-- <i v-if="isMobile()" :class="(showAsActive ? '' : 'hidden ') + 'pi menu-active pi-check' "/> -->
        
    </div>
    <ConfirmModal :headerText="'Delete feed: ' + displayFeedTitle()+'?'" confirmButtonLabel='Yes' @confirmed="deleteConfirmed" ref='deleteFeedModal' />
</template>

<script>



import GroupService from '../../service/GroupService';
import BrowserUtils from '../../utilities/BrowserUtils';
import EventBus from '../../event-bus';
import {mapState} from 'vuex';
import ConfirmModal from '../modal/ConfirmModal.vue';
import { hasPremiumGroupSubscription, isINVRSGroup} from './';


export default {
    name: 'FeedListItem',
    emits: ['feed-clicked', 'remove-feed-notifications'],
    props: ['feed', "isGroupOwnerOrAdmin"],
    components: {
      ConfirmModal
    },

    data() {
        return {
            newPostsCount:0,

            thisSelected: false,
        };
    },

  
    mounted() {
        // console.log("mounted FeedListItem", this.feed);
        this.thisSelected = this.isItemSelected(this.selectedGroupConduit);
    },

    computed: {
        ...mapState(['selectedGroup']),
        ...mapState(['selectedGroupConduit']),
        ...mapState(['feedNotificationCounts']),
        
       

         feedItemClass() {
            let baseClass = (this.isGroupOwnerOrAdmin ? 'feed-item-owner ' : 'feed-item ');
            const feedTypeClass = this.feed.privateFeed ? 'private' : 'public';
            if( this.thisSelected ) {
                return `${baseClass} ${feedTypeClass} selected`;
            }
            else {
                return `${baseClass} ${feedTypeClass}`;
            }
        },
        showAsActive() {
            if( this.thisSelected ) {
                return true;
            }
            else {
                return false;
            }
        },
        feedTypeToolTip() {
            //return (this.feed.description ? this.feed.description 
            if (this.feed.privateFeed) {
                return 'Private Feed: '+ this.feed.description;
            } else {
                return 'Public Feed: '+ this.feed.description;
            }
        }
    },

    watch:{
        selectedGroupConduit(newConduit, oldConduit) { // eslint-disable-line
            // console.log("feed list item detected group conduit changed ", newConduit);
            this.thisSelected = this.isItemSelected(newConduit);
            if(this.thisSelected){
                this.$emit('remove-feed-notifications', this.feed);
            }
        },
    },

    methods:{
        canDeleteFeeds() {
            return (isINVRSGroup(this.selectedGroup.groupId) || this.selectedGroup.educational || hasPremiumGroupSubscription(this.selectedGroup.subscriptionId)) && (this.isGroupOwner || this.isGroupAdmin);
        },

        getFeedNotificationCount(){
           if(this.feed && !this.selectedGroup.educational){
                const groupNotifications = this.feedNotificationCounts?.unreadGroupNotifications[this.feed.groupId]?.groupFeedNotifications;
                if(groupNotifications) {
                    const groupFeedNotifications = groupNotifications.find(feed=>feed.feedId==this.feed.groupFeedId);
                    const count = groupFeedNotifications?.feedCount ? groupFeedNotifications.feedCount : 0;

                    if(count == 0){
                        return 0;
                    }
                    if(count == 1){
                        return "1 post";
                    }
                    if(count > 10){
                        return "10+ posts";
                    }

                    return count +" posts";
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },
         
        isMobile() {
            return BrowserUtils.isMobile();
        },

        isItemSelected(item){
            //console.log("isSubItemSelected feed",item);
            return item && this.feed 
                && item.groupId == this.feed.groupId 
                && (item.type == "feed" || item.type == "courseFeed")
                && item.obj.groupFeedId == this.feed.groupFeedId;
        },

        // channel data that has property 'state' will come from your own channels that you belong to, meaning that you're already a member

        onFeedClick() {
          
            if( this.$store.state.chatMode == 'groups') {
                this.$emit('feed-clicked', this.feed);
            }
        },

        editFeed() {
            
            EventBus.emit("open-create-edit-group-feed-modal",{ feed: this.feed});
        },


        deleteFeed(e) {
            e.stopPropagation();

            this.$refs.deleteFeedModal.open();

           
           
        },

        deleteConfirmed() {
             // console.log("blockDmChannel",this.dmUser);
            GroupService.deleteGroupFeed(this.feed.groupId, this.feed.groupFeedId).then(resp=> {// eslint-disable-line
                if( resp.data.status == 'success'){
                    this.$toast.add({severity:'success', summary: 'The feed was deleted.', detail:'', life: 3000, group: 'tc'});
                    
                    if(this.feed?.courseId){
                        EventBus.emit('group-course-feed-deleted');
                    } else {
                        EventBus.emit('group-feed-deleted');
                    }
                } else {
                    this.$toast.add({severity:'error', summary: 'The feed was not deleted.', detail:'', life: 3000, group: 'tc'});
                }
                
            });
        },

        


        displayFeedTitle() {
            

            return this.feed.name;
        },

       
       
    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text  {
    background: white !important;
    color: #32364e !important;
}
.p-tooltip.p-component.p-tooltip-bottom {
    z-index: 999;
}
</style>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.hidden {
    visibility: hidden;
}

.feed-item {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}
.feed-item-owner {
    width: 100%;
    min-height: 33px !important;
    display: flex;
    align-items: center;
    padding: 4px 4px;
    margin: 2px 0px;
    border-radius: 8px;
}

.feed-item:hover, .feed-item-owner:hover {
    background: #f2f4fa;
    cursor: pointer;
}
.feed-item.selected ,  .feed-item-owner.selected{
    background: #f2f4fa;
    cursor: unset;
}
/* .feed-item:hover .feed-title {
    color: #FFFFFF;
} */

.private .feed-item-icon {
    font-variation-settings: 
        'FILL' 1
}
.feed-item-icon {
    margin-right: 4px;
    font-size: 14px;
}

.p-avatar {
    min-width: 48px;
}



.feed-item .feed-title {
    /* font-weight: bold; */
    color: #32364e;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.feed-item .feed-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.feed-item .time-posted {
    white-space: nowrap;
    font-size: 14px;
}

.feed-item .more-options {
    margin-left: auto;
}

.feed-item .feed-item-name-container {
    display: flex;
    flex-direction: column;
}
.feed-item-dm .feed-item-name-container {
    max-width: 70%;
}

.feed-item-dm-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.feed-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.feed-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover{
    border-color: #FFFFFF !important;
}

.block-btn{
    /* margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px; */
    border-radius: 16px;
    font-size: 0.8rem;
}

.action-button {
    border-radius: 30%;
    padding: 4px !important;
    width: unset !important;
    box-shadow: unset !important;
    color: #32364E;
}

.action-button:active,
.action-button:hover {
    background: #32364e !important;
    color: white !important;
   
}

.item-count-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.dot {
    width: 8px !important;
    height: 8px !important;
    padding: unset !important;
    background-color: #e63e3e;
    margin: 4px;
    height: 100%;
    color: #fff;
    padding: 4px 8px;
    border-radius: 30px;
    white-space: nowrap;
}

</style>