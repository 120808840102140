<template>
    <div v-if="!initLoading && (!channels || channels.length <= 0)" style="font-style:italic;margin: 5px 0px 5px 5px;">
        <i class="pi pi-comments channel-item-placeholder-icon" ></i><small>{{ (selectedGroup.isMember ? 'No Channels Yet': 'Chat Channels only visible to group members.')}}</small>
    </div>
    <div style="height: 50%; flex-grow: 1; max-height: 100%;">
        <infinite-scroll @infinite-scroll="loadMoreChannels" message="" :noResult="noMoreResults" style="width: 100%; height: 100%;">
            <GroupChannelListItem v-for='channel in channels' :key='channel.id' @channel-clicked="onChannelClick" :sideMenuWidth="sideMenuWidth" 
                :channel="channel" :isGroupOwnerOrAdmin="isGroupOwnerOrAdmin" :selectedGroup="selectedGroup"/>
        </infinite-scroll>
    </div>
</template>

<script>
import ChatService from '../../service/ChatService';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import GroupChannelListItem from './GroupChannelListItem.vue';
import UserUtils from '../../utilities/UserUtils';
import EventBus from '../../event-bus';

export default {
    name: 'GroupChannelList',
    emits: ['channel-selected'],
    props: {
        queryText: {
            type: String,
            default: ''
        },
      
        selectedGroup: {
            type: Object,
            required: true
        },
        sideMenuWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            channels: [],
            noMoreResults: false,
            selectingChannel: false,
            selectedChannelForAction: null,
            totalMatches: 0,
            header: '',
            selectedMenuItem: null,
            
            currentlyVisible : false,
            mentionNotificationHandler: null,
            messageNotificationHandler: null,
            deleteHandler: null,
            wasUnmounted: false,
            fetchInProgress : false,

            initGroupChannelsStateAttempt: 0
        }
    },
    components: {
        InfiniteScroll, 
        GroupChannelListItem
    },
    computed: {
        scrollStyle(){
            const itemHeight = 38;
            // const channelsShown = this.channels.length < 5 ? this.channels.length : 5;
            // const scrollHeight = itemHeight * channelsShown;
            const scrollMaxHeight = (itemHeight * 5) - 4;
            return `width: 100%; max-height: ${scrollMaxHeight}px; height: 100%;`;
        },
        isGroupOwnerOrAdmin() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },
    },

    mounted() {
        this.initGroupChannelsStateAttempt = 0;
        //console.log("Group Channel list mounted",this.isListDM);

        EventBus.off('refresh-my-channel-list');
        EventBus.on('refresh-my-channel-list', () => {
            //console.log("received refreshmy channel list event ");
            this.initalFetchChannels("refresh-my-channel-list event");
        })

       

        EventBus.off('mark-messages-read');
        EventBus.on('mark-messages-read', (channelId) => {
            this.markChannelRead(channelId);
        })

        // if( !this.messageNotificationHandler ){
        //     this.messageNotificationHandler = (chatNotification) => {
        //         this.updateUnread(chatNotification);
        //     };
        // }

        if( !this.deleteHandler ){
            this.deleteHandler = (notification) => {//eslint-disable-line
                
               
                this.$store.commit('MARK_CHANNELS_STALE')
                if( this.currentlyVisible ) {
                    this.initalFetchChannels("delete channel handler");
                }
                
                
            };
        }
        EventBus.off('channel-notification-group-channel-list', this.onNewChannelNotification);
        EventBus.on('channel-notification-group-channel-list', this.onNewChannelNotification);
        EventBus.off('chat-channel-deleted-notification', this.deleteHandler);
        EventBus.on('chat-channel-deleted-notification', this.deleteHandler);

        this.initalFetchChannels("mounted");
         
        
    },

    unmounted() {
        this.wasUnmounted = true;
        this.initLoading = false;
    },

   
   watch: {
        selectedGroup(newVal, oldVal) {
            
            if( oldVal && newVal) {
                //console.log("selected group changed from " + oldVal.groupId + " to "+ newVal.groupId)
                if( this.wasUnmounted || (oldVal.groupId != newVal.groupId) || oldVal.isMember != newVal.isMember) {
                    this.initGroupChannelsStateAttempt = 0;
                    this.initalFetchChannels("selectGroupWatcher");
                }
                
            }
        },

         $route(newRoute, oldRoute) { //eslint-disable-line
            if(!newRoute.fullPath.startsWith('/groups/profile/') ) {
                this.wasUnmounted = true;
            }
            
        },
   },

    methods: {

        onNewChannelNotification(notification) {
            if(notification && this.channels){
                const channelId = notification?.message?.chatChannelId;
                // console.log("onNewChannelNotification", notification, channelId,this.channels);
                for(let i = 0; i < this.channels.length; i++ ) {
                    let c = this.channels[i];
                    if (c.data.chatChannelId == channelId ) {
                        c.state.unreadCount = c.state.unreadCount ? c.state.unreadCount + 1 : 1;
                        // console.log("onNewChannelNotification found", c.state.unreadCount);
                        break;
                    }
                }
            }
        },

        markChannelRead(channelId) {
            for( var i = 0; i < this.channels.length; ++i ) {
                let c = this.channels[i];
                if (c.data.id == channelId ) {
                    c.state.unreadCount = 0;
                    c.state['mentionCount'] = 0;
                    break;
                }
            }
        },

        initalFetchChannels(context) {//eslint-disable-line
            //console.log("initial fetch channels called from "+ context);
            this.fetchInProgress = false;
            this.wasUnmounted = false;
            this.initLoading = false;
            
            this.fetchChannels(20, 0);
        },

        fetchChannels(limit, offset) {
            if (!this.fetchInProgress ){
                this.initLoading = true;
                this.fetchInProgress = true;
                // console.log("waiting to fetch group channels for group  " +this.selectedGroup.groupId);
                ChatService.waitForClient().then(() => {
                    // console.log("Fetching group channels for group  " +this.selectedGroup.groupId);
                    ChatService.getGroupChannels(this.selectedGroup.groupId, limit, offset, null, null, null, this.selectedGroup.isMember).then(  resp => {
                            
                        try {
                            this.fetchInProgress = false;
                            this.handleLoadingResp(resp, offset);
                        }catch(err){
                            console.log("error handling group channels resp: ", err);
                            this.initLoading = false;
                        }
                        
                    }).catch(err => {
                        console.log("error getting group channels attempt #", this.initGroupChannelsStateAttempt, err);
                        this.fetchInProgress = false;
                        this.initLoading = false;
                        this.initGroupChannelsStateAttempt++;

                        if (this.initGroupChannelsStateAttempt <= 2) {
                            setTimeout(() => {
                                this.fetchChannels(20, 0);
                            }, 1000);
                        }
                    });
                        
                }).catch(err => {
                    console.log("error getting group channels attempt #", this.initGroupChannelsStateAttempt, err);
                    this.fetchInProgress = false;
                    this.initLoading = false;
                    this.initGroupChannelsStateAttempt++;

                    if (this.initGroupChannelsStateAttempt <= 2) {
                        setTimeout(() => {
                            this.fetchChannels(20, 0);
                        }, 1000);
                    }
                });
            }
        },

        handleLoadingResp(resp, offset) {
            this.loading = false;
            this.initLoading = false;

            const initialChannelsLoaded = offset == 0 || offset != this.channels.length;

            if(initialChannelsLoaded){
                this.channels = [];
            }

            if( resp.status == "success") {
                if (resp.channels.length > 0) {
                    // console.log("handleLoadingResp", this.selectedGroup.groupId, resp.channels[0]?.data?.group?.groupId);
                    if(this.selectedGroup.groupId == resp.channels[0]?.data?.group?.groupId){
                        if(offset == 0 || offset != this.channels.length){
                            this.channels = resp.channels; 
                        } else {
                            this.channels = this.channels.concat(resp.channels); 
                        }
                    
                        this.noMoreResults = false;
                    } else {
                        console.error("Selected group and received channels don't match. Ignoring.");
                    }
                    
                
                } else {
                    this.noMoreResults = true;
                }
            }
            else {
                console.error("error getting group channels :" + resp.message)
            }

            if (initialChannelsLoaded) {
                EventBus.emit("initial-side-menu-data-loaded");
            }
        },

        async loadMoreChannels() {
            //console.log("loadm more channels called")
            if (!this.loading && this.channels.length >= 20) {
                this.loading = true;

                if (!this.noMoreResults) {
                    this.fetchChannels(20, this.channels.length);
                } else {
                    this.loading = false;
                }
            }
            
        },

        onChannelClick(channel) {
            if( channel.channelData.state) {
                channel.channelData.state.unreadCount = 0;
            }
            this.$emit('channel-selected', channel);

        },

    }
}
</script>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}
.channel-item-placeholder-icon {
    margin-right: 4px;
    font-size: 14px;
}
/* 
::v-deep(.channel-item) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.channel-item:hover) {
    background: #32364e;
}
::v-deep(.channel-item:hover .channel-title) {
    color: #FFFFFF;
}

::v-deep(.channel-item .channel-title) {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep(.channel-item .more-options) {
    margin-left: auto;
} */
</style>