<template>
    <div v-if="!initLoading && (!currentGroupPortfoliosShown || currentGroupPortfoliosShown.length <= 0)" style="font-style:italic;margin: 5px 0px 5px 5px;">
        <i class="pi pi-comments channel-item-placeholder-icon"></i><small>{{ (selectedGroup.isMember ? (isGroupOwner ? 'Create a Group Portfolio!' : 'No Portfolios Yet') :
        'Group portfolios only visible to group members.') }}</small>
    </div>
    <infinite-scroll style="height: 0; flex-grow: 1;">
        <GroupPortfolioListItem v-for='portfolio in currentGroupPortfoliosShown' :key='portfolio.groupPortfolioId' @portfolio-clicked="onPortfolioClick"
            :sideMenuWidth="sideMenuWidth" :portfolio="portfolio" :isGroupOwner="isGroupOwner" :isGroupAdmin="isGroupAdmin" />
    </infinite-scroll>

    <ConfirmModal :headerText="deleteModalHeaderText" confirmButtonLabel='Yes'
        @confirmed="deleteConfirmed" ref='deletePortfolioAccountModal' />
</template>

<script>
import ConfirmModal from '../modal/ConfirmModal.vue';

import GroupService from '../../service/GroupService';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import GroupPortfolioListItem from './GroupPortfolioListItem.vue';
import UserUtils from '../../utilities/UserUtils';

import {mapState} from 'vuex';
import EventBus from '../../event-bus';

export default {
    name: 'GroupPortfolioList',
    emits: ['portfolio-selected'],
    props: {
        queryText: {
            type: String,
            default: ''
        },

        selectedGroup: {
            type: Object,
            required: true
        },
        sideMenuWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            selectingPortfolio: false,
            selectedPortfolioForAction: null,
            totalMatches: 0,
            header: '',
            selectedMenuItem: null,

            deleteModalHeaderText: '',
            deleteModalData: null,

            currentGroupPortfoliosShown: null,

            deleteHandler: null,
            wasUnmounted: false,
            fetchInProgress: false,
        }
    },
    components: {
        InfiniteScroll,
        GroupPortfolioListItem, ConfirmModal
    },
    computed: {
        ...mapState(['selectedGroupConduit', 'currentGroupPortfolios']),

        scrollStyle() {
            // const itemHeight = 39;
            // const scrollHeight = itemHeight * 5;//this.portfolios.length;
            // const scrollMaxHeight = (itemHeight * 5) - 4;
            return `width: 100%; max-height:100%; height: 100%;`;
        },
        isGroupOwner() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId);
        },
        isGroupAdmin() {
            return UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },
    },

    mounted() {
        //console.log("Group Channel list mounted",this.isListDM);

        EventBus.off('open-delete-confirm-modal');
        EventBus.on('open-delete-confirm-modal', this.openDeleteConfirmModal);
        if(this.currentGroupPortfolios){
            this.currentGroupPortfoliosShown = this.currentGroupPortfolios.filter(portfolio => portfolio.groupPortfolioId > 0);
        }
    },

    unmounted() {
        this.wasUnmounted = true;
    },

    watch: {
        $route(newRoute, oldRoute) { //eslint-disable-line
            if (!newRoute.fullPath.startsWith('/groups/profile/')) {
                this.wasUnmounted = true;
            }

        },
        
        currentGroupPortfolios(newValue){
            //console.log("currentGroupPortfolios",newValue);
            this.currentGroupPortfoliosShown = newValue.filter(portfolio => portfolio.groupPortfolioId > 0);
        },
    },

    methods: {
        onPortfolioClick(portfolioSelection) {
            this.$emit('portfolio-selected', portfolioSelection);
        },

        openDeleteConfirmModal(data) {
            this.deleteModalData = data;
            if(data.portfolio){
                this.deleteModalHeaderText = 'Delete portfolio?';
                this.$refs.deletePortfolioAccountModal.open();
            } else if(data.account) {
                this.deleteModalHeaderText = 'Remove account?';
                this.$refs.deletePortfolioAccountModal.open();
            }
        },

        deleteConfirmed(){
            if(this.deleteModalData.portfolio){

                GroupService.deleteGroupPortfolio(this.selectedGroup.groupId, this.deleteModalData.portfolio.groupPortfolioId).then(resp => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'The portfolio was deleted.', detail: '', life: 3000, group: 'tc' });

                        EventBus.emit('group-portfolio-deleted');
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'The portfolio was not deleted.', detail: '', life: 3000, group: 'tc' });
                    }
                    if(this.deleteModalData.callback){
                        this.deleteModalData.callback();
                    }
                });

            } else if(this.deleteModalData.account) {

                GroupService.deleteGroupPortfolioAccount(this.selectedGroup.groupId, this.deleteModalData.account.groupPortfolioId, 
                    this.deleteModalData.account.investmentAccountId).then(resp => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Account was removed.', detail: '', life: 3000, group: 'tc' });

                        EventBus.emit('group-portfolio-deleted');
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Account was not removed.', detail: '', life: 3000, group: 'tc' });
                    }
                    if(this.deleteModalData.callback){
                        this.deleteModalData.callback();
                    }
                });

            }
        }

    }
}
</script>

<style scoped>
::v-deep(*),
:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.channel-item-placeholder-icon {
    margin-right: 4px;
    font-size: 14px;
}

/* 
::v-deep(.channel-item) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.channel-item:hover) {
    background: #32364e;
}
::v-deep(.channel-item:hover .channel-title) {
    color: #FFFFFF;
}

::v-deep(.channel-item .channel-title) {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep(.channel-item .more-options) {
    margin-left: auto;
} */
</style>