<template>
    <infinite-scroll @infinite-scroll="loadOlderMessages" message="" :noResult="noMoreResults" direction='up' ref='chatScroll' :style='messageListScrollStyle'>
        <LoadingSpinner :showLoading="loading" :style='loadingSpinnerStyle' /> 
        <Message :data='msg' v-for='msg in messages' :key='msg.id' :rootElementId="rootElementId" :channel="channelParent" :showReactionCounts="showReactionCounts" :type="type"/>

    </infinite-scroll>
</template>

<script>
import Message from '../chat/Message.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';

import EventBus from '../../event-bus';
import moment from "moment";

export default {
    name: 'MessageList',
    emits: ['load-more-messages'],
    props: {
        channelMessages: {
            type: Array,
            required: true
        },
        channelParent: {
            type: Object,
            required: true
        },
        descriptionHeight: {
            type: Number,
            required: true
        },
        rootElementId: {
            type: String,
            required: false,
            default: "#appChatDrawer"
        },
        showReactionCounts: {
            type: Boolean,
            required: true
        
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            initialLoadingDone: false,
            noMoreResults: false,
            messages: [], // Use this for actually displaying the current list of messages on the channel
            offset: 0,
            ro: null,
            scrollpanelContentNode: null,
            scrollpanelContentChildNode: null,
            previousScrollHeight: null,
            loadingSpinnerStyle: 'width: 100%; height: 100px; max-height: 100px; min-height: 100px;',
            containerHeight: 0,
            updateHandler: null,
        }
    },
    computed: {
        messageListScrollStyle() {
           
            let s =  `width: 100%; max-height: calc(100% - ${this.containerHeight + this.descriptionHeight}px); height: calc(100% - ${this.containerHeight + this.descriptionHeight}px);`;
             //console.log(s)
             return s;
        }
    },
    components: {
        InfiniteScroll, LoadingSpinner, Message
    },

    created() {
        EventBus.off('get-container-height');
        EventBus.off('update-message-list');
        EventBus.on('get-container-height', (height) => {
            this.updateScrollPositionWithContainerHeight(height);
        })
        EventBus.on('update-message-list', (newMsg) => {
            this.updateMessageList(newMsg);
        })

        

        EventBus.off('message-deleted');
        EventBus.on('message-deleted', (deletedMessage) => {
            this.removeMessage(deletedMessage);
        })

        if( !this.updateHandler) {
            this.updateHandler = (msg) => {
                this.updateMessage(msg);
            };
        }

        EventBus.off('update-message', this.updateHandler);
        EventBus.on('update-message', this.updateHandler);
    },

    mounted() {
        const length = this.channelMessages.length;
        this.scrollpanelContentNode = this.$refs.chatScroll.$el.getElementsByClassName('p-scrollpanel-content')[0];

        if (this.channelMessages.length > 20) {
            this.messages = this.markSubsequentMessages(this.channelMessages.slice(length - 20, length));
        } else {
            this.messages = this.markSubsequentMessages(this.channelMessages);
        }

        this.$nextTick(() => {
            if (length <= 20) {
                this.noMoreResults = true;
            } else {
                this.offset = this.messages[0].id;
            }
        });

        try {
            this.scrollpanelContentChildNode = document.querySelector('.message-list-container .p-scrollpanel-content div');
            if(this.scrollpanelContentChildNode ) {
                this.ro = new ResizeObserver(this.onResize).observe(this.scrollpanelContentChildNode);
            }
        }catch(err) {
            console.warn("error mounting scrollpanelContentChildNode: " +err);
        }
    },

    unmounted() {
        if (this.ro) {
            this.ro.unobserve(this.scrollpanelContentChildNode);
        }
        this.initialLoadingDone = false;
    },

    methods: {
        onResize() {
            const scrollHeight = this.scrollpanelContentChildNode.scrollHeight;
            // console.log("onResize this.initialLoadingDone",this.initialLoadingDone, scrollHeight, this.scrollpanelContentNode.scrollTop);
            if(scrollHeight != 0 && this.scrollpanelContentNode.scrollTop != 0 && !this.initialLoadingDone){
                // console.log("onResize this.scrollpanelContentChildNode.clientHeight", scrollHeight, this.scrollpanelContentNode.scrollTop);
                this.scrollpanelContentNode.scrollTop = scrollHeight;
                this.initialLoadingDone = true;
            }
        },

        updateMessage(updatedMessage){
            //console.log("recieved update message for "+ JSON.stringify(updatedMessage));
            for(var i =0; i < this.messages.length; ++i ) {
                if( this.messages[i].id == updatedMessage.id) {
                    this.messages[i] = updatedMessage;
                    //console.log("msg found");
                }
            }
        },

        /* This basically walks through the array of messages to detect what messages
        are subsequent (Seeing if one message is sent right after the other and so forth within a minute of each other from the same user). Adding
        'subsequentPos' to the msg object is used for specific styling if it were subsequent */
        markSubsequentMessages(currentMessages) {
            let arr = [];
            let inSubsequentMessages = false; // This flag is to determine what messages are subsequent with each other

            for (let i = 0; i < currentMessages.length; i++) {
                const prevMsg = currentMessages[i - 1];
                const nextMsg = currentMessages[i + 1];
                let currentMsg = currentMessages[i];
                

                currentMsg['subsequentPos'] = null;

                /* Quick anon function that checks if the same user has sent both the current message and the previous one from that, then sees
                if it's been within a minute since those two messages were sent */
                const checkFunc = () => {
                    inSubsequentMessages = false;
                    if (prevMsg !== undefined) {
                        if (currentMsg.user.id === prevMsg.user.id) {
                            if (moment(currentMsg.created_at).diff(prevMsg.created_at, 'minutes') <= 1) {
                                currentMsg.subsequentPos = 'last';
                            }
                        }
                    }
                }

                if (nextMsg !== undefined) { // If you're atleast on the first element and not the last one
                    if (currentMsg.user.id === nextMsg.user.id) {
                        if (moment(nextMsg.created_at).diff(currentMsg.created_at, 'minutes') <= 1) {
                            if (!inSubsequentMessages) {
                                inSubsequentMessages = true;
                                currentMsg.subsequentPos = 'first';
                            } else {
                                currentMsg.subsequentPos = 'middle';
                            }
                        } else { 
                            checkFunc();
                        }
                    } else { // For checking messages behind you (If you're not on the first message) and see if it's subsequent with the current one
                        checkFunc();
                    }
                } else { // If you're at the last element, then check behind you
                    checkFunc();
                } 

                arr.push(currentMsg);

            }
            return arr;
        },

        updateScrollPositionWithContainerHeight(height) {
            this.containerHeight = height;

            this.$nextTick(() => {
                // This makes the current scrollbar position go to the very bottom of the message list container
                this.scrollpanelContentNode.scrollTop = this.scrollpanelContentNode.scrollHeight;
            })
        },

        removeMessage(deletedMessage) {
            let indexFlag = 0;
            let currentPos = null;
            let newMessages = [];

            /* Reason for keeping track of the index and subsequent position (If it is a subsequent message) is so that
            we can update the subsequent messages beside it to have the correct positioning, thus correct styling to apply */
            newMessages = this.messages.filter((val, index) => {
                if (val.id === deletedMessage.id) {
                    indexFlag = index;
                    currentPos = val.subsequentPos;
                }

                return val.id !== deletedMessage.id
            });


            if (newMessages.length > 1) {

                if (currentPos) {
                    if (currentPos === 'first') {
                        let nextMsg = null;

                        /*
                            [1,2,3,4,5] - We delete 5, that would be index 4 before it gets deleted. 
                            After it gets deleted, index 4 in the new array would be undefined since the last
                            element is at index 3, so we grab that instead by simply decrementing
                        */
                        if (newMessages[indexFlag] === undefined) { 
                            indexFlag--;
                        }
                        
                        nextMsg = newMessages[indexFlag];

                        if (nextMsg.subsequentPos === 'last') {
                            newMessages[indexFlag].subsequentPos = null;
                        } else if (nextMsg.subsequentPos === 'middle') {
                            newMessages[indexFlag].subsequentPos = 'first';
                        }
                    } else if (currentPos === 'last') {
                        let prevMsg = newMessages[indexFlag - 1];

                        if (prevMsg.subsequentPos === 'middle') {
                            newMessages[indexFlag - 1].subsequentPos = 'last';
                        } else if (prevMsg.subsequentPos === 'first') {
                            newMessages[indexFlag - 1].subsequentPos = null;
                        }
                    }
                } 
                
            }

            this.messages = newMessages;

        },


        recordScrollPosition() {
            this.previousScrollHeight = this.scrollpanelContentNode.scrollHeight - this.scrollpanelContentNode.scrollTop;
        },

        /* This moves the scrollbar to the exact position that gets stored in previousScrollHeight, so that when you reach the very top
        of the scroll container - You won't need to manually go down then to the very top again */
        restoreScrollPosition() {
            this.scrollpanelContentNode.scrollTop = this.scrollpanelContentNode.scrollHeight - this.previousScrollHeight;
        },


        async loadOlderMessages() {
            if (!this.loading && !this.noMoreResults) {
                this.loading = true;
                this.recordScrollPosition();

                const fetchMessages = async () => {
                    return this.channelParent.query({
                        messages: {limit: 20, id_lt: this.offset}
                    });
                }

                setTimeout(async () => {
                    if (!this.noMoreResults) {
                        this.loading = false;

                        const result = await fetchMessages();

                        if (result.messages.length > 0) {
                            this.offset = result.messages[0].id;

                            /* This is just to check if there's more messages to fetch afterwards, so that
                            it won't accidentally try to waste time attempting to fetch more */
                            const moreResults = await fetchMessages();

                            if (moreResults.messages.length === 0) {
                                this.noMoreResults = true;
                            }

                            /*
                            unshift adds the elements at the front of the array, which is what we want since we will see older messages
                            near the front of the array as we scroll higher in the chat channel 
                            */

                            this.messages.unshift(...this.markSubsequentMessages(result.messages));

                            this.$nextTick(() => {
                                this.restoreScrollPosition();
                            });
                        } else {
                            this.noMoreResults = true;
                        }
                    } else {
                        this.loading = false;
                    }
                }, 1000)
               
            }
        },

        updateMessageList(msg) {
            let recentMsg = this.messages[this.messages.length - 1];
            let arr = [];

            msg['isNewMessage'] = true;

            /* This code block basically keeps track if the new message is subsequent to the most recent one to keep
            the subsequent message styling consistent when new messages come in */
            if (recentMsg) {
                const pos = recentMsg.subsequentPos;
                const fromSameUser = recentMsg.user.id === msg.user.id;

                if (pos) {
                    if (pos === 'last') {
                        if (fromSameUser) {
                            if (moment(msg.created_at).diff(recentMsg.created_at, 'minutes') <= 1) {
                                msg['subsequentPos'] = 'last';
                                recentMsg.subsequentPos = 'middle';

                                arr.push(recentMsg, msg);
                            }
                        }
                    }
                } else {
                    if (fromSameUser) {
                        if (moment(msg.created_at).diff(recentMsg.created_at, 'minutes') <= 1) {
                            msg['subsequentPos'] = 'last';
                            recentMsg.subsequentPos = 'first';

                            arr.push(recentMsg, msg);
                        }
                    }
                }
            }

            if (arr.length > 0) {
                this.messages.splice(this.messages.length - 1, 1, ...arr);
            } else {
                this.messages.push(msg);
            }

            this.$nextTick(() => {
                this.scrollpanelContentNode.scrollTop = this.scrollpanelContentNode.scrollHeight;
            })
        }
    }

}
</script>

<style scoped>

::v-deep(.p-scrollpanel-content) {
    padding: 0px 8px 18px 0px !important;
}
.p-scrollpanel ::v-deep(.p-scrollpanel-bar) {
    background: #BFBFBF;
}

::v-deep(a) {
    font-weight: bold;
}
::v-deep(.analyst-mention) {
    color: #693BF5;
}

::v-deep(.user-message  .analyst-mention) {
    color: #a68cf5;
}
::v-deep(.other-message .security-mention ){
    color: #0da975;
}

::v-deep(.other-message .tag-mention){
    color: #0da975;
}
::v-deep(.security-mention) {
    color: #33CC99;
}
::v-deep(.tag-mention) {
    color: #33CC99;
}

@media (max-width: 760px) {
    ::v-deep(.p-scrollpanel-content) {
        padding: 0px !important;
    }
    ::v-deep(.more-options-menu) {
        margin-right: 0px;
    }
}
</style>