<template>
    <Sidebar class="group-profile-side-menu-modal" v-model:visible="display" :modal="false" :showCloseIcon="false" :dismissable="true" @hide="onHide">
        <div style="display:flex;flex-direction:row;align-items:baseline;"><h4>{{selectedGroup.name}}</h4><Button icon="pi pi-angle-left" @click="close()" style="margin-right:-15px;margin-left:auto;  color: #ffffff;background: #32364e;border-color:#32364e;"/></div>
        <GroupProfileSideMenu style="margin:2px;" :selectedGroup="selectedGroup" ref="sideMenu" @group-sidemenu-change="onGroupSideMenuChange($event)"/>
    </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';
import GroupProfileSideMenu from '../GroupProfileSideMenu.vue';


export default {
    name: 'GroupProfileSideMenuModal',
    emits: ['on-modal-closed', 'group-sidemenu-change'],
    components: {
        Sidebar,
        GroupProfileSideMenu,
    },
    props: {
        selectedGroup: {
            type: Object,
            required: true,
        }

    },
    data() {
        return {
            display: false,
            selectedOption: false,
        };
    },

    methods: {
        onGroupSideMenuChange(e) {
            //console.log("group side menu changed")
            this.$emit('group-sidemenu-change', {newItem: e.newItem, oldItem:e.oldItem})
        },
        selectTabByIndex(index) {
            this.$refs.sideMenu.selectTabByIndex(index);
        },
        open() {
            this.display = true;
            //console.log("opening group profile side menu modal")
        },
        close() {
            //console.log("closing group profile side menu modal")
            this.display = false;
        },

        onHide(){
            this.$emit('on-modal-closed');
        }

    },
};
</script>

<style>
.group-profile-side-menu-modal .p-sidebar-content {
    height: 100%;
    overflow-y: unset;
    display: flex;
    flex-direction: column;
}
</style>
<style scoped>
</style>