<template>
    <Dialog :header="(isQuestion ? 'Ask a Question' : 'Post')" v-model:visible="display" :modal="true" :closable="false" class='input-modal'  ref="dialog" id="statusEditorDialog" :closeOnEscape="false">
        <template #header>
         
            <span id="pv_id_11_header" class="p-dialog-title">{{(isQuestion ? 'Ask a Question' : 'Post')}}</span>
            <div class="p-dialog-header-icons" style="margin-left:auto;">
               <Button @click="close()" autofocus="" class="p-dialog-header-icon p-dialog-header-close p-link" icon="pi pi-times" aria-label="Close" type="button"/>
               <!-- <button autofocus="" class="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" type="button"><span class="p-dialog-header-close-icon pi pi-times"></span></button> -->
            </div>
          
         
        </template>
        <div class="status-container" aria:haspopup="true" aria-controls="overlay_panel"  >

            <template v-if="!isQuestion">

                <div class="flex flex-wrap gap-3">
                    <div v-if="!isEducationalPost" class="flex align-items-center">
                        <RadioButton v-model="postType" inputId="statusRadioButton" :value="'status'" key="Status" />
                        <label for="statusRadioButton" class="ml-2">Status</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="postType" inputId="noteRadioButton" :value="'note'" key="Note" />
                        <label for="noteRadioButton" class="ml-2">{{(isEducationalPost ? 'Lesson': 'Note')}}</label>
                    </div>
                    <div v-if="isEducationalPost" class="flex align-items-center">
                        <RadioButton v-model="postType" inputId="taskRadioButton" :value="'task'" key="Task" />
                        <label for="taskRadioButton" class="ml-2">Task</label>
                    </div>
                </div>

                <template v-if="isNote || isTask">
                    
                    <InputText type="text" v-model="title" ref="titleInput" id="noteTitleInput" maxlength="100" class="title-input" placeholder="Enter Title"/>
                    
                    <vue-editor id="feedNoteEditor" ref="feedNoteEditor" v-model="status" :editorOptions="createFeedEditorSettings('#statusEditorDialog .status-container')"  
                                :customModules="editorModules" 
                                :placeholder="editorPlaceholder"
                                />
                    
                    
                </template>
                <template v-else>
                  <div class="adding-ad-checkbox-container" v-if="isAdvertiser && !selectedContest">
                      <Checkbox v-model="addingAd" inputId="addAd" name="addAd" :binary="true" />
                      <label for="addAd">This is an Ad</label>
                  </div>

                  <template v-if="!addingAd">

                    <vue-editor id="feedStatusEditor" ref="feedStatusEditor" v-model="status" :editorOptions="createFeedEditorSettings('#statusEditorDialog .status-container')" :customModules="editorModules" @text-change="watchStatusLength"
                                
                                :placeholder="editorPlaceholder"/>
                                 
                    <span :style="{marginRight: 'auto', color: v$.currentStatusLength.$error ? '#E63E3E': null}">{{currentStatusLength}} / {{statusCharLimit}}</span>
                  </template>
                  <template v-else>
                    <div class="advertising-section">
                      <Dropdown :loading="partnersLoading" :options="partners" optionLabel="name" v-model="selectedPartner" placeholder="Select a partner"/>
                      <Dropdown :loading="campaignsLoading" :disabled="!selectedPartner" :options="campaigns" optionLabel="name" v-model="selectedCampaign" placeholder="Select a campaign"/>
                      <Dropdown :loading="adsLoading" :disabled="!selectedPartner || !selectedCampaign" :options="ads" optionLabel="ad.name" v-model="selectedAd" placeholder="Select an ad"/>

                      <div class="go-to-advertising-section" v-if="hasNoPartnersCampaignsNorAds">
                        <div class='go-to-advertising-message'>{{ goToAdvertisingPageMessage }}</div>
                        <Button label="Go to Advertising" class='green-button__primary go-to-advertising-button' @click="goToAdvertising()"/>
                      </div>
                    </div>

                    <InputText type="text" v-model="title" maxlength="100" class="title-input" placeholder="Enter Title"/>

                    
                    <vue-editor id="feedAdEditor" ref="feedAdEditor"  v-model="status" :editorOptions="createFeedEditorSettings('#statusEditorDialog .status-container')"  
                                :customModules="editorModules"   @text-change="watchStatusLength"
                                :placeholder="editorPlaceholder"/>
                    
                    <span :style="{marginRight: 'auto', color: v$.currentStatusLength.$error ? '#E63E3E': null}">{{currentStatusLength}} / {{adCharLimit}}</span>
                  </template>
                </template>
                <Accordion style="margin-top:5px;">
                  <AccordionTab header="Thumbnails:">
                  <!-- <div class="choices-label">Thumbnails:</div> -->
                  <div class="input-section">
                            <InputSwitch class="input-switch" v-model="thumbnailsEnabled"/><span v-show="thumbnailsEnabled" style="margin-right:auto;margin-left:5px;">Enabled</span><span v-show="!thumbnailsEnabled" style="margin-right:auto;margin-left:5px;">Disabled</span>
                        </div>
                    <div v-show="thumbnailsEnabled">
                      <div class='image-preview-thumbnail'  >
                        
                        <img :id='thumbnailElementId' :src='thumbnailUrl' v-show="thumbnailUrl" />
                        <span v-show="!thumbnailUrl" style="padding:10px;line-height:200px;">No thumbnail provided.  Will autogenerate based on images in post.</span>
                      </div>
                      <span class='p-button p-component action-button'  @mouseup='openFileDialogForImportingImage' style="margin-top: 25px;" v-show='!thumbnailUrl'>
                            <span class="p-button-label">Add Custom Thumbnail</span>
                            <ImageFileInput :srcImageId='thumbnailElementId' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)' :maxHeight="0" :maxWidth="0" :resize="false"/>
                        </span>
                      <Button label='Remove Thumbnail' class='delete action-button' v-show='thumbnailUrl' @click='removeImportedImage' style="margin-top: 5px;"/>
                    </div>
                  </AccordionTab>
                </Accordion>
            </template>
            <template v-else>

                <template v-if="!selectedContest || (selectedContest && isGroupOwnerAdminOrMod)">
                  <div class="question-label">What type of question is this?</div>
                  <div class="flex flex-wrap gap-3">
          
                      <div class="flex align-items-center">
                          <RadioButton v-model="questionType" inputId="general" name="general" value="general" />
                          <label for="general" class="ml-2">General</label>
                      </div>
                      <div class="flex align-items-center">
                          <RadioButton v-model="questionType" inputId="yesNo" name="yesNo" value="yesNo" />
                          <label for="yesNo" class="ml-2">Yes/No Vote</label>
                      </div>
                      <div class="flex align-items-center">
                          <RadioButton v-model="questionType" inputId="poll" name="poll" value="poll" />
                          <label for="poll" class="ml-2">Poll</label>
                      </div>
                  </div>
                </template>

                <div class="question-label" v-if="questionType == 'poll'">Style of Poll:</div>
                <div class="flex flex-wrap gap-3" v-if="questionType == 'poll'">
        
                        <div class="flex align-items-center">
                        <RadioButton v-model="multiType" inputId="multipleChoice" name="multipleChoice" value="multipleChoice" />
                        <label for="multipleChoice" class="ml-2">Only one selection allowed</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="multiType" inputId="multipleSelect" name="multipleSelect" value="multipleSelect" />
                        <label for="multipleSelect" class="ml-2">Multiple selection allowed</label>
                    </div>
                </div>

                
                <InputText type="text" v-model="title" ref="titleInput" id="questionTitleInput" maxlength="100" class="title-input" placeholder="Your Question"/>
        

                
                <vue-editor id="feedQuestionEditor" ref="feedQuestionEditor" v-model="status" :editorOptions="createFeedEditorSettings('#statusEditorDialog .status-container')"  
                            :customModules="editorModules"   
                            :placeholder="editorPlaceholder"/>
                   

                <div class="choices-section" v-show="showEmailSection()">
                    <div class="choices-label">Send as email to all group members:</div>
                    <div class="input-section">
                        <InputSwitch class="input-switch" v-model="sendEmail"/>
                    </div>
                </div>

                <div class="choices-section contest-dropdown" v-if="showContestSection()">
                    <div class="choices-label">Contest (optional):</div>
                    <div class="flex flex-wrap gap-2">
                        <Dropdown v-model="contestOptionId" :options="contestsOptions" placeholder="Select a Contest" showClear optionLabel="label" optionValue="id" />
                    </div>
                </div>

                <div class="choices-section" v-if="showDifficultyLevelSection()">
                    <div class="choices-label">Difficulty level (optional):</div>
                    <div class="flex flex-wrap gap-2">
                        <Dropdown v-model="difficultyLevelOptionId" :options="difficultyLevelOptions" placeholder="Select a Difficulty" showClear optionLabel="label" optionValue="id" />
                    </div>
                </div>

                <div class="choices-section" v-if="showCategorySection()">
                    <div class="choices-label">Category (optional):</div>
                    <div class="flex flex-wrap gap-2">
                        <Dropdown v-model="pollCategoryOptionId" :options="pollCategoryOptions" placeholder="Select a Category" showClear optionLabel="label" optionValue="id" />
                    </div>
                </div>

                <div class="choices-section" v-if="showForceReadSection()">
                    <div class="choices-label">Force users to view poll details before answering:</div>
                    <div class="input-section">
                        <InputSwitch class="input-switch" v-model="forceRead"/>
                    </div>
                </div>

                <div class="choices-section" v-if="showCorrectAnswerYesNoSection()">
                    <div class="choices-label">Correct answer:</div>
                    <div class="flex flex-wrap gap-2">
                        <div class="flex align-items-center" v-for="option in correctAnswerYesNoOptions" :key="option.id">
                            <RadioButton v-model="correctAnswerYesNoOptionId" :inputId="'correctAnswerYesNo'+option.id" :name="option.id" :value="option.id" />
                            <label :for="'correctAnswerYesNo'+option.id" class="ml-2">{{ option.label }}</label>
                        </div>
                    </div>
                </div>

                <div class="choices-section" v-if="showChoicesPollSection()">
                    <div class="choices-labels">
                        <span class="choices-label">Choices (min 2, max 5):</span>
                        <span class="choices-label" v-if="showCorrectAnswerPollSection()">Correct answer (optional):</span>
                    </div>

                    <div class="input-section">
                        <div class="choices-item-container">
                            A&nbsp;
                            <InputText type="text" id='choiceA' class="choice-text-input" v-model="choiceA" placeholder="Enter choice A" :maxlength="choiceMaxLength"/>
                            <span class='required'>*</span>
                        </div>
                        <Checkbox v-if="showCorrectAnswerPollSection()" v-model="correctAnswerPoll" inputId="choice1" name="choice1" :value="pollChoicesCodes.choiceA" />
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            B&nbsp;
                            <InputText type="text" id='choiceB' class="choice-text-input" v-model="choiceB" placeholder="Enter choice B" :maxlength="choiceMaxLength"/>
                            <span class='required'>*</span>
                        </div>
                        <Checkbox v-if="showCorrectAnswerPollSection()" v-model="correctAnswerPoll" inputId="choice2" name="choice2" :value="pollChoicesCodes.choiceB" />
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            C&nbsp;
                            <InputText type="text" id='choiceC' class="choice-text-input" v-model="choiceC" placeholder="Enter choice C (optional)" :maxlength="choiceMaxLength"/>
                        </div>
                        <Checkbox v-if="showCorrectAnswerPollSection()" v-model="correctAnswerPoll" inputId="choice3" name="choice3" :value="pollChoicesCodes.choiceC" />
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            D&nbsp;
                            <InputText type="text" id='choiceD' class="choice-text-input" v-model="choiceD" placeholder="Enter choice D (optional)" :maxlength="choiceMaxLength"/>
                        </div>
                        <Checkbox v-if="showCorrectAnswerPollSection()" v-model="correctAnswerPoll" inputId="choice4" name="choice4" :value="pollChoicesCodes.choiceD" />
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            E&nbsp;
                            <InputText type="text" id='choiceE' class="choice-text-input" v-model="choiceE" placeholder="Enter choice E (optional)" :maxlength="choiceMaxLength"/>
                        </div>
                        <Checkbox v-if="showCorrectAnswerPollSection()" v-model="correctAnswerPoll" inputId="choice5" name="choice5" :value="pollChoicesCodes.choiceE" />
                    </div>
                </div>

                <div class="choices-section" v-if="showRewardSection()">
                    <div class="choices-label">Answer explanation (optional):</div>
                    
                    <vue-editor id="feedAnswerDetailsEditor" ref="feedAnswerDetailsEditor" v-model="answerDetails" :editorOptions="createFeedEditorSettings('#statusEditorDialog .status-container')"
                                :customModules="editorModules"   
                                :placeholder="answerDetailsPlaceholder" />
                    
                   
                    <div class="choices-label">Show the correct answer to users after they answer.</div>
                    <div class="input-section">
                        <InputSwitch class="input-switch" v-model="showAnswerOnSubmit"/>
                    </div>
                    <div class="choices-labels">
                        <span class="choices-label">Reward amount:</span>
                        <span class="choices-label">Reward currency:</span>
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            <InputNumber id='rewardAmount' class="reward-amount-input" v-model="correctAnswerReward" mode="currency" currency="USD" locale="en-US" placeholder="Enter reward amount" />
                        </div>
                        <SelectButton class="reward-currency-select-button" :unselectable="false" v-model="rewardCurrencyId"
                                :options="currencyOptions" optionLabel="label" optionValue="value" />
                    </div>
                    <div class="choices-labels">
                        <span class="choices-label">Max number of winners (Set to 0 for no limit):</span>
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            <InputNumber id='maxNumberOfWinners' class="reward-amount-input" v-model="maxNumberOfWinners" placeholder="Enter max number of winners" />
                        </div>
                    </div>
                    <span class="choices-label" v-if="correctAnswerReward > 0">{{ pollRewardLabel() }}</span>
                </div>
                
            </template>

            <Button class="post-status-button" @click="submitPost" :disabled="disablePostStatusButton">Post</Button>
        </div>  
    </Dialog>

    <Dialog v-model:visible="displayWait" :modal="true" :closable="false" class='status-posting-progress-modal'  id="status-progress-modal">
        <template #header>
            <div style="font-size: 2rem;margin:auto;">{{ displayWaitMessage }}</div>
        </template>
        <LoadingSpinner :showLoading="displayWait" style="height: 65px;min-height:65px;"/>
    </Dialog>

    

    <ImportImageErrorModal ref='importImageErrorModal'/>
    <ConfirmModal ref="confirmCloseModal" @confirmed="onCloseConfirmed" :headerText="'Unsaved Changes '" confirmButtonLabel="Yes">
      <template #content>
        <div class="conformation-container">
         <p>You have unsaved changes. Are you sure you want to close the editor? </p>
        
        </div>
      </template>
    </ConfirmModal>
</template>

<script>

import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';



import { VueEditor, Quill} from "vue3-editor";
import { createFeedEditorSettings, feedEditorModules, gatherMentionsAndMedia, QuillAutoSaveHandler} from "../editor/config.js";

import AdService from '../../service/AdService';
import GroupService from '../../service/GroupService';
import FeedService from '../../service/FeedService';
import ResearchService from '../../service/ResearchService';


//import PostUtils from '../../utilities/PostUtils';
import StringUtils from '../../utilities/StringUtils';
import FormattingUtils from '../../utilities/FormattingUtils';
import UserUtils from '../../utilities/UserUtils';

import EventBus from '../../event-bus';
import useVuelidate from '@vuelidate/core'
import { maxValue } from '@vuelidate/validators';


import LoadingSpinner from '../common/LoadingSpinner.vue';

import {YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL, CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE, GROUP_CONTEST_CATEGORIES} from '../../common/constants';
import { createSummary } from '../notes';
import {correctAnswerYesNoOptionIds, currencyOptions, correctAnswerYesNoOptions, difficultyLevelOptions, 
  pollCategoryOptions, pollChoicesCodes} from './';

import ImageFileInput from '../fileInput/ImageFileInput';
import ImportImageErrorModal from '../errors/ImportImageErrorModal';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import {GroupPlanUtils} from '../groups/';
import ConfirmModal from '../modal/ConfirmModal.vue';
import { hasPremiumGroupSubscription} from '../groups';

const domParser = new DOMParser();
export default {
  name: 'StatusEditorDialog',
    props: {
        selectedContest: {
            type: Object,
            required: false,
        },
    },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      currencyOptions,
      correctAnswerYesNoOptions,
      correctAnswerYesNoOptionIds,
      pollChoicesCodes,
      difficultyLevelOptions,
      pollCategoryOptions,

      formatter: new FormattingUtils(),

      display: false,

      

      status: '',
      
      currentStatusLength: 0,
      statusCharLimit: 500,
      adCharLimit: 500,
     // editor: ClassicEditor, //eslint-disable-line
      renderedEditor: null,
      answerDetailsRenderedEditor: null,
      lastSelectedRenderedEditor: null,
      answerDetails: "",

       // quill editor
     // toolbar: feedEditorToolbar,
      createFeedEditorSettings: createFeedEditorSettings,
      editorModules: feedEditorModules,
      




      selectedGroup: null,
      displayWait: false,
      displayWaitMessage: "",
      selectedGroupSubFeed: null,
      isQuestion: false,
      title: null,
      questionType: 'general',
      multiType: 'multipleChoice',
      choiceMaxLength: 200,
      choiceA: null,
      choiceB: null,
      choiceC: null,
      choiceD: null,
      choiceE: null,
      correctAnswerYesNoOptionId: correctAnswerYesNoOptionIds.notApplicable,
      correctAnswerPoll: [],
      rewardCurrencyId: US_CURRENCY_TYPE.ID,
      correctAnswerReward: 0,
      maxNumberOfWinners: 5,
      showAnswerOnSubmit: false,
      forceRead: false,
      sendEmail: false,
      difficultyLevelOptionId: null,
      pollCategoryOptionId: null,
      contestsOptions: [],
      contestOptionId: null,

      addingAd: false,

      partners: [],
      partnersLoading: false,
      selectedPartner: null,

      campaigns: [],
      campaignsLoading: false,
      selectedCampaign: null,

      ads: [],
      adsLoading: false,
      selectedAd: null,
      thumbnailUrl: null,
      thumbnailFile: null,
      thumbnailElementId: 'postThumbnailId',
      thumbnailsEnabled: true,
      postType: null,
      autoSaveHandler: null,
    }
  },

  components: {
    Dialog,
   // GiphyDialog,
    LoadingSpinner,
    RadioButton,
    Checkbox,
    SelectButton,
    InputNumber,
    InputSwitch,
    Dropdown,
    // Editor,
    VueEditor,
    Quill, //eslint-disable-line
    ImageFileInput,
    ImportImageErrorModal,
    Accordion,
    AccordionTab,
    ConfirmModal

  },


  watch: {
    correctAnswerPoll(newValue, oldValue){
        if(oldValue && oldValue[0]){
            let index = this.correctAnswerPoll.indexOf(oldValue[0]);
            if (index !== -1) {
                this.correctAnswerPoll.splice(index, 1);
            }
        }
    },

    // eslint-disable-next-line no-unused-vars
    selectedPartner(newVal, oldVal){
      if(newVal) {
          this.fetchAdCampaigns();
      }
    },

    // eslint-disable-next-line no-unused-vars
    selectedCampaign(newVal, oldVal){
      if(newVal) {
          this.fetchCampaignAds();
      }
    },

    addingAd(newVal) {
      if (!newVal) {
        this.currentStatusLength = 0;
        this.title = null;
        this.resetAdSelections();
      } else {
        this.fetchAdPartners();
      }
    },

    postType(newVal, oldVal) {
      this.title = null;
      if (!newVal) {
        this.addingAd = false;
        this.currentStatusLength = 0;
      }
      else if( oldVal && oldVal == 'status' && newVal == 'note'){
        this.currentStatusLength = 0;
      }

      if(this.autoSaveHandler){
        this.autoSaveHandler.cacheUnsavedTypeChange(newVal);
      }
      
    },

    title(newVal){
      if( this.autoSaveHandler ){
        this.autoSaveHandler.cacheUnsavedTitleChange(newVal);
      }
    },

    status(newVal){
      if( this.autoSaveHandler){
        this.autoSaveHandler.cacheUnsavedContentChange(newVal);
      }
    }

   
  },

  computed: {
    isAdvertiser() {
      return UserUtils.userIsAdvertising();
    },

    isEducationalPost() {
       if (this.selectedGroup?.coursesEnabled && (this.selectedGroup?.educational || hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId) )) {
        return  this.selectedGroupSubFeed?.courseId && this.isGroupOwnerAdminOrMod;
       }
       else {
        return false;
       }
      //return this.selectedGroup?.educational && this.selectedGroupSubFeed?.courseId && this.isGroupOwnerAdminOrMod;
    },

    disablePostStatusButton() {
        if (this.isQuestion) {
            if (this.questionType != 'poll') {
                return (!this.title || this.title === '') ? 'disabled' : null;
            }
            else {
                if ((this.choiceA && this.choiceA.trim() != '') && (this.choiceB && this.choiceB.trim() != '')) {
                    return (!this.title || this.title === '') ? 'disabled' : null;
                }
                else {
                    return 'disabled';
                }
            }
        }
        else {
          if (this.addingAd) {
            return !this.selectedAd || this.v$.$errors.length > 0 || (!this.status || this.status === '') || (!this.title || this.title === '') ? 'disabled' : null; 
          } else {
            
            return this.v$.$errors.length > 0 || (!this.status || this.status === '') ? 'disabled' : null;
          }
        }
    },

    hasNoPartners() {
      return !this.partnersLoading && this.partners.length === 0;
    },

    hasNoCampaigns() {
      return !this.campaignsLoading && this.selectedPartner && this.campaigns.length === 0;
    },

    hasNoAds() {
      return !this.adsLoading && this.selectedCampaign && this.ads.length === 0;
    },

    hasNoPartnersCampaignsNorAds() {

      if (!this.hasNoPartners) {
        if (!this.hasNoCampaigns) {
          return this.hasNoAds;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    goToAdvertisingPageMessage() {
      let message = "";
      const endString = "Click the button below to go to Advertising page and create one.";

      if (!this.hasNoPartners) {
        if (!this.hasNoCampaigns) {
          if (this.hasNoAds) {
            message = "You have no ads. " + endString;
          }
        } else {
          message = "You have no campaigns. " + endString;
        }
      } else {
        message = "You have no ad partners. "  + endString;
      }

      return message;
    },

    isGroupOwnerAdminOrMod() {
      return UserUtils.isUser(this.selectedGroup?.groupOwnerId) 
              || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']) 
              || UserUtils.isGroupMod(this.$store.getters['users/groupRoleList']);
    },

    editorPlaceholder() {
      if(this.isQuestion) {
        return 'Write any further details about your question here...';
      }
      else {
        return 'Share your market insight here...';
      }
    },

    answerDetailsPlaceholder() {
      return 'Write any further details about the correct answer here...';
      
    },

    isNote() {
      return this.postType == 'note';
    },

    isTask() {
      return this.postType == 'task';
    },

    isStatus() {
      return this.postType == 'status';
    }
  },



  methods: {
    //  onTextChange(delta, prevDelta, source) {//eslint-disable-line
    //   if(this.autoSaveHandler ) {
    //     this.autoSaveHandler.cacheUnsavedChanges(delta, prevDelta);
    //   }
     
    //  },

     openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
     selectedImage(importedImage) {
            this.thumbnailFile = importedImage;
            this.thumbnailUrl = importedImage.url;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },
        removeImportedImage() {
            document.getElementById(this.thumbnailElementId).src = ""; // Prevents former image from rendering when uploading new image
            this.thumbnailFile = null;
            this.thumbnailUrl = null;
        },


    open(selectedGroup, selectedGroupSubFeed, isQuestion) {
      this.selectedGroup = selectedGroup;
      this.selectedGroupSubFeed = selectedGroupSubFeed;
      this.isQuestion = isQuestion;
      
      if(this.isQuestion) {
        
 
          if(this.isGroupOwnerAdminOrMod) {
              if (!this.selectedContest) { 
                this.fetchContestsOptions();
              }
          } else {
              this.contestsOptions = [];
          }
      }

      if( this.isEducationalPost ){
        this.postType = 'note';
      }else {
        this.postType = 'status';
      }

      
      
      this.display = true;
     
      this.$nextTick(() => {
        try{
          
          if( !isQuestion ) {
            let getContent = () => {
              if( this.isTask || this.isNote ){
                return this.$refs.feedNoteEditor.quill.root.innerHTML;
              }
              else {
                return this.$refs.feedStatusEditor.quill.root.innerHTML;
              }
            };

            this.autoSaveHandler = new QuillAutoSaveHandler("statusEditorDialog", {getContent: getContent});
          

            if( this.autoSaveHandler.hasUnsavedChanges() ){
              let unsavedChanges = this.autoSaveHandler.getUnsavedChanges();
              this.title = unsavedChanges.title;
              this.status = unsavedChanges.content;
              //this.$refs.feedNoteEditor.quill.root.innerHTML//updateContents(unsavedChanges.changes);
            }
          }
        }catch( error){
          console.log("error attempting restore unsaved changes:", error);
        }
          
      });
       
      


      //console.log("open called with subfeed : ", selectedGroupSubFeed)
    },
    onCloseConfirmed() {
      this.doClose();
    },

    close() {
      
      if(this.autoSaveHandler && this.autoSaveHandler.hasUnsavedChanges()){
        this.$refs.confirmCloseModal.open();
      }
      else {
        this.doClose();
      }
    },


    doClose() {
     
      
      this.display = false;
      this.status = '';
      
      
      this.currentStatusLength = 0;
      this.title = '';
      this.questionType = 'general';
      this.multiType  ='multipleChoice';
      this.choiceA  = null;
      this.choiceB  = null;
      this.choiceC  = null;
      this.choiceD  =null;
      this.choiceE  =null;
      this.correctAnswerYesNoOptionId = correctAnswerYesNoOptionIds.notApplicable;
      this.correctAnswerPoll = [];
      this.rewardCurrencyId = US_CURRENCY_TYPE.ID;
      this.correctAnswerReward = 0;
      this.maxNumberOfWinners = 5;
      this.showAnswerOnSubmit = false;
      this.forceRead = false;
      this.sendEmail = false;
      this.answerDetails = '';
      this.thumbnailUrl = null;
      this.thumbnailsEnabled = true;

      this.addingAd = false;
      this.postType = null;
      
      this.resetAdSelections();
      if(this.autoSaveHandler ) {
        this.autoSaveHandler.dispose();
      }
      
      

    },
    refresh() {
      EventBus.emit('refresh-feed');
    },

    resetAdSelections() {
      this.selectedPartner = null;
      this.partnersLoading = false;
      this.partners = [];

      this.selectedCampaign = null;
      this.campaignsLoading = false;
      this.campaigns = [];

      this.selectedAd = null;
      this.adsLoading = false;
      this.ads = [];
    },

    fetchAdPartners() {
      this.partnersLoading = true;
      AdService.getAdPartners().then(resp => {
          this.partnersLoading = false;

          if(resp.data.status == 'success'){
              this.partners = resp.data.partners;
          }
      });
    },

    fetchAdCampaigns() {
      this.campaignsLoading = true;
      this.campaigns = [];
      this.selectedCampaign = null;
      AdService.getAdCampaigns(this.selectedPartner.partnerId).then(resp => {
          this.campaignsLoading = false;
          if(resp.data.status == 'success'){
              this.campaigns = resp.data.campaigns;
          }
      });
    },

    fetchCampaignAds() {
      this.adsLoading = true;
      this.ads = [];
      this.selectedAd = null;
      AdService.getCampaignAds(this.selectedCampaign.campaignId).then(resp => {
          this.adsLoading = false;
          if(resp.data.status == 'success'){
              this.ads = resp.data.ads;
          }
      });
    },

    goToAdvertising() {
      this.$router.push('/advertising');
    },

    fetchContestsOptions(){
        GroupService.getGroupContests(this.selectedGroup ? this.selectedGroup.groupId : 1).then(resp => {
            this.contestsLoading = false;
            if (resp.data.status == "success") {
                this.contestsOptions = [];

                resp.data.contests.forEach(contest => {
                  if(contest.categoryId == GROUP_CONTEST_CATEGORIES.quiz){// only quiz contests should be selectable from drop down
                        this.contestsOptions.push({
                            label: contest.name,
                            id: contest.contestId
                        });
                    }

                    if( this.selectedGroupSubFeed && this.selectedGroupSubFeed.groupFeedId == contest.groupFeedId ){
                      //console.log('yepppp');
                      this.contestOptionId = contest.contestId;
                    }
                });

                //console.log("getGroupContests", resp,  this.contestsOptions);
            }
        });
    },

    pollRewardLabel(){
        if(this.maxNumberOfWinners == 0){
            return `Users to answer correctly will be paid 
                ${this.formatter.format(this.correctAnswerReward, '$0.00')} in 
                ${this.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough.`;
        } 
        
        return `First ${this.maxNumberOfWinners} ${this.maxNumberOfWinners == 1 ? 'user' : 'users'} to answer correctly will be paid 
            ${this.formatter.format(this.correctAnswerReward, '$0.00')} in 
            ${this.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough. 
            (Total amount: ${this.formatter.format(this.maxNumberOfWinners*this.correctAnswerReward, '$0.00')} 
            ${this.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough)`;
    },

    showCorrectAnswerYesNoSection(){
        return this.isQuestion && this.questionType == 'yesNo';    
    },

    showChoicesPollSection(){
        return this.isQuestion && this.questionType == 'poll';    
    },

    showCorrectAnswerPollSection(){
        return this.showChoicesPollSection() && this.multiType == "multipleChoice";    
    },

    showEmailSection(){
     
        return this.isQuestion && this.questionType != 'general' && this.isPollEmailsEnabled()
            && this.canSendGroupEmails();
    },

    canSendGroupEmails() {
      
      if( this.isInvrsAdminOrMod() ){
       
        return true;
      }
      else if( this.isGroupOwnerAdminOrMod ) {
       
        return GroupPlanUtils.isINVRSGroup(this.selectedGroup?.groupId) || this.isEducationalGroup() || GroupPlanUtils.hasPremiumGroupPlan(this.selectedGroup);
      }
      else {
        return false;
      }
    },

   

    isEducationalGroup() {
      return this.selectedGroup?.educational;
    },

    isInvrsAdminOrMod() {
      return UserUtils.userIsAdmin() || UserUtils.userIsFeedModerator();
    },

    showDifficultyLevelSection(){
        return this.isQuestion && this.questionType != 'general' && this.isGroupOwnerAdminOrMod;
    },

    showCategorySection(){
        return this.isQuestion && this.questionType != 'general' && this.isGroupOwnerAdminOrMod;
    },

    showContestSection(){
        return !this.selectedContest && this.isQuestion && this.questionType != 'general' && !this.selectedGroup?.educational && (!this.selectedGroupSubFeed || this.selectedGroupSubFeed?.groupFeedId == 0) && this.isGroupOwnerAdminOrMod;
    },

    showForceReadSection(){
        return this.questionType == 'yesNo' || this.questionType == 'poll';
    },

    showRewardSection(){
        return (this.showCorrectAnswerPollSection() && this.correctAnswerPoll?.length > 0) 
        || (this.isQuestion && this.questionType == 'yesNo' && this.correctAnswerYesNoOptionId != correctAnswerYesNoOptionIds.notApplicable);
    },

    isPollEmailsEnabled(){
        return this.$store.state.featureSettings.pollEmailsEnabled;
    },
   
    watchStatusLength() {
      let currentText = this.status;
      currentText = currentText.replace(/&nbsp;/g, " "); // Looks for all non-breaking space codes (Especially when you press enter), replaces them with actual single spaces
      // for future reference the reg expression below doesn't work in safar and breaks everything as safari doesn't yet support 
      // lookahead and lookbehind regular expressions.
      //const matches  = currentText.match(?:<a .+>)([^<>]+)(?=<\/a>)/g); // For mentions or links, gets the raw text in those 'a' tags

      let dom = domParser.parseFromString(currentText,"text/xml");
      const matches = dom.getElementsByTagName("a");

      this.currentStatusLength = StringUtils.stripHtml(currentText).length; // Strip entire html from 'status'

      if (matches && matches.length > 0) {
        // Substract the length of each raw text in a mention or link (In html form)

        for(let i = 0; i<matches.length; i++){
          this.currentStatusLength -= matches[i].textContent.length//match.length;
        }

        // We want to treat mentions or links as 1 char, so we simply add each match we find
        this.currentStatusLength += matches.length;
      }
      
      const charLimit = !this.addingAd ? this.statusCharLimit : this.adCharLimit;

      if (this.currentStatusLength > charLimit) {
        this.v$.currentStatusLength.$touch();
      } else {
        this.v$.currentStatusLength.$reset();
      }

      // if( !this.isQuestion && this.autoSaveHandler){
      //   this.autoSaveHandler.cacheUnsavedChanges( delta, prevDelta);
      // }
    },

    async submitPost() {
      
      
      this.displayWait = true;
      let mentionsAndMedia = null;
      try {
          if( this.isQuestion ) {
            this.displayWaitMessage = "Posting Question...";
            mentionsAndMedia = gatherMentionsAndMedia(this.$refs.feedQuestionEditor);
          }
          else if( this.isNote ) {
            if( this.isEducationalPost ) {
              this.displayWaitMessage = "Posting Lesson...";
            }
            else {
              this.displayWaitMessage = "Posting Note...";
            }
              mentionsAndMedia = gatherMentionsAndMedia(this.$refs.feedNoteEditor);
          }
          else if( this.isTask ) {
              this.displayWaitMessage = "Posting Task...";
              mentionsAndMedia = gatherMentionsAndMedia(this.$refs.feedNoteEditor);
          }
          else if(this.addingAd){
            this.displayWaitMessage =  "Posting Ad...";
            mentionsAndMedia = gatherMentionsAndMedia(this.$refs.feedAdEditor);
          }
          else {
            this.displayWaitMessage =  "Posting status...";
            mentionsAndMedia = gatherMentionsAndMedia(this.$refs.feedStatusEditor);
          }
      }catch( error) {
        console.log("errro getting editor contents ", error);
      }
      

      let post = { 
          contestId: this.selectedContest?.contestId ? this.selectedContest.contestId : this.contestOptionId, 
          content: this.status, 
          mentions: mentionsAndMedia.mentions, 
          mediaUrls: mentionsAndMedia.mediaUrls, 
          groupId:(this.selectedGroup ? this.selectedGroup.groupId : null) ,
          groupSubFeedId: (this.selectedGroupSubFeed && this.selectedGroupSubFeed.groupFeedId != 0 ? this.selectedGroupSubFeed.groupFeedId : null),
          thumbnailUrl: this.thumbnailUrl,
          thumbnailsEnabled : this.thumbnailsEnabled,
          task: false,
      }
      if( this.isTask) {
        post.task = true;
      }
      

      if( this.isQuestion) {
          post['title'] = this.title.trim();

          if (this.questionType != 'general') {

              let poll = {
                  pollTypeId: 0,
                  pollOptions: [],
                  yesIsCorrect: null,
                  correctAnswerReward: null,
                  maxNumberOfWinners: null,
                  rewardCurrencyId: null,
                  showAnswerOnSubmit: null,
                  forceRead: null,
                  sendEmail: null,
                  answerDetails: null,
                  contestId: null,
                  categoryId: null,
                  difficultyId: null,
              }

              poll.forceRead = this.forceRead;
              poll.sendEmail = this.sendEmail;
              poll.contestId = post.contestId;
              poll.categoryId = this.pollCategoryOptionId;
              poll.difficultyId = this.difficultyLevelOptionId;
              
              let correctAnswerExists = false;

              if (this.questionType == YESNO_POLL.name) {
                  poll.pollTypeId = YESNO_POLL.pollTypeId;

                  let yesIsCorrect = this.correctAnswerYesNoOptionId !== correctAnswerYesNoOptionIds.notApplicable
                      ? this.correctAnswerYesNoOptionId === correctAnswerYesNoOptionIds.yes : null;
                  correctAnswerExists = correctAnswerExists ? correctAnswerExists : yesIsCorrect != null;

                  poll.yesIsCorrect = yesIsCorrect;
              }
              else {
                  if (this.multiType == MULTIPLE_CHOICE_POLL.name) {
                      poll.pollTypeId = MULTIPLE_CHOICE_POLL.pollTypeId;
                  }
                  else {
                      poll.pollTypeId = MULTIPLE_SELECT_POLL.pollTypeId;
                  }

                  const choices = [
                      {
                          code: this.pollChoicesCodes.choiceA,
                          title: this.choiceA?.trim()
                      },
                      {
                          code: this.pollChoicesCodes.choiceB,
                          title: this.choiceB?.trim()
                      },
                      {
                          code: this.pollChoicesCodes.choiceC,
                          title: this.choiceC?.trim()
                      },
                      {
                          code: this.pollChoicesCodes.choiceD,
                          title: this.choiceD?.trim()
                      },
                      {
                          code: this.pollChoicesCodes.choiceE,
                          title: this.choiceE?.trim()
                      }
                  ];

                  choices.forEach(choice => {
                      if (choice.title && choice.title != '') {
                          let answerIsCorrect;

                          if (this.correctAnswerPoll[0]) {
                              answerIsCorrect = this.correctAnswerPoll[0] === choice.code;
                              correctAnswerExists = correctAnswerExists ? correctAnswerExists : answerIsCorrect == true;
                          }

                          poll.pollOptions.push({
                              title: choice.title,
                              code: choice.code,
                              answerIsCorrect: answerIsCorrect
                          });
                      }
                  });

              }

              if (correctAnswerExists) {
                  poll.showAnswerOnSubmit = this.showAnswerOnSubmit;
                  poll.answerDetails = this.answerDetails;
                  poll.maxNumberOfWinners = this.maxNumberOfWinners;

                  if (this.correctAnswerReward > 0) {
                      poll.correctAnswerReward = this.correctAnswerReward;
                      poll.rewardCurrencyId = this.rewardCurrencyId;
                  }
              }

              post['poll'] = poll;

              
          }

         
          await FeedService.postQuestion(post).then((resp) => {
             
            this.displayWait = false;
            this.status = '';
            if (resp.data.status == 'success') {
              this.$toast.add({ severity: 'success', summary: 'Question Posted', life: 1500, group: 'center' });
              try{
                this.refresh();
                this.display = false;

                if (this.selectedGroup) {
                  EventBus.emit('track-question', "group");
                } else {
                  EventBus.emit('track-question', "feed");
                }
              }catch( error) {
                console.log("error doing refresh or event handling after posting question "+ error);
              }
            }
            else if( resp.data.message == 'Posting disabled'){
              this.$toast.add({ severity: 'error', summary: 'Posts are currently disabled for this group feed.', life: 3000, group: 'center' });
              
            }
            else {
              this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
              console.log("unexpected error posting status "+resp.data.message);
            }
        }).catch(error => {
            this.displayWait = false;
            this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
            console.log("unexpected error posting status "+error);
        });
      }
      else if(this.isNote || this.isTask) {
          
          this.postNote(post);
      }
      else {
         

          if (this.selectedAd) {
            post['title'] = this.title.trim();
            post['campaignAdId'] = this.selectedAd.campaignAdId;
          }

          this.postStatus(post);
      }
        
    },

    postStatus(post) {
        FeedService.postStatus(post).then((resp) => {
            
            this.displayWait = false;
           
            if (resp.data.status == 'success') {
                this.$toast.add({ severity: 'success', summary: 'Status Posted', life: 1500, group: 'center' });
                this.refresh();
                

                if (this.selectedGroup) {
                    EventBus.emit('track-post', "group");
                } else {
                    EventBus.emit('track-post', "feed");
                }
                this.autoSaveHandler.clearUnsavedChanges();
                this.doClose();
            }
            else if (resp.data.message == 'Posting disabled') {
                this.$toast.add({ severity: 'error', summary: 'Posts are currently disabled for this group feed.', life: 3000, group: 'center' });
                console.log("Posting disabled");
            }
            else {
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
                console.log("unexpected error posting status " + resp.data.message);
            }
        }).catch(error => {
            this.displayWait = false;
            this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
            console.log("unexpected error posting status " + error);
        });
    },

    postNote(post) {

        if( !this.title || this.title.trim() == ''){
          this.displayWait = false;
           this.$toast.add({ severity: 'error', summary: 'Missing Title.', detail:'You need to specify a title.', life: 5000, group: 'tc' });
           return;
        }
        const postSavedNote = (noteId) => {
            const notePosting = {
                noteId: noteId,
                contestId: post?.contestId,
                mentions: post?.mentions,
                mediaUrls: post?.mediaUrls,
                postToMainFeed: false,
                groupId: post?.groupId,
                groupSubFeedId: post?.groupSubFeedId,
                thumbnailUrl : post?.thumbnailUrl,
                thumbnailsEnabled : post?.thumbnailsEnabled,
                task: post?.task,
            }

            this.displayWait = true;
            let toastMsg = "Note Posted";
            if( this.isEducationalPost ) {
              if( this.isTask ) {
                toastMsg = "Task Posted";
              }
              else {
                toastMsg = "Lesson Posted";
              }
             
            }
           

            FeedService.postNote(notePosting).then((resp) => {
                this.displayWait = false;
                
                if (resp.data.status == "success") {
                    this.refresh();
                    this.$toast.add({ severity: 'success', summary: toastMsg, life: 2500, group: 'center' });
                   
                    this.autoSaveHandler.clearUnsavedChanges();
                    this.doClose();
                    
                } else if (resp.data.message == 'Posting disabled') {
                    this.$toast.add({ severity: 'error', summary: 'Posts are currently disabled for one or more of the selected locations.', life: 3000, group: 'center' });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
                }
            });
        }

        const note = {
            analystId: this.$store.state.users.user.analyst.analystId,
            title: this.title.trim(),
            summary: createSummary(post.content),
            content: post.content,
        };

        ResearchService.saveNote(note).then((resp) => {
            if (resp.data.status == "success") {
                //console.log("ResearchService.saveNote", resp);
                postSavedNote(resp.data.noteId);
            } else if (resp.data.message == "Invalid Name") {
                this.displayWait = false;
                this.$toast.add({ severity: 'error', summary: 'Invalid Title.  Please change your note title.  Only ascii printable characters allowed.', detail: '', life: 3000, group: 'center' });
            } else {
                this.displayWait = false;
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 1500, group: 'center' });
            }
        });
    },

  },
  validations() {
      return {
        currentStatusLength: {
          maxValue: maxValue(this.statusCharLimit)
        }
      }
    }
}
</script>
<style scoped>

::v-deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {

  box-shadow: none;
}

::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  font-weight: normal;
}

[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
    text-align: center;
    width: fit-content;
}
[class*='image-preview'] img {
    /* width: 100%; */
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    /* width: 250px; */
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}



.adding-ad-checkbox-container {
  text-align: left;
  margin-bottom: 12px;
}
.adding-ad-checkbox-container label {
  margin-left: 8px;
}
.required{
  color:red;
}
.choice-text-input {
  width: 65%;
}
.question-label{
  text-align:left;
  margin-bottom: 1rem;
}

.choices-label{
  text-align:left;
  margin-bottom: 1rem;
  margin-top:1rem;
}
.gap-2 {
    gap: 1rem!important;
    margin-left: 1rem;
}

.gap-3 {
    gap: 1rem!important;
    margin-bottom: 25px;
    margin-left: 1rem;
    
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap!important;
}
.flex {
    display: flex!important;
}
.ml-2{
    margin-left: 0.5rem!important;
}

.choices-section{
  text-align: left;
  margin-top: 5px;
  display: flex;
  flex-direction: column; 
}

.choices-section .p-dropdown {
    width: 13rem;
}

.contest-dropdown .p-dropdown {
    width: unset !important;
    min-width: 13rem !important;
}

.choices-labels{
  display: flex;
  justify-content: space-between;
}

.input-section {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 5px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choices-item-container {
  flex-grow: 1;
}
.required {
  margin-left: 2px;
}

::v-deep(.reward-amount-input input) {
    width: 100%;
}
.reward-currency-select-button {
    margin-left: auto;
    width: fit-content;
}
::v-deep(.reward-currency-select-button .p-button) {
    font-size: 0.9rem !important;
    padding: 4px 6px 4px 6px;
}
::v-deep(.reward-currency-select-button .p-button:focus) {
    box-shadow: none;
}

/* ADVERTISING STYLING */
.advertising-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

::v-deep(.adding-ad-checkbox-container .p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #33CC99;
}
::v-deep(.adding-ad-checkbox-container):not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #00C083;;
    background: #00C083;
}
::v-deep(.adding-ad-checkbox-container):not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #00C083;
    box-shadow: none;
}

.advertising-section > * {
  margin-bottom: 12px;
}
.advertising-section > *:last-child {
  margin-bottom: 0;
}

.advertising-section > .p-dropdown {
  margin-bottom: 8px;
  width: 100%;
}
.advertising-section > .p-dropdown:last-child {
  margin-bottom: 0;
}

.advertising-section .go-to-advertising-section {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.advertising-section .go-to-advertising-section .go-to-advertising-message {
  color: black;
  font-size: 12px;
  margin-bottom: 4px;
}

.advertising-section .go-to-advertising-section .go-to-advertising-button {
  border-radius: 50px;
}
</style>

<style>

.ap {
  margin-top: 0 !important;
}
.ql-editor {
      min-height: 25rem;
      max-height: 35rem;
}
.ql-snow .ql-toolbar button svg, .quillWrapper .ql-snow.ql-toolbar button svg {
    width: 18px !important;
    height: 18px !important;
}
.quillWrapper .ql-snow.ql-toolbar {
  text-align: left;
  border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ql-container.ql-snow {
 border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin-bottom:0 !important;
  margin-right: 0;
}
.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  padding: 0 !important;
}


.input-modal {
  font-family: 'Trebuchet MS', 'Verdana';
  border-radius: 16px;
  width: 650px;
}

.input-modal .p-dialog-header {
  color: #32364e;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 15px 25px 10px;
}
.input-modal .p-dialog-header .p-dialog-title {
  font-size: 26px;
  font-weight: bold;
}

.input-modal .p-dialog-content {
  text-align: center;
  padding: 0px 20px 15px;
  border-top: 1px solid #F2F2F2;
  /* overflow: hidden; */
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.ck.ck-toolbar {
  background: #F2F4FA;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ck.ck-content {
  font-size: 16px;
  color: black;
  padding: 0 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  min-height: 200px;
  max-height: 22rem;
}

.title-span {

  margin-top: 5px;
}
.status-container {
  margin-top: 10px;
}

.status-container .title-input {
    width: 100%;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.status-container .title-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.status-container.input-error .ck-editor__editable_inline.ck-focused,
.status-container.input-error .ck.ck-content,
.status-container.input-error .ck.ck-toolbar {
  border-color: #E63E3E !important;
}

.ck.ck-reset_all, .ck.ck-reset_all * {
  font-family: 'Trebuchet MS', 'Verdana' !important;
}



.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  margin-left: 15px;
}

.ck.ck-content a:not([class*='mention']) {
  color: #5367FF;
  font-weight: bold;
}

.ck.ck-mentions>.ck.ck-list__item .ck-button.header {
  padding: 5px 10px 5px;
  pointer-events: none;
}

/* quill */


.ql-container{
  font-family: 'Trebuchet MS', 'Verdana' !important;
}

.ql-mention-list-container {
  /* position:relative !important; */
  

      overflow-y: auto !important;
    overflow-x: hidden !important;
    overscroll-behavior: contain !important;
    max-height: 200px !important;
    display: flex !important;
    flex-direction: column !important;

    /* box-shadow: var(--ck-drop-shadow),0 0; */
    min-height: 15px;
    /* background: var(--ck-color-panel-background); */
    /* border: 1px solid var(--ck-color-panel-border); */
}

.ql-mention-list {
  border-collapse: collapse;
    
    text-align: left;
    white-space: nowrap;
    cursor: auto;
    float: none;

    margin: 0;
    padding: 0;
    border: 0;
    /* background: transparent; */
    text-decoration: none;
    vertical-align: middle;
    transition: none;
    word-wrap: break-word;
}

.ql-mention-list-item {
  overflow: hidden;
    flex-shrink: 0;
    cursor:default;
}

.ql-mention-list-item .item-header {
  font-weight: bold;
  font-size: 18px;
   padding: 5px 10px 5px;
  pointer-events: none;
}
.ql-mention-list-item .custom-item-security {
  padding: 13px 10px 10px;
}

.ql-mention-list-item .custom-item-tag {
  padding: 13px 10px 10px;
}

.ql-mention-list-item .custom-item-analyst {
  padding: 13px 10px 10px;
  padding: 10px 10px;
} 

.ql-mention-list-item .custom-item-analyst >span {
  display: block;
}
.ql-mention-list-item .custom-item-security >span {
  display: block;
}
.ql-mention-list-item .custom-item-tag >span {
  display: block;
}
.ql-mention-list-item .item-row {
  display: flex;
  align-items: center;
}
.ql-mention-list-item .item-symbol {
   font-size: 14px;
  color: black;
  line-height: 0.8;
}

.ql-mention-list-item .item-name {
  font-size: 11px;
  color: #32364e;
}


.ql-mention-list-item .item-row .rounded-image {
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #32364e;
  margin-right: 10px;
}

.ql-mention-list-item .item-row .rounded-image .profile-image {
  display: inline;
  height: 100%;
  width: auto;
}

.ql-mention-list-item .item-row .rounded-image {
  border-color: #FFFFFF;
}

.ql-mention-list-item .item-row .names-container {
  display: flex;
  flex-direction: column;
}
.ql-mention-list-item .item-row .names-container .full-name {
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
  line-height: 1.2;
}
.ql-mention-list-item .item-row .names-container .username {
  color: #a6a6a6;
  line-height: 1.2;
}
.ql-mention-list-item .item-row .names-container > span,
.ql-mention-list-item .item-row:hover > span:not(.item-header) {
  color: #ddd;
}
.ql-mention-list-item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.ql-mention-list-item:hover {
  background-color: #b5f3e0;
}

.security-mention {
  color: #33CC99 !important;
  text-decoration: none !important;
  font-weight: bold;
}
.analyst-mention {
  color: #693BF5 !important;
  text-decoration: none !important;
  font-weight: bold;
}

.tag-mention {
  color: #33CC99  !important;
  text-decoration: none !important;
  font-weight: bold;
}


.ck.ck-mentions>.ck.ck-list__item .ck-button.header .item-header {
  font-weight: bold;
  font-size: 18px;
}
.ck.ck-mentions>.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: #32364e;
}
.ck.ck-mentions>.ck.ck-list__item .ck-button.header:hover,
.ck.ck-mentions>.ck-list__item:hover,
.ck.ck-mentions>.ck-list__item .ck-button.ck-on {
  background: none;
}
.ck.ck-mentions>.ck-list__item .custom-item-security.ck-button {
  padding: 13px 10px 10px;
}

.ck.ck-mentions>.ck-list__item .custom-item-tag.ck-button {
  padding: 13px 10px 10px;
}

.ck.ck-mentions>.ck-list__item .custom-item-analyst.ck-button {
  padding: 10px 10px;
}
.ck.ck-mentions>.ck-list__item .ck-button > span {
  display: block;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-symbol {
  font-size: 14px;
  color: black;
  line-height: 0.8;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-name {
  font-size: 11px;
  color: #32364e;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row {
  display: flex;
  align-items: center;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .rounded-image {
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #32364e;
  margin-right: 10px;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .rounded-image .profile-image {
  display: inline;
  height: 100%;
  width: auto;
}
.ck.ck-mentions>.ck-list__item:hover .ck-button .item-row .rounded-image {
  border-color: #FFFFFF;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container {
  display: flex;
  flex-direction: column;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container .full-name {
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
  line-height: 1.2;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container .username {
  color: #a6a6a6;
  line-height: 1.2;
}
.ck.ck-mentions>.ck-list__item:hover .ck-button .item-row .names-container > span,
.ck.ck-mentions>.ck-list__item:hover .ck-button > span:not(.item-header) {
  color: #FFFFFF;
}
.ck.ck-mentions>.ck-list__item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.ck-content [class*='mention'] {
    background: none;
    font-weight: bold;
}
.ck-content .security-mention {
  color: #33CC99;
}
.ck-content .analyst-mention {
  color: #693BF5 ;
}

.ck-content .tag-mention {
  color: #33CC99 ;
}


.ck-editor__editable_inline.ck-focused {
    border-color: #33CC99 !important;
}

@media (max-width: 760px) {
 /* .p-dialog {
    height: 100vh;
  } */
  .input-modal {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    max-height: 100vh !important;
  }
  .input-modal .p-dialog-header {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }  

  .input-modal .p-dialog-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .p-dialog-content {
    padding-top: 0 !important;
    height: 100%;
  }
  .ck.ck-toolbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .ck.ck-content {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }  
  .post-status-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ql-mention-list-container {
    max-width: 90%;
  }
}

/** the following is need to avoid the mentions from showing up behind the dialog as chat and status conflict */
.ck.ck-balloon-panel.ck-balloon-panel_caret_se.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_sw.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_ne.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_nw.ck-balloon-panel_visible {
     z-index: 9000;
}
</style>

<style >
#status-progress-modal.status-posting-progress-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 350px;
    height: 150px;
	box-shadow: none;
}

#status-progress-modal.status-posting-progress-modal .p-dialog-header {
    padding: 20px 10px 10px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

#status-progress-modal.status-posting-progress-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

#status-progress-modal.status-posting-progress-modal .logo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

#status-progress-modal.status-posting-progress-modal .p-dialog-content {
	padding: 10px 25px !important;
    height: 100% !important;
    border-bottom-left-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
    text-align: center;
}

#status-progress-modal.status-posting-progress-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>

<style scoped>
*, ::v-deep(.p-component) {
  font-family: 'Trebuchet MS', 'Verdana';
}

/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
} 

.input-modal .p-dialog-content .status-container {
  display: flex;
  flex-direction: column; 
}

.input-modal .post-status-button {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  border-radius: 30px;
  color: #FFFFFF;
  background: #33CC99;
  border: 1px solid #33CC99;
  margin-top: 15px;
  padding: 12px 64px;
}
.input-modal .post-status-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.input-modal .post-status-button:focus {
  box-shadow: none;
  border-color: #33CC99;
}

::v-deep(.embedly-card) {
    max-height: 680px;
   
    }

::v-deep(.embedly-card-hug) {
     transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    
  }

 ::v-deep(.insta-iframe) {
     height:760px;
      transform-origin: top center;
     -webkit-transform:scale(0.7);
     -moz-transform-scale:scale(0.7);
 }

 ::v-deep(.insta-blockquote){
     max-height: 530px;
     
 }
</style>