<template>
    <div class='chat-messenger-container'>
        <div class='chat-editor-bar'>
            <div :class="{'chat-editor-container':true, 'input-error': v$.currentMessageLength.$error}" aria:haspopup="true" aria-controls="overlay_panel" >
                
                <vue-editor id="chatEditor" ref="chatEditor" v-model="message" :editorOptions="createChatEditorSettings('.chat-editor-container', this.onEnterKey)"  
                                :customModules="editorModules" 
                                placeholder="Add a message..."
                                @text-change="watchMessage"/>
            </div>
            <Button icon='pi pi-send' class="p-button-raised send-message-button" @click='onSendMessageButtonClick' :disabled='disableSendButton' v-if='isMember' />
            <Button label='Join' class="p-button-raised send-message-button" @click='onJoinChannelButtonClick' v-else />  
        </div>
        <div class='extra-tools-bar'>
            <span class='message-character-counter' :style="{marginLeft: 'auto', color: v$.currentMessageLength.$error ? '#E63E3E': null}">{{currentMessageLength}} / {{messageCharLimit}}</span>
        </div>
    </div>
    
</template>

<script>
import { VueEditor, Quill } from "vue3-editor";
import { createChatEditorSettings, chatEditorModules, gatherMentionsAndMedia} from "../editor/config.js";


import EventBus from '../../event-bus';
import useVuelidate from '@vuelidate/core'
import { maxValue } from '@vuelidate/validators';


export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'ChatEditor',
    emits: ['join-channel', 'on-send'],
    props: {
        placeholderText: {
            type: String
        },
        isMember: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            message: '',
           
            isActive: false,
            renderedEditor: null,
            ro: null,
            chatEditorContainerEl: null,
            currentMessageLength: 0,
            messageCharLimit: 2000,
            inputEl: null,
            createChatEditorSettings: createChatEditorSettings,
            editorModules: chatEditorModules,
           
        }
    },
    computed: {
        disableSendButton() {
            return this.v$.$errors.length > 0 || (!this.message || this.message === '') ? 'disabled' : null;
        },
    },
    components: {
        VueEditor, 
        Quill //eslint-disable-line
    },

    mounted() {
        try {
            
            this.chatEditorContainerEl = document.getElementsByClassName('chat-messenger-container')[0];
            if(this.chatEditorContainerEl ) {
                this.ro = new ResizeObserver(this.onResize).observe(this.chatEditorContainerEl);
            }
        }catch(err) {
            console.warn("error mounting chat editor: " +err);
        }

    },
    unmounted() {
        if (this.ro) {
            this.ro.unobserve(this.chatEditorContainerEl);
        }
    },

    methods: {
        watchMessage() {
            let currentMessage = this.message;
            // currentMessage = currentMessage.replace(/&nbsp;/g, " "); // Looks for all non-breaking space codes (Especially when you press enter), replaces them with actual single spaces
            // for future reference the reg expression below doesn't work in safar and breaks everything as safari doesn't yet support 
            // lookahead and lookbehind regular expressions.

            // let dom = domParser.parseFromString(currentMessage,"text/xml");
            // const matches = dom.getElementsByTagName("a");

            this.currentMessageLength = currentMessage.length; // Strip entire html from 'status'

          

            if (this.currentMessageLength > this.messageCharLimit) {
                this.v$.currentMessageLength.$touch();
            } else {
                this.v$.currentMessageLength.$reset();
            }

        },

        onJoinChannelButtonClick() {
            this.$emit('join-channel');
        },
        onSendMessageButtonClick() {
            let msg = this.message;//this.renderedEditor.getData();
            let mentionsAndMedia = gatherMentionsAndMedia(this.$refs.chatEditor);
            
            this.$emit('on-send', { message: { content:msg }, mentions: mentionsAndMedia.mentions, mediaUrls: mentionsAndMedia.mediaUrls });

            this.resetEditor();
                
            
        },

        onResize() {
            EventBus.emit('get-container-height', this.chatEditorContainerEl.clientHeight);
        },

        onEnterKey() {
            if(!this.disableSendButton){
                this.onSendMessageButtonClick();
            }
        },

        

        resetEditor() {
           
            this.isActive = false;
            this.message = '';
            this.$refs.chatEditor.quill.setText('');
            this.currentMessageLength = 0;
        },
    },

    validations() {
      return {
        currentMessageLength: {
          maxValue: maxValue(this.messageCharLimit)
        }
      }
    }
}
</script>

<style >
.ck.ck-balloon-panel.ck-balloon-panel_caret_se.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_sw.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_ne.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_nw.ck-balloon-panel_visible {
     z-index: 9000;
}


</style>

<style scoped>


::v-deep(.ql-toolbar) {

  float:right;
 
  border: none;

}
::v-deep(.ql-editor) {

    padding: 5px 10% 5px 8px;
    min-height: 35px;
    max-height: 80px;
    overflow: auto;
}

::v-deep(.ql-editor::-webkit-scrollbar-track)
{
	
     cursor: pointer;
    
}

::v-deep(.ql-editor::-webkit-scrollbar)
{
	width: 12px;
    cursor: pointer;

}

::v-deep(.ql-editor::-webkit-scrollbar-thumb)
{
	
      cursor: pointer;
}


::v-deep(#emoji-palette){
    top: unset !important;
    position: fixed !important;
    left: unset !important;
    bottom: 30px;
    z-index: 999;
}

::v-deep(.ql-container) {
    margin-right: 16px; 
    padding: 0px 8px; 
    border: 1px solid #BFBFBF !important; 
    /* border-right: none; */
    /* background: #F2F4FA; */
    border-radius: 16px;
    /* border-bottom-right-radius: 0px !important; */
    /* border-right-width: 0px !important; */
    /* border-bottom-left-radius: 0px; */
    width: 100%;
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);  */
    /* position: relative; */
   
   
   /* max-height:80px; */
    overflow: hidden;
}
::v-deep(.quillWrapper) {
    width:100%;
    margin-left: 3px;
    
}

/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
} 

.chat-messenger-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
}

.chat-editor-bar {
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-editor-container {
    padding: 0px 8px; 
    border: none; 
    /* background: #F2F4FA; */
    /* border-radius: 16px; */
    width: 90%;
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);  */
    margin-right: 16px;
}
.chat-editor-container:focus-within {
    border-color:#33CC99;
}
.chat-editor-container.input-error,
.chat-editor-container.input-error:focus-within {
    border-color: #E63E3E;
}
::v-deep(.send-message-button) {
    padding: 8px 16px;
    background: #FFFFFF;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    color: #32364e;
    border: 1px solid #33CC99;
    align-self: flex-end;
}

::v-deep(.send-message-button:hover) {
    border-color: #33CC99;
    color: #FFFFFF;
    background: #33CC99;
}

::v-deep(div[role='toolbar']) {
    padding: 0px;
    background: none;
    border: none;
}
::v-deep(.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items) {
    margin-left: 0px;
}

::v-deep(div[role='application']) {
    width: 100%;
}

::v-deep(div[role='textbox']) {
    min-height: 40px;
    max-height: 250px;
    box-shadow: none;
    border: none;
}

::v-deep(.ck.ck-editor__main > .ck-editor__editable) {
    /* background: #F2F4FA; */
    background: none;
}
::v-deep(.ck.ck-editor__editable_inline) {
    font-size: 16px;
}
::v-deep(.ck.ck-editor__editable_inline>:last-child),
::v-deep(.ck.ck-editor__editable_inline>:first-child) {
    margin: 0px;
}

::v-deep(.ck .ck-placeholder:before),
::v-deep(.ck.ck-placeholder:before) {
    color: #BFBFBF;
}

/* ::v-deep(.mention) {
    color: rgb(51, 204, 153);
} */

::v-deep(.ck-content .security-mention) {
  color: #33CC99;
}
::v-deep(.ck-content .analyst-mention) {
  color: #693BF5;
}

::v-deep(.ck-content .tag-mention) {
  color: #33CC99;
}

::v-deep(.ck.ck-content) {
    /* border-radius: 16px !important;
    padding: 8px 16px; */
    border-radius: 0px !important;
    padding: 8px 16px;
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar) {
	/* width */
	width: 10px;
    
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-track) {
	margin-right: 10px;
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}
::v-deep(.ck-editor__editable_inline.ck-focused) {
    /* border: 2px solid #33CC99 !important; */
    border: none !important;
    box-shadow: none !important;
}

::v-deep(.ck-content .image img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
    
}

::v-deep(.ck-content .image-inline img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
    


}

::v-deep(div.comment-text > figure > img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
}

.extra-tools-bar {
    display: flex;
    width: 90%;
    margin-bottom: 7px;
}
.extra-tools-bar .message-character-counter {
    margin-top: 8px;
}

@media (max-width: 760px) { 
    ::v-deep(.ck.ck-content) {
        padding: 4px;
    }

    ::v-deep(div[role='textbox']) {
        min-height: 32px;
    }

    ::v-deep(#emoji-close-div) {
        pointer-events: none!important;
    }
}

@media (max-width: 1530px) { 
    ::v-deep(.ql-editor) {
    
        padding: 5px 15% 5px 8px;
        
    }
}

@media (max-width: 1185px) { 
    ::v-deep(.ql-editor) {
    
        padding: 5px 35% 5px 8px;
        
    }
}

</style>