<template>
    <Dialog header="Membership Subscriptions" v-model:visible="display" :modal="true" class='group-subs-modal' >
        
         
             <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if="!loading">
               
                <span v-if="sellerAccountStatus == 'noAccount'" class="settings-description">
                    <p>Membership subscriptions allow you to charge a monthly or yearly recurring fee for membership to your group.</p>
                    <p>To enable subscriptions you must first <a href="/index.html#/profile/seller">connect a Stripe account</a> so you can process payments on the INVRS platform.</p>
                    <!-- <p>You haven't connected a Stripe Account yet.</p>   -->
                    <!-- <p>To setup your Stripe account go to the 
                        <a href="/index.html#/profile/seller">payments section of your profile.</a></p> -->
                    
                 
                </span>
                <span v-else-if="sellerAccountStatus == 'chargesDisabled' || sellerAccountStatus == 'payoutsDisabled'" class="settings-description">
                    <p>Membership subscriptions allow you to charge a monthly or yearly fee for membership to your group.</p>
                    <p>To enable subscriptions you must first <a href="/index.html#/profile/seller">complete the Stripe setup</a> so you can process payments on the INVRS platform.</p>
                    <!-- <p>You've started to connect a Stripe Account but you're still missing some info. </p> -->
                    <!-- <p>You'll need to <a href="/index.html#/profile/seller">complete the Stripe setup</a> in order to start charging or receiving payments.</p> -->
                   
                </span>
                
                <span v-else class="settings-description">
                    <div class="settings-section">
            
                    <span class="settings-label">Enable Subscriptions:</span>
                        <InputSwitch v-model="subscriptionRequired" class="group-actions-switch"  />
                    </div>
                    <div class="settings-section" :style="(subscriptionRequired? '' : 'opacity:0.3;pointer-events:none;')" >
                        <h4 class="settings-title">Subscription plans:</h4>
                        <div style="display:flex;flex-direction:column;">
                            
                            <div  style="display:flex;align-items: center;">
                                <span class="settings-label">Monthly Subscription:</span>
                                
                                <InputNumber placeholder="$0.00" v-model="monthlyPrice" mode="currency" :currency="currency" style="margin-left:auto;"/>
                                
                            </div>
                            <div  style="display:flex;align-items: center;">
                                <span class="settings-label">Yearly Subscription:</span>
                            
                                <InputNumber placeholder="$0.00" v-model="yearlyPrice"  mode="currency" :currency="currency" style="margin-left:auto;"/>
                                
                            </div>
                            <div style="display:flex;align-items: center;">
                                <span class="settings-label">Currency:</span>
                            
                                <SelectButton v-model="currency" :options="['USD', 'CAD']" aria-labelledby="basic" style="margin-left:auto;"/>
                            </div>
                            
                        </div>
                    </div>
                </span>
                  <div style="padding: 0px 15px 0px 0px; font-size:12px;">
                    <p><b>Please note:</b> 
                      Group Monetization is primarily intended to support <b>investment education providers and content creators</b>.
                    </p>
                    <p>
                    All paywalled groups and resources are <b>periodically reviewed and monitored</b> to ensure there are no violations of our payment provider's rules and restrictions
                        such as <b>Pyramid Schemes, Multi-Level Marketing, Pump and Dump and other unfair, deceptive or abusive acts or practices</b>.
                    </p>
                    <p>
                        You can see the full list of restricted and prohibited goods and services<a href="https://stripe.com/en-ca/legal/restricted-businesses#prohibited-businesses" target="_blank"> here</a>.
                    </p>
                    </div>

            </div>
        

		<template #footer>
			<Button label="Cancel" class='p-button-raised p-button-rounded view-button' @click="close()"/>
            <Button label="Save" class='p-button-raised p-button-rounded view-button' @click="save()" :disabled="!dirty" :loading="saving"/>
           
		</template>
	</Dialog>
</template>

<script>


import Dialog from 'primevue/dialog';

import PaymentService from '../../../service/PaymentService';


import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import SelectButton from 'primevue/selectbutton';

import LoadingSpinner from '../../common/LoadingSpinner.vue';

// import Textarea from 'primevue/textarea';


export default {
    name: 'GroupSubscriptionsModal',
 
    components: {
        Dialog, InputSwitch, InputNumber, SelectButton,LoadingSpinner,
    },

    
    data() {
		return {
           
			display: false,
            loading: false,
            monthlyPrice: null,
            yearlyPrice: null,
            currency: "USD",
            sellerAccountStatus: 'noAccount',
            groupId: null,
            dirty: false,
            subscriptionRequired: false,
            group: null,
            description: '',
            saving: false,
            extProductId: null,
		}
    },

    watch: {
        subscriptionRequired(newVal, oldVal){
             if( !this.loading && newVal != oldVal){
                this.dirty = true;
            }
        },
        monthlyPrice(newVal, oldVal){
            if( !this.loading && newVal != oldVal){
                this.dirty = true;
            }
        },
        yearlyPrice(newVal, oldVal){
            if( !this.loading && newVal != oldVal){
                this.dirty = true;
            }
        },
        currency(newVal, oldVal){
            if( !this.loading && newVal != oldVal){
                this.dirty = true;
            }
        }


    },

   

    methods: {
        

        open(group) {
            this.display = true;
            this.group = group;
            this.loading = true;
            this.dirty = false;
            this.saving = false;
            this.subscriptionRequired = group.subscriptionRequired;
            this.extProductId = null;
            PaymentService.getSellerAccountStatus().then(resp => {
               
                if( resp.data.status == 'success'){
                    this.sellerAccountStatus = resp.data.sellerAccountStatus;

                    if( this.sellerAccountStatus == 'accountEnabled'){
                        PaymentService.getGroupSubscriptions(this.group.groupId).then( resp => {
                            
                    
                            if( resp.data.status == 'success'){
                                console.log("got group subs", resp.data.subscriptions);
                                if( resp.data.subscriptions && resp.data.subscriptions.length > 0 ){
                                    for( var i =0 ; i< resp.data.subscriptions.length; ++i){
                                        let sub = resp.data.subscriptions[i];
                                        if( sub.periodTypeId == 1){
                                            console.log("setting  yearly price to " + sub.price)
                                            this.yearlyPrice= sub.price;
                                        }
                                        else {
                                            console.log("setting  monthly price to " + sub.price)
                                            this.monthlyPrice = sub.price;
                                        }
                                    }
                                    this.currency = (resp.data.subscriptions[0].currencyId == 1 ? 'CAD' : 'USD');
                                    this.extProductId = resp.data.subscriptions[0].extProductId;
                                    
                                }
                                this.loading = false;
                            }
                            else {
                                this.loading = false;
                            }
                        })
                    }
                    else {
                         this.loading = false;
                    }
                }
                else {
                    this.loading = false;
                    
                }
            });
        },

        close() {
            this.display = false;
            this.loading = false;
            this.monthlyPrice = null;
            this.yearlyPrice = null;
            this.currency = "USD";
            this.dirty = false;
            this.group = null;
            this.saving = false;
            this.extProductId = null;
        },

        

       save() {
            console.log("saved");
            if( this.subscriptionRequired ){
                if( this.monthlyPrice == null || this.monthlyPrice < 2.95 ){
                     this.$toast.add({ severity: 'error', summary: 'Invalid Monthly Subscription Price', detail: "Monthly Subscription Price must be at least $2.95", life: 5000, group: 'center'});
            
                }
                else if( this.yearlyPrice == null || this.yearlyPrice < 29.50 ){
                     this.$toast.add({ severity: 'error', summary: 'Invalid Yearly Subscription Price', detail: "Yearly Subscrption Price must be at least $29.50", life: 5000, group: 'center'});
            
                }
                else {
                    console.log("enabling subs and saving subs")
                    this.saving = true;
                    PaymentService.updateGroupSubscriptions({
                        groupId: this.group.groupId,
                        name: this.group.name,
                        description: "Membership subscription for "+ this.group.name,
                        monthlyPrice: this.monthlyPrice,
                        yearlyPrice: this.yearlyPrice,
                        currencyCode: this.currency,
                        extProductId: this.extProductId
                    }).then( resp => {
                         this.saving = false;
                        if( resp.data.status == "success") {
                            this.$toast.add({ severity: 'success', summary: 'Subscriptions Saved.', detail: "Users will now be required to subcribe in order to join your group.", life: 5000, group: 'center'});
                            this.group.subscriptionRequired = true;
                            this.close();
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Error saving subscriptions', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
            
                        }
                    }).catch(err => {
                        this.saving = false;
                         this.$toast.add({ severity: 'error', summary: 'Error saving subscriptions', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
                        console.log("error updating group subs ", err);
                    })
                }
            }
            else if( this.group.subscriptionRequired && !this.subscriptionRequired){
                console.log("disabling subs");
                this.saving = true;
                PaymentService.disableGroupSubscriptions(this.group.groupId).then(resp => {

                     if( resp.data.status == "success") {
                            this.$toast.add({ severity: 'success', summary: 'Subscriptions Disabled.', detail: "Users will no longer be required to subcribe in order to join your group.", life: 5000, group: 'center'});
                            this.group.subscriptionRequired = false;
                            this.close();
                          
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Error disabling subscriptions', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
            
                        }
                    }).catch(err => {
                        this.saving = false;
                         this.$toast.add({ severity: 'error', summary: 'Error disabling subscriptions', detail: "If the problem persists contact support@invrs.com.", life: 5000, group: 'center'});
                        console.log("error disabling group subs ", err);
                    })
            }
           
       }

       
    }
}
</script>

<style>
.group-subs-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
}


.group-subs-modal .p-dialog-content {
    padding: 0px 18px 0px;
}

.group-subs-modal .p-dialog-footer {
    padding: 34px 0px;
    text-align: center;
}
</style>
<style scoped>
 *:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    width: 400px;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 16px 0px 16px 16px;
    text-align: center;
}

.header-highlight {
    color: #693BF5;
}

.body-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
    margin: 12px 0px 30px;
}

.perks-container {
    text-align: center;
}

.perk-container {
    margin-bottom: 16px;
}
.perk-container i {
    color: #33CC99;
}
.perk-container span {
    color: black;
    font-size: 18px;
}
.perk-container:last-child {
    margin-bottom: 0px;
}

.upgrade-button, .view-button {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 64px;
    margin: 0px;
}

.view-button {
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;
}

.view-button:hover {
    background-color: #32364e;
    border: 1px solid #32364e;
}

.p-button:last-child {
    margin-right: 0 !important;
}
</style>