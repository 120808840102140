<template>
    <div :class='messageClass' @mouseenter="onMouseEnter">
        <template v-if='canViewMessageMenu'>
            <MoreOptionsMenu class='more-options-menu' alignment="vertical" @click='toggleMenu'/>
            <Menu v-if="messageMenuOptions && messageMenuOptions.length > 0" ref='menu' :model="messageMenuOptions" :popup="true" />
        </template>
        <UserAvatar :imageSrc="data.user.avatar" size='sm' v-if='renderAvatar' @click='postUtils.goToAnalystProfile(data.user.name, $event)'/>
        <div class='message-container'>
            <span class='message-owner-name' v-if='renderName' @click='postUtils.goToAnalystProfile(data.user.name, $event)'>{{data.user.name}}</span>
            <div class='message-content-container'>
                <div class="message-outer-content-container" v-if="!browserUtils.isMobile()">
                    <div :id='messageContentId' class='message-content' v-html='data.text'>
                        

                    </div>
                </div>
                <div class="message-outer-content-container" v-touch:tap="openReactionMenu" v-else>
                    <div :id='messageContentId' class='message-content' v-html='data.text'>
                        

                    </div>
                    <ReactionMenu :reactions="messageReactions" @reaction-selected="updateReaction" ref="reactionMenu" v-if="!isMessageAuthor()"/>
                </div>
                
            </div>
            
          <div class="reaction-count-container">
            <span class='reaction-count' v-for='key in Object.keys(reactionCounts)' :key='key' @click="addOrRemoveReaction(key)"   @touchstart="addOrRemoveReaction(key)">{{formatReactionCount(key)}}</span>
        </div>
        </div>
        
        <template v-if="!browserUtils.isMobile()">
            <div class="reaction-icon-container" @click="openReactionMenu">
                <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
            </div>
            <ReactionMenu :reactions="messageReactions" @reaction-selected="updateReaction" ref="reactionMenu" v-if="!isMessageAuthor()"/>
        </template>
        
        <span class='message-time-sent' v-if="renderTime">{{timeUtils.getTimeFromNow(data.created_at)}}</span>
        
    </div>
    

    <ConfirmModal headerText='Delete message?' :appendToString='rootElementId' confirmButtonLabel='Yes' @confirmed="actionConfirmed" ref='confirmActionModal' v-if='isMounted' />
    <ImageDialog ref='imageDialog'/>
</template>

<script>
import UserAvatar from '../profile/UserAvatar.vue';
import MoreOptionsMenu from '../menu/MoreOptionsMenu.vue';
import ConfirmModal from '../modal/ConfirmModal.vue';
import ImageDialog from '../feed/ImageDialog.vue';
//import OverlayPanel from 'primevue/overlaypanel';
//import ContextMenu from 'primevue/contextmenu';
import ChatService from '../../service/ChatService';
import PostUtils from '../../utilities/PostUtils';
import TimeUtils from '../../utilities/TimeUtils';
import BrowserUtils from '../../utilities/BrowserUtils';
import UserUtils from '../../utilities/UserUtils';
import ReactionMenu from '../menu/ReactionMenu';
import EventBus from '../../event-bus';
export default {
    name: 'Message',
    props: {
        data: {
            type: Object,
            required: true
        },
        rootElementId: {
            type: String,
            required: false,
            default: "#appChatDrawer"
        },
        channel: {
            type: Object,
            required: true
        },
        showReactionCounts:{
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    components: {
        UserAvatar, MoreOptionsMenu, ConfirmModal, ImageDialog, //OverlayPanel, //
       // ContextMenu
       ReactionMenu,
    },

    mounted() {
        this.isMounted = true;

        this.$nextTick( () => {
            for(const key in ChatService.reactionMap) {
                this.messageReactions.push({
                    'text': key,
                    'icon': ChatService.reactionMap[key]
                });
            }


            let messageContent = document.getElementById(this.messageContentId);
            if (messageContent) {

                const openImageDialog = (img) => {
                    img.addEventListener('load', () => {
                        if (img.width > img.height) {
                            img.className = 'wide-image';
                        }
                    })

                    img.addEventListener('click', () => {
                        document.body.style.overflow = 'auto';  

                        this.$refs.imageDialog.open(img.src, img.height > img.width);
                    });
                }

                const loopThroughChildren = (children) => {
                    if (children.length > 0) {
                        for(let i = 0; i < children.length; i++) {
                            let child = children[i];

                            if (child) {
                                loopThroughChildren(child.childNodes);

                                if (child.tagName === 'IMG') {
 
                                    openImageDialog(child);
                                    // node.addEventListener('click', () => {
                                        
                                    //     this.$refs.imageDialog.open(node.src, node.height > node.width);
                                    // });
                                }
                            } 
                        } 
                    }
                }

                loopThroughChildren(messageContent.childNodes);
                this.reactionCounts = this.data.reaction_counts;
            }
        });

        if( this.lostFocusHandler == null ) {
            this.lostFocusHandler = (messageId) => {
                if( !this.data || this.data.id != messageId) { //if we've lost focus make sure context menu closes
                    if( this.$refs.reactionMenu ) {
                        this.$refs.reactionMenu.hide();
                    }
                }
            };
        }
        EventBus.off('message-has-focus', this.lostFocusHandler);
        EventBus.on('message-has-focus', this.lostFocusHandler); 
    },

    data() {
        return {
            browserUtils: BrowserUtils,
            postUtils: PostUtils,
            timeUtils: TimeUtils,
            userUtils: UserUtils,
            isMounted: false,
            reactionCounts: [],
            lostFocusHandler: null,
            messageReactions: [],
        }
    },

    watch: {
        data() {
            //console.log("message updated...")
            this.reactionCounts = this.data.reaction_counts;
            
        }
    },
    computed: {
        messageContentId() {
            return `${this.data.id}-content`;
        },

       canViewMessageMenu() {
        return (this.type === 'directMessaging' && this.isUserOrChatMod) ||
        (this.type === 'groups' && (UserUtils.isUser(this.data.user.id) || (UserUtils.isUser(this.channel.data.group.groupOwnerId) || this.isGroupOwnerAdminOrMod)));
       },

       isGroupOwnerAdminOrMod() {
            return UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']) || UserUtils.isGroupMod(this.$store.getters['users/groupRoleList']);
       },

        isUserOrChatMod() {
            return this.userUtils.isUser(this.data.user.id) || this.userUtils.userIsChatModerator();
        },

        renderTime() {
            const pos = this.data.subsequentPos;

            if (this.data.isNewMessage === undefined) {
                if (pos) {
                    return pos === 'last' ? true : false;
                }
            } else {
                return false;
            }

            return true;
        },
        renderName() {
            if (!this.userUtils.isUser(this.data.user.id)) {
                const pos = this.data.subsequentPos;

                if (pos) {
                    return pos === 'first' ? true : false;
                } 

                return true;
            }

            return false;
        },
        renderAvatar() {
            if (!this.userUtils.isUser(this.data.user.id)) {
                const pos = this.data.subsequentPos;

                if (pos) {
                    return pos === 'last' ? true : false;
                } 

                return true;
            }

            return false;
        },
        messageClass() {
            const isUser = this.userUtils.isUser(this.data.user.id);

            let obj = {
                'message': true,
                'user-message': isUser,
                'other-message': !isUser,
                'message-option': this.userUtils.userIsChatModerator()
            }

            if (this.data.subsequentPos) {
                const pos = this.data.subsequentPos;

                obj['subsequent__first'] = pos === 'first';
                obj['subsequent__middle'] = pos === 'middle';
                obj['subsequent__last'] = pos === 'last';
            
            }

            return obj;
        },
        messageMenuOptions() {
            return this.getMessageMenuOptions();
        }
    },
    

    methods: {
        onMouseEnter(e) {//eslint-disable-line
            EventBus.emit('message-has-focus', this.data.id);
        },
        addOrRemoveReaction(reaction) {//eslint-disable-line
            //console.log("adding or removing reaction for message "+ JSON.stringify(this.data));
            if( !this.isMessageAuthor() ){
                for( var i = 0; i < this.data.latest_reactions.length; ++i ) {
                    let r = this.data.latest_reactions[i];
                    
                    if (this.userUtils.isUser(r.user_id)) {
                        if( r.type == reaction){
                            ChatService.removeReaction(this.channel, this.data.id,reaction);
                        }
                    }
                    else {
                        this.updateReaction(reaction);
                    }
                }
            }
        },
        formatReactionCount(key) {
            if( this.showReactionCounts) {
                let reactionNum = this.reactionCounts[key];

                return reactionNum +ChatService.reactionMap[key];
            }
            else {
                return ChatService.reactionMap[key];
            }
        },

         isMessageAuthor() {
            return this.userUtils.isUser(this.data.user.id);
        },
        updateReaction( reaction) {
            
            //console.log("updating reaction on "+ JSON.stringify(this.data)+" to "+ reaction);
            ChatService.updateReaction(this.channel, this.data.id, this.$store.state.users.user.userId, reaction);
        },
        
        openReactionMenu(e) {
            if( this.$refs.reactionMenu ) {
             this.$refs.reactionMenu.show(e);
            }
           
        },
        actionConfirmed() {
            EventBus.emit('delete-message', this.data.id) // <-- listened to by Channel.vue which does the actual delete
        },

        toggleMenu(event) {
            // console.log("toggleMenu", event, this.messageMenuOptions);
            this.$refs.menu.toggle(event);
        },

        getMessageMenuOptions() {
            let menuItems = [];

            if (this.canViewMessageMenu) {
                menuItems.push({
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        document.body.style.overflow = 'auto'
                        this.$refs.confirmActionModal.open();
                    }
                })
            }

            return menuItems;
        },
    }
}
</script>

<style scoped>

.reaction-count-container {
    margin-left: 10px;
    /* margin-top: -10px; */
}
.reaction-count {
    font-size: .8rem;
    margin-right: 5px;
    cursor: pointer;
}


.message {
    display: flex;
    margin-bottom: 16px;
}
/* .message:last-child {
    margin-bottom: 0px;
} */
.user-message {
    flex-direction: row-reverse;
    padding-left: 16px;
}
.other-message {
    padding-right: 16px;
    position: relative;
}
.other-message:not(.message-option) {
    margin-left: 4px;
}

.subsequent__first,
.subsequent__middle {
    margin-bottom: 4px;
}

.other-message.subsequent__first,
.other-message.subsequent__middle {
    margin-left: 32px;
}
.other-message.subsequent__last {
    margin-left: 0px;
}
.other-message.subsequent__first:not(.message-option),
.other-message.subsequent__middle:not(.message-option) {
    margin-left: 40px;
}
.other-message.subsequent__last:not(.message-option) {
    margin-left: 4px;
}


.message-container {
    display: flex;
    flex-direction: column;
    max-width: 65%;
}

.message-owner-name {
    margin-bottom: 4px;
}
.message-owner-name:hover {
    cursor: pointer;
}

.user-message .message-owner-name {
    margin-left: auto;
}

.message-time-sent {
    align-self: center;
    color: #a6a6a6;
    font-size: 11px;
}
.user-message .message-time-sent {
    margin-right: auto;
}
.other-message .message-time-sent {
    margin-left: auto;
}

.message-outer-content-container {
    position: relative;
}
.message-content-container {
    display: flex;
}
.user-message .message-content-container {
    flex-direction: row-reverse;
}

.message-content {
    padding: 8px;
    border-radius: 16px;
    word-break: break-word;
}
.user-message.subsequent__first .message-content {
    border-bottom-right-radius: 4px;
}
.user-message.subsequent__middle .message-content {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.user-message.subsequent__last .message-content {
    border-top-right-radius: 4px;
}
.other-message.subsequent__first .message-content {
    border-bottom-left-radius: 4px;
}
.other-message.subsequent__middle .message-content {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.other-message.subsequent__last .message-content {
    border-top-left-radius: 4px;
}


.message-content ::v-deep(*) {
    padding: 0px !important;
    margin: 0px;
}

.message-content ::v-deep(.image) {
    text-align: center;
}

.message-content ::v-deep(img) {
    max-width: 55%;
}
.message-content ::v-deep(img.wide-image) {
    max-width: 100%;
}
.message-container ::v-deep(.media div div) {
    height: 100% !important;
}
.message-container ::v-deep(.media div div iframe) {
    position:relative !important;
}

.user-message .message-content {
    background: #32364e;
    color: #FFFFFF;
}
.other-message .message-content {
    background: #BFBFBF;
    color: black;
}


/* .other-message.subsequent__first ::v-deep(.p-avatar) {
    align-self: flex-end;
} */

.other-message ::v-deep(.more-options-menu),
.other-message ::v-deep(.p-avatar) {
    align-self: flex-end;
    margin-right: 3px;
}
::v-deep(.p-avatar:hover) {
    cursor: pointer;
}

::v-deep(.more-options-menu.p-button:enabled:hover) {
    background: none;
}
::v-deep(.more-options-menu:focus) {
    box-shadow: none;
}

.reaction-icon-container {
    display: none;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    right: 10px;
}
.other-message:hover .reaction-icon-container:hover {
    opacity: 1;
}
.reaction-icon:hover {
    cursor: pointer;
    transform:rotate(0.4deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.other-message.subsequent__last .reaction-icon-container {
    top: -10px;
}
.other-message:hover .reaction-icon-container {
    display: block;
    opacity: 0.4;
}

</style>